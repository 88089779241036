import auth from './auth';
import cards from './cards';
import ui from './ui';
import attachments from './attachments';
import comments from './comments';
import milestones from './milestones';
import deliveries from './deliveries';
import rooms from './rooms';
import logs from './logs';
import files from './files';
import links from './links';
import possibleResponsible from './possible-responsible';

const RELEASE_NAME = 'visual-boards';
const debug = false; // if ReactGA debug statements should be put into console

const newrelic = window.newrelic;

// Trackable redux actions (splitted based on event categories)
const events = {
  ...auth,
  ...cards,
  ...ui,
  ...attachments,
  ...comments,
  ...milestones,
  ...deliveries,
  ...rooms,
  ...logs,
  ...files,
  ...links,
  ...possibleResponsible,
};

export const initialize = () => {
  if (newrelic) {
    newrelic.addRelease(RELEASE_NAME, `${import.meta.env.VITE_APP_VERSION}-${import.meta.env.VITE_APP_GIT_SHA}`);
  }
};

export const logEvent = ({ category, action, label = '', value = 0 }: { category: string, action: string, label?: string, value?: number }) => {
  if (category && action) {
    if (newrelic) {
      newrelic
        .interaction()
        .setAttribute('actionCategory', category) // 'category' is NewRelic reserved interaction attribute
        .setAttribute('action', action)
        .setAttribute('label', label)
        .setAttribute('value', value)
        .save();
    }
  }
};

export const logException = ({ type, action, code, message, fatal = false, error }: { type: string, action?: string, code?: string, message: string, fatal?: boolean, error?: Error }) => {
  if (newrelic) {
    newrelic.noticeError(error, { type, code, action, fatal, message });
  }
};

export const logAction = (action: {type: string}) => {
  const actionType = action && action.type ? action.type : '';
  const event = events[actionType];
  // Ignore non-trackable redux actions
  if (event) {
    logEvent(typeof event === 'function' ? event.call(null, action) : event);
  } else if (debug) {
    console.log(`Action '${actionType}' is not tracked in google analytics`);  
  }
};

export const logPageView = () => {
  const page = window.location.pathname + window.location.search;

  if (newrelic) {
    newrelic.setCurrentRouteName(page);
  }
};

export const actionLogger = () => (next: any) => (action: any) => {
  logAction(action);
  return next(action);
};
