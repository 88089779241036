import React from 'react';
import { default as Tab } from './tab';
import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  page: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

const PageWrapper = ({ children, title }: { children: React.ReactNode; title: string }) => {
  const theme = useTheme();
  const classes: any = useStyles(theme);

  return (
    <React.Fragment>
      <Tab>{title}</Tab>
      <Box component="div" sx={classes.page}>
        {children}
      </Box>
    </React.Fragment>
  );
};

export default PageWrapper;
