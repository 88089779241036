import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';

import Link from './Link';
import { Theme, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

const useStyles = (theme: Theme) => ({
  button: {
    borderRadius: '0px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
    },
  },
  navItem: {
    ...theme.typography.body2,
    display: 'block',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  navLink: {
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  navLinkButton: {
    color: theme.palette.text.secondary,
    textIndent: '24px',
    fontSize: '13px',
    paddingLeft: '10px',
  },
  activeButton: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
});

const NavLink = ({
  href,
  showText,
  title,
  icon,
  disabled,
}: {
  href: string;
  title: string;
  showText?: boolean;
  icon: any;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const classes: any = useStyles(theme);
  const location = useLocation();
  const isActive = location.pathname + location.search === href;

  return (
    <Tooltip title={title} placement="top-start">
      <ListItem sx={classes.navItem} disableGutters>
        <Button
          component={Link}
          href={href}
          disabled={disabled}
          sx={{
            ...classes.button,
            ...classes.navLinkButton,
            ...(isActive && classes.activeButton),
          }}
          disableGutters
        >
          {icon}
          {showText && <span style={{ marginLeft: 12 }}>{title}</span>}
        </Button>
      </ListItem>
    </Tooltip>
  );
};

export default NavLink;
