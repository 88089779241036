import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import LinearProgress from '@mui/material/LinearProgress';

import { alertAction, alertWarning } from '../../thunks/Alerts';
import PageWrapper from '../../common/PageWrapper';
import Steps from './Steps';
import Toolbar from './Toolbar';
import * as boardsActions from '../../thunks/Boards';
import * as cardActions from '../../thunks/Cards';
import * as milestoneActions from '../../thunks/Milestones';
import * as deliveriesActions from '../../thunks/Deliveries';
import { actions } from '../../ducks/Boards';
import { getNoonOfDay } from '../../utils/DateUtil';
import {
  extractDeliveries,
  extractQuestions,
  flattenArray,
  calculatePPC,
  calculateToValue,
} from '../../utils/FieldUtil';
import {
  actions as viewActions,
  selectBoardCards,
  selectIsLoadingCards,
  selectPossibleResponsible,
  selectViewConfig,
} from '../../ducks/Cards';
import { selectActiveBoard, selectBoardConfig, selectWeekScrollPos, actions as bActions } from '../../ducks/Boards';
import { WEEK_BOARD_VIEW_MODE } from '../../utils/ViewModeUtil';;
import WeekBoard from './WeekBoard';

import { selectMilestones } from '../../ducks/Milestones';
import { selectDeliveries } from '../../ducks/Deliveries';
import { isOverdueColumn } from '../../utils/DNDUtils';
import { selectShowSidebar, selectZoomLevel } from '../../ducks/App';
import CreateCardMenu from './CreateCardMenu';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { CardType } from '@/models/card/CardModel';

const mapStateToProps = () =>
  createStructuredSelector({
    activeBoard: selectActiveBoard(),
    isLoadingCards: selectIsLoadingCards(),
    cards: selectBoardCards(),
    milestones: selectMilestones(),
    deliveries: selectDeliveries(),
    config: selectBoardConfig(WEEK_BOARD_VIEW_MODE),
    viewConfig: selectViewConfig(),
    scrollPos: selectWeekScrollPos(),
    showSidebar: selectShowSidebar(),
    zoomLevel: selectZoomLevel(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...boardsActions,
      ...bActions,
      ...cardActions,
      ...milestoneActions,
      ...deliveriesActions,
      expandCollapseWeekViewColumn: actions.expandCollapseWeekViewColumn,
      setViewConfig: viewActions.setViewConfig,
    },
    dispatch,
  ),
});

const WeekBoardContainer = ({
  cards,
  activeBoard,
  actions,
  config,
  viewConfig,
  isLoadingCards,
  scrollPos,
  possibleResponsible,
  milestones,
  deliveries,
  showSidebar,
}: {
  cards: any;
  activeBoard: any;
  actions: any;
  config: any;
  viewConfig: any;
  isLoadingCards: any;
  scrollPos: any;
  possibleResponsible: any;
  milestones: any;
  deliveries: any;
  showSidebar: any;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

  React.useEffect(() => {
    if (cards.length > 1 || activeBoard.id.toString() !== params.boardId) {
      actions.fetchCards({ boardId: params.boardId }).then((action) =>
        alertAction({
          action,
          error: intl.get('board.views.week.fetch_cards.error'),
        }),
      );
      actions.fetchMilestones({ boardId: params.boardId });
      if (activeBoard.delivery_config) {
        actions.fetchDeliveries({ boardId: params.boardId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards.length, activeBoard.id, params.boardId]);

  const handleCardClick = ({ card, type }) => {
    if (CardType.MILESTONE === type) {
      navigate(
        `/${activeBoard.id}/milestoneset/${card.milestoneFolderId}/milestones/${card.milestoneId}?view=${WEEK_BOARD_VIEW_MODE}`,
      );
    } else if (CardType.DELIVERY === type) {
      navigate(`/${activeBoard.id}/deliveries/${card.id}?view=${WEEK_BOARD_VIEW_MODE}`);
    } else {
      navigate(`/${activeBoard.id}/cards/${card.id}?view=${WEEK_BOARD_VIEW_MODE}`);
    }
  };

  const handleSetScrollPos = ({ pos }) => actions.setWeekScrollPos({ scrollPos: pos });

  const handleUpdateCard = ({ card, columnIndex, rowIndex, sourceRowIndex, config }) => {
    if (card.type === CardType.MILESTONE) {
      return handleMoveMilestone({
        card,
        board: activeBoard,
        columnIndex,
        config,
      });
    }
    if (card.type === CardType.DELIVERY) {
      return handleMoveDelivery({
        card,
        board: activeBoard,
        columnIndex,
        rowIndex,
        config,
      });
    }
    const initialStepId = activeBoard.step_config.initial_step_id;

    actions
      .updateCardImmediately({
        boardId: activeBoard.id,
        card,
        stepId: initialStepId,
        data: getFieldsWithUpdatedFieldsForQuestion({
          card,
          board: activeBoard,
          columnIndex,
          rowIndex,
          sourceRowIndex,
          config,
        }),
      })
      .then((action) => {
        alertAction({
          action,
          error: intl.get('board.views.week.update_card.error'),
          success: intl.get('board.views.week.update_card.success'),
        });
      });
  };

  const handleMoveDelivery = ({ card, board, columnIndex, rowIndex, config }) => {
    actions
      .updateDeliveryImmediately({
        boardId: board.id,
        delivery: card,
        data: getFieldsWithUpdatedFieldsForDelivery({
          card,
          board,
          columnIndex,
          rowIndex,
          config,
        }),
      })

      .then((action) => {
        alertAction({
          action,
          error: intl.get('delivery.move.error'),
          success: intl.get('delivery.move.success'),
        });
      });
  };

  const getFieldsWithUpdatedFieldsForQuestion = ({ card, board, columnIndex, rowIndex, sourceRowIndex, config }) => {
    const dateField = board.field_config.answer_due_field;
    const toMemberField = board.field_config.to_member_field;
    const newToFieldValue = board.field_config.allowed_to_authorities.find(
      (authority) => authority.id === config.xColumns[rowIndex].id,
    );
    const oldToFieldValue = board.field_config.allowed_to_authorities.find(
      (authority) => authority.id === config.xColumns[sourceRowIndex].id,
    );
    return card.fields.map((field) => {
      if (field.id === toMemberField) {
        return field.set(
          'value',
          calculateToValue({
            board,
            card,
            newValue: newToFieldValue,
            oldValue: oldToFieldValue,
          }),
        );
      }
      if (field.id === dateField && !isOverdueColumn(columnIndex)) {
        return field.set('value', config.yColumns[columnIndex].date);
      }
      return field;
    });
  };

  const getFieldsWithUpdatedFieldsForDelivery = ({ card, board, columnIndex, rowIndex, config }) => {
    const dateField = board.delivery_config.delivery_date_field;
    const fromMemberField = board.delivery_config.delivery_from_field;
    const fromMemberFieldValue = board.delivery_config.allowed_from_authorities.filter(
      (authority) => authority.id === config.xColumns[rowIndex].id,
    );
    return card.fields.map((field) => {
      if (field.id === fromMemberField) {
        return field.set('value', fromMemberFieldValue);
      }
      if (field.id === dateField && !isOverdueColumn(columnIndex)) {
        return field.set('value', config.yColumns[columnIndex].date);
      }
      return field;
    });
  };

  const handleMoveMilestone = ({ card, board, columnIndex, config }) => {
    actions
      .updateMilestoneImmediately({
        boardId: board.id,
        milestone: card,
        milestoneSet: card.milestoneFolderId,
        data: getFieldsWithUpdatedDateForMilestone({
          card,
          board,
          columnIndex,
          config,
        }),
      })

      .then((action) => {
        alertAction({
          action,
          error: intl.get('board.views.tile.move_milestone.error'),
          success: intl.get('board.views.tile.move_milestone.success'),
        });
      });
  };

  const getFieldsWithUpdatedDateForMilestone = ({ card, board, columnIndex, config }) => {
    const dateField = board.milestones_config.filter((config) => config.id === card.milestoneFolderId)[0].date_field;
    return card.fields.map((field) =>
      field.id === dateField ? field.set('value', config.yColumns[columnIndex].date) : field,
    );
  };

  const handleMoveCard = ({ board, card, step }) => {
    actions
      .moveCardImmediately({
        board,
        card,
        stepId: step.id,
      })
      .then((action) => {
        alertAction({
          action,
          error: intl.get('board.views.week.move_card.error'),
          success: intl.get('board.views.week.move_card.success'),
        });
      });
  };

  const handleConfigChange = (viewConfig) => {
    actions.setViewConfig(viewConfig);
  };

  const handleQuestionCreateClick = () => navigate(`/${params.boardId}/create-card?view=${WEEK_BOARD_VIEW_MODE}`);

  const handleMilestoneCreateClick = ({ milestoneSet }) =>
    navigate(`/${params.boardId}/milestoneset/${milestoneSet.id}/create-milestone?view=${WEEK_BOARD_VIEW_MODE}`);

  const handleDeliveryCreateClick = () => navigate(`/${params.boardId}/create-delivery?view=${WEEK_BOARD_VIEW_MODE}`);

  const handleExpandAll = () => {
    handleConfigChange(
      viewConfig.set(
        'expandedWeekRows',
        config.xColumns.map((column) => column.id),
      ),
    );
  };

  const handleCollapseAll = () => {
    handleConfigChange(viewConfig.set('expandedWeekRows', []));
  };

  const handleCalculatePPC = (dateFrom, dateTo) => {
    const questions = extractQuestions(cards, activeBoard.field_config, activeBoard.step_config);
    const dels = extractDeliveries(deliveries, activeBoard.delivery_config);
    const allCards = calculatePPC(dateFrom, dateTo, questions.concat(dels));
    if (allCards.total < 1) {
      alertWarning(intl.get('board.toolbar.ppc.not_found'));
      handleConfigChange(viewConfig.set('showPPC', false));
    }
    return allCards;
  };

  const handleExpandAllCells = () => {
    const cells = [];
    config.xColumns.map((xCol, xIndex) => config.yColumns.map((yCol, yIndex) => cells.push(`${xIndex}-${yIndex}`)));
    handleConfigChange(
      viewConfig
        .set('expandedWeekCells', cells)
        .set(
          'expandedWeekRows',
          viewConfig.expandedWeekRows.length === 0
            ? config.xColumns.map((column) => column.id)
            : viewConfig.expandedWeekRows,
        ),
    );
  };

  const handleCollapseAllCells = () => {
    handleConfigChange(viewConfig.set('expandedWeekCells', []));
  };

  const handleDateChange = (field, value) => {
    handleConfigChange(viewConfig.set(field, getNoonOfDay(value)));
  };

  const handleStepSidebarClick = () => {
    handleConfigChange(viewConfig.set('stepsSidebarOpen', !viewConfig.stepsSidebarOpen));
  };

  const renderCreateCardButton = () => (
    <CreateCardMenu
      board={activeBoard}
      onCreateQuestion={handleQuestionCreateClick}
      onCreateMilestone={handleMilestoneCreateClick}
      onCreateDelivery={handleDeliveryCreateClick}
    />
  );

  if (isLoadingCards) {
    return <LinearProgress />;
  }

  const containerWidth = isMediumUp
    ? window.innerWidth - (viewConfig.stepsSidebarOpen ? 410 : 115) - (showSidebar ? 205 : 0)
    : window.innerWidth;

  return (
    <PageWrapper title={intl.get('app_bar.week_view')}>
      <Toolbar
        renderCreateCardButton={renderCreateCardButton}
        board={activeBoard}
        viewConfig={viewConfig}
        onChange={handleConfigChange}
        onExpandAll={handleExpandAll}
        onCollapseAll={handleCollapseAll}
        expandedRows={viewConfig.expandedWeekRows}
        expandedCells={viewConfig.expandedWeekCells}
        onCalculatePPC={handleCalculatePPC}
        onExpandAllCells={handleExpandAllCells}
        onCollapseAllCells={handleCollapseAllCells}
        possibleResponsible={possibleResponsible}
        onDateChange={handleDateChange}
        viewName={WEEK_BOARD_VIEW_MODE}
      />
      <WeekBoard
        width={containerWidth}
        config={config}
        cards={flattenArray(cards)}
        board={activeBoard}
        onUpdateCard={handleUpdateCard}
        onCardClick={handleCardClick}
        viewConfig={viewConfig}
        scrollPos={scrollPos}
        onChange={handleConfigChange}
        onExpandCollapseColumn={actions.expandCollapseWeekViewColumn}
        onSetScrollPos={handleSetScrollPos}
        milestones={milestones}
        deliveries={deliveries}
      />
      <Steps
        open={viewConfig.stepsSidebarOpen}
        onSidebarClick={handleStepSidebarClick}
        board={activeBoard}
        cards={cards[1]}
        steps={activeBoard.step_config.steps.filter((step) => step.id !== activeBoard.step_config.initial_step_id)}
        viewConfig={viewConfig}
        onCardClick={handleCardClick}
        onMoveCardToStep={handleMoveCard}
        onChange={handleConfigChange}
      />
    </PageWrapper>
  );
};

const ConnectedWeekBoardContainer = connect(mapStateToProps, mapDispatchToProps)(WeekBoardContainer);

export default ConnectedWeekBoardContainer;
