import { useState } from 'react';
import intl from 'react-intl-universal';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Hidden from '@mui/material/Hidden';
import Tooltip from '@mui/material/Tooltip';

import StopClickPropagation from '../../common/StopClickPropagation';
import AttachmentsMobile from './AttachmentsMobile';
import AttachmentsDesktop from './AttachmentsDesktop';
import DeleteAttachmentsDialog from '../../common/DeleteAttachmentsDialog';

import FilePicker from './FilePicker';
import { Box, styled, Theme, useTheme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    paddingRight: '10px',
  },
  column: {
    flexBasis: '33.33%',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  badge: {
    top: '-4px',
    right: '-30px',
    transform: 'scale(1)',
  },
  flexGrow: {
    flex: '1 1 auto',
  },
});

const filePickerStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 200,
  },
  content: {
    width: 500,
    height: 400,
    position: 'static',
  },
};

const buttonStyles = {
  backgroundColor: '#13627c',
  color: '#fff',
  marginTop: 20,
  marginLeft: 5,
};

const cancelButtonStyles = {
  marginTop: 20,
  marginLeft: '70%',
};

const Attachments = (props) => {
  const {
    activeCommunity,
    activeRoom,
    item,
    attachments,
    members,
    isUploading,
    isDeleting,
    onUploadAttachment,
    onDeleteAttachment,
    onDownloadAttachment,
    onAddLinks,
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [deletingRow, setDeletingRow] = useState({});
  const [openDeleteAttachmentsDialog, setOpenDeleteAttachmentsDialog] = useState(false);
  const [openFilePicker, setOpenFilePicker] = useState(false);

  const handleUploadAttachment = (e) => {
    const files = Array.isArray(e) ? e : e.target.files;
    onUploadAttachment({ files });
  };

  const commitChanges = ({ deleted }) => {
    setDeletingRow(attachments[deleted[0]]);
    setOpenDeleteAttachmentsDialog(true);
  };

  const cancelDelete = () => {
    setOpenDeleteAttachmentsDialog(false);
    setDeletingRow({});
  };

  const deleteAttachment = () => {
    onDeleteAttachment({
      attachmentId: deletingRow.id,
    });
    setOpenDeleteAttachmentsDialog(false);
    setDeletingRow({});
  };

  const downloadAttachment = (attachment) => {
    onDownloadAttachment({ attachment });
  };

  const formatAttachmentLink = ({ row }: { row: { destination_version: string; name: string } }) => (
    <Tooltip title={intl.get('common.attachments.download')}>
      <Box component="span" sx={classes.link} onClick={() => downloadAttachment(row)}>
        {row.destination_version ? `${row.name} (${row.destination_version})` : row.name}
      </Box>
    </Tooltip>
  );

  const handleAddLinks = ({ files }) => {
    const links = files.map((file: any) => ({
      destination: file.id,
      destination_version: file.version,
      force: true,
      name: file.name,
      path: file.path,
      type: 'internal-link',
    }));
    onAddLinks(links);
    handleCloseFilePicker();
  };

  const handleOpenFilePicker = () => setOpenFilePicker(true);

  const handleCloseFilePicker = () => setOpenFilePicker(false);

  const FileInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  
  return (
    <Accordion disabled={!item.id} defaultExpanded={!!item.id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ paddingRight: 40, display: 'flex', width: '100%' }}>
          <Box sx={classes.column}>
            <Badge sx={{ badge: classes.badge }} color="primary" badgeContent={attachments.length}>
              <Typography sx={classes.heading}>{intl.get('common.attachments')}</Typography>
            </Badge>
          </Box>
          <Box sx={classes.flexGrow} />
          <StopClickPropagation
            style={{
              position: 'relative',
              paddingRight: 0,
              cursor: !item.permissions.ADD_ATTACHMENT ? 'default' : 'pointer',
            }}
          >
            <Tooltip title={intl.get('common.link.add')}>
              <Button
                id={'add-link'}
                variant="contained"
                color="primary"
                component="label"
                style={{ marginRight: 20 }}
                onClick={handleOpenFilePicker}
                disabled={!item.permissions.ADD_ATTACHMENT}
              >
                {intl.get('common.link.add')}
              </Button>
            </Tooltip>
            <Tooltip title={intl.get('common.attachments.upload.drag_and_drop')}>
              <Button
                id={'add-attachment'}
                variant="contained"
                color="primary"
                component="label"
                disabled={!item.permissions.ADD_ATTACHMENT}
              >
                {intl.get('common.attachments.upload')}
                <FileInput type="file" multiple onChange={handleUploadAttachment} />
              </Button>
            </Tooltip>
          </StopClickPropagation>
        </div>
      </AccordionSummary>
      <Hidden smDown>
        <AttachmentsDesktop
          item={item}
          attachments={attachments}
          members={members}
          isUploading={isUploading}
          isDeleting={isDeleting}
          formatAttachmentLink={formatAttachmentLink}
          commitChanges={commitChanges}
          onUploadAttachment={handleUploadAttachment}
        />
      </Hidden>
      <Hidden mdUp>
        <AttachmentsMobile
          item={item}
          attachments={attachments}
          formatAttachmentLink={formatAttachmentLink}
          commitChanges={commitChanges}
        />
      </Hidden>
      <Divider />
      <DeleteAttachmentsDialog
        open={openDeleteAttachmentsDialog}
        record={deletingRow}
        onClose={cancelDelete}
        onDelete={deleteAttachment}
      />
      {openFilePicker && (
        <FilePicker
          lazyInit={true}
          styles={filePickerStyles}
          buttonStyles={buttonStyles}
          buttonText={intl.get('common.link.add.title')}
          cancelButtonStyles={cancelButtonStyles}
          cancelButtonText={intl.get('common.dialog.cancel')}
          restrictToCommunity={activeCommunity}
          defaultRoom={activeRoom}
          callback={handleAddLinks}
        />
      )}
    </Accordion>
  );
};

export default Attachments;
