import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const useStyles = () => ({
  caption: {
    display: 'inline-block',
    fontWeight: 'bold',
  },
  captionHighReadability: {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  value: {
    display: 'inline-block',
  },
  valueHighReadability: {
    display: 'inline-block',
    fontSize: '20px',
  },
});

const Value = ({ highReadability, caption, value }: any) => {
  const classes: any = useStyles();

  return (
    <Box component="div">
      <Typography sx={highReadability ? classes.captionHighReadability : classes.caption}>
        {caption}
        :&nbsp;
      </Typography>
      <Typography component={'span'} sx={highReadability ? classes.valueHighReadability : classes.value}>
        {value}
      </Typography>
    </Box>
  );
};

export default Value;
