import { getNoonOfDay } from './DateUtil';
import { deliveryStatuses, isOverdue } from './FieldUtil';
import Immutable from 'seamless-immutable';
import { getFilters } from './FilterUtil';
import { cardSorter } from './SortUtil';
import { CardType } from '@/models/card/CardModel';

export const prepareDeliveryCardsForWeekBoard = (deliveries, board, viewConfig) => {
  const deliveryConfig = board.delivery_config;
  const filters = getFilters({ viewConfig, type: CardType.DELIVERY });

  return Immutable.asMutable(deliveries)
    .filter((delivery) => {
      const filterResults = filters.map((filter) =>
        filter.filterFn({
          fieldId: filter.fieldId,
          filterValues: viewConfig.field_filters[CardType.DELIVERY][filter.fieldId],
          card: delivery,
        }),
      );
      return !filterResults.length || filterResults.indexOf(true) < 0;
    })
    .map((delivery) => {
      const fromField = delivery.fields.find((f) => deliveryConfig.delivery_from_field === f.id);
      const dateField = delivery.fields.find((f) => deliveryConfig.delivery_date_field === f.id);
      const metDateField = delivery.fields.find((f) => deliveryConfig.delivery_met_date_field === f.id);

      const from = fromField && fromField.value ? fromField.value : undefined;
      const dueDate = dateField && dateField.value ? getNoonOfDay(new Date(dateField.value)) : undefined;

      const metDate = metDateField && metDateField.value ? getNoonOfDay(new Date(metDateField.value)) : undefined;

      return Immutable({
        ...delivery,
        type: CardType.DELIVERY,
        metDate,
        dueDate,
        disciplines: from,
        status: metDate ? deliveryStatuses.DELIVERED : deliveryStatuses.NOT_DELIVERED,
        isOverdue: !metDate && isOverdue(dueDate),
      });
    })
    .sort(cardSorter(viewConfig['deliveryTitle']));
};
