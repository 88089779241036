import { Board } from '@/models/BoardModel';
import { client } from './API';
import { User } from '@/models/permission/AuthorityModel';

/**
 * @returns list of boards
 */
export const fetchBoards = (params: { communityId: string, roomId: string }): Promise<Board[]> => {
  return client.get(`/v1/boards`, { params }).then((response) => response.data);
};

/**
 * @returns single board with id and metadata
 */
export const fetchBoard = ({ boardId }: { boardId: string }): Promise<Board> => client.get(`/v1/boards/${boardId}`).then((response) => response.data);

export const getRoomMembers = ({ boardId, disciplines }: { boardId: string, disciplines: string[] }): Promise<User[]> =>
  client
    .get(`/v1/boards/${boardId}/members`, {
      params: {
        authorityIds: disciplines.toString(),
      },
    })
    .then((response) => response.data);

export const getAllRoomMembers = ({ boardId }: { boardId: string }): Promise<User[]> =>
  client.get(`/v1/boards/${boardId}/authorities`).then((response) => response.data);
