import green from '@mui/material/colors/green';

import CardItemTooltip from './CardItemTooltip';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CommentIcon from '@mui/icons-material/ModeCommentOutlined';
import AttachmentIcon from '@mui/icons-material/AttachFile';
import intl from 'react-intl-universal';
import QuestionIcon from '@mui/icons-material/HelpRounded';
import DraggableCard from './DraggableCard';
import { getCardDate, getQuestionFieldValuesToDisplayInTooltip, getTitle } from '../../utils/FieldUtil';
import { Box } from '@mui/material';

const useStyles = () => ({
  cell: {
    backgroundColor: '#e4e4e4',
    marginTop: '2px',
    marginLeft: '2px',
    marginBottom: '2px',
    minHeight: '200px',
    borderRadius: '4px',
    width: '100%',
  },
  cellHighReadability: {
    backgroundColor: '#bdbdbd',
    marginTop: '4px',
    marginLeft: '4px',
    marginBottom: '4px',
    minHeight: '470px',
    borderRadius: '4px',
    width: '100%',
  },
  cellIsOver: {
    backgroundColor: green[100],
  },
  card: {
    float: 'left',
    width: '150px',
    margin: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
    position: 'relative',
    background: '#f5f2f1',
    minHeight: '38px',
    border: '2px dashed transparent;',
  },
  cardHighReadability: {
    float: 'left',
    width: '250px',
    margin: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
    position: 'relative',
    background: '#f5f2f1',
    minHeight: '56px',
  },
  title: {
    maxHeight: '30px',
    overflow: 'hidden',
    fontSize: '14px',
    lineHeight: '15px',
    padding: '6px 14px 0 38px',
    textOverflow: 'ellipsis',
  },
  titleHighReadability: {
    maxHeight: '49px',
    overflow: 'hidden',
    fontSize: '24px',
    lineHeight: '24px',
    padding: '8px 22px 0 52px',
    textOverflow: 'ellipsis',
  },
  info: {
    display: 'flex',
    paddingRight: '5px',
    fontSize: '10px',
    fontWeight: '100',
  },
  infoHighReadability: {
    display: 'flex',
    paddingTop: '2px',
    paddingBottom: '2px',
    fontSize: '18px',
    fontWeight: '100',
  },
  typeIconContainer: {
    borderRadius: '50%',
    position: 'absolute',
    top: 4,
    display: 'inline-block',
  },
  tag: {
    height: '26px',
    width: '6px',
    borderRadius: '3px',
    position: 'absolute',
    right: '3px',
    top: '6px',
    display: 'inline-block',
  },
  tagHighReadability: {
    height: '42px',
    width: '12px',
    borderRadius: '6px',
    position: 'absolute',
    right: '3px',
    top: '6px',
    display: 'inline-block',
  },
  icon: {
    height: '14px',
    width: '14px',
  },
  iconHighReadability: {
    height: '24px',
    width: '24px',
  },
  typeIcon: {
    height: '32px',
    width: '32px',
    color: '#3eb1c8',
  },
  typeIconHighReadability: {
    height: '48px',
    width: '48px',
    color: '#3eb1c8',
  },
  statusGreen: {
    backgroundColor: '#309269',
  },
});

const AnsweredCardsList = ({
  data,
  board,
  onCardClick,
  viewConfig,
}: {
  data: any;
  board: any;
  onCardClick: any;
  viewConfig: any;
}) => {
  const classes: any = useStyles();
  const locale = intl.getInitOptions().currentLocale;

  return (
    <Box
      component="div"
      sx={{
        ...(viewConfig.highReadability ? classes.cellHighReadability : classes.cell),
      }}
    >
      {data.map((item: any, index: any) => {
        const showAttachments = viewConfig.showAttachments && item.child_count > 0;
        const showComments = viewConfig.showComments && item.comment_count > 0;
        const commentCaption = intl.get(
          item.comment_count === 1 ? 'boards.tooltips.comment' : 'boards.tooltips.comments',
        );
        const attachmentCaption = intl.get(
          item.child_count === 1 ? 'boards.tooltips.attachment' : 'boards.tooltips.attachments',
        );
        return (
          <DraggableCard key={`item-${index}`} board={board}>
            <Paper
              sx={{
                ...(viewConfig.highReadability ? classes.cardHighReadability : classes.card),
              }}
              elevation={1}
              onClick={() => {
                onCardClick({ card: item });
              }}
            >
              <Tooltip title={intl.get('board.tooltips.question')}>
                <Box component="div" sx={classes.typeIconContainer}>
                  {
                    <QuestionIcon
                      sx={viewConfig.highReadability ? classes.typeIconHighReadability : classes.typeIcon}
                    />
                  }
                </Box>
              </Tooltip>
              <Tooltip title={intl.get('board.tooltips.question.answered')}>
                <Box
                  component="div"
                  sx={{
                    ...(viewConfig.highReadability ? classes.tagHighReadability : classes.tag),
                    ...classes.statusGreen,
                  }}
                />
              </Tooltip>
              <CardItemTooltip viewConfig={viewConfig} map={getQuestionFieldValuesToDisplayInTooltip(item)}>
                <Box component="div">
                  <Typography
                    sx={{
                      ...(viewConfig.highReadability ? classes.titleHighReadability : classes.title),
                    }}
                    gutterBottom
                  >
                    {getTitle(item, viewConfig, 'questionTitle')}
                  </Typography>
                  <Box
                    sx={{
                      ...(viewConfig.highReadability ? classes.infoHighReadability : classes.info),
                      paddingLeft: 40,
                    }}
                  >
                    <span style={{ height: 20, marginRight: 1 }}>{getCardDate(item, board, locale)}</span>
                    {showComments && (
                      <Tooltip title={String(item.comment_count) + ' ' + commentCaption}>
                        <CommentIcon
                          sx={{
                            ...(viewConfig.highReadability ? classes.iconHighReadability : classes.icon),
                          }}
                        />
                      </Tooltip>
                    )}
                    {showAttachments && (
                      <Tooltip title={String(item.child_count) + ' ' + attachmentCaption}>
                        <AttachmentIcon
                          sx={{
                            ...(viewConfig.highReadability ? classes.iconHighReadability : classes.icon),
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </CardItemTooltip>
            </Paper>
          </DraggableCard>
        );
      })}
    </Box>
  );
};

export default AnsweredCardsList;
