import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import IconInfo from '@mui/icons-material/InfoOutlined';
import IconEdit from '@mui/icons-material/EditOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate, useParams } from 'react-router-dom';

import * as milestoneActionThunks from '../../thunks/Milestones';
import { fetchRoomMembers } from '../../thunks/Boards';
import ListBoard from './ListBoard';
import {
  actions as milestoneActions,
  selectMilestones,
  selectIsLoadingMilestones,
  selectLastActiveMilestone,
  selectListViewConfig,
} from '../../ducks/Milestones';
import { selectActiveBoard, selectRoomMembers } from '../../ducks/Boards';
import { LIST_MILESTONE_VIEW_MODE } from '../../utils/ViewModeUtil';
import { fieldsToColumns, generateNumberOfCardsMessage } from '../../utils/ListViewUtil';
import PageWrapper from '../../common/PageWrapper';
import {
  toPrimitiveFieldValue,
  getCellComponentRenderer,
  itemToRow,
  getCardInfo,
  getEntryTitle,
} from '../../utils/FieldUtil';
import { canEditMilestone } from '../../utils/PermissionUtils';
import { Cell } from '../../common/table';
import ToggleIconButton from '../../common/ToggleIconButton';
import Typography from '@mui/material/Typography';
import Error from '../../common/Error';
import { alertAction } from '../../thunks/Alerts';
import DeleteMilestoneDialog from '../../common/DeleteCardItemDialog';
import DeleteIconButton from '../../common/DeleteIconButton';
import { exportContent } from '../../services/Export';
import saveAs from 'file-saver';
import { generateExportRequest } from '../../utils/ExportUtil';
import ListViewConfig from '@/models/ListViewConfig';

let searchTerm = '';

const mapStateToProps = () =>
  createStructuredSelector({
    activeBoard: selectActiveBoard(),
    isLoadingMilestones: selectIsLoadingMilestones(),
    milestones: selectMilestones(),
    listViewConfig: selectListViewConfig(),
    roomMembers: selectRoomMembers(),
    lastActiveMilestone: selectLastActiveMilestone(),
  });

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...milestoneActionThunks,
      ...milestoneActions,
      cleanUp: milestoneActions.cleanUp,
      fetchRoomMembers,
    },
    dispatch,
  ),
});

const ListMilestoneContainer = ({
  activeBoard,
  milestones,
  lastActiveMilestone,
  isLoadingMilestones,
  roomMembers,
  actions,
  listViewConfig: initialListViewConfig,
}: any) => {
  const { boardId } = useParams();
  const navigate = useNavigate();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filters, setFilters] = useState([]);
  const [viewConfig, setViewConfig] = useState(initialListViewConfig);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [selectedMilestoneSet, setSelectedMilestoneSet] = useState(null);

  useEffect(() => {
    actions.fetchMilestones({ boardId });

    if (roomMembers.length < 1 || activeBoard.id.toString() !== boardId) {
      actions.fetchRoomMembers({ boardId });
    }

    searchTerm = localStorage.getItem('searchTerm') || '';
    setFilters(JSON.parse(localStorage.getItem('filters')) || []);

    // Cleanup function
    return () => {
      if (viewConfig) {
        actions.setListViewConfig(viewConfig);
      }
      actions.cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardId, activeBoard.id, actions, roomMembers.length]);

  const handleSortChange = ({ sorter, milestoneFolderId }) => {
    const milestoneSetViewConfig = viewConfig[milestoneFolderId] || ListViewConfig.defaultValue;
    setViewConfig({
      ...viewConfig,
      [milestoneFolderId]: { ...milestoneSetViewConfig, sorter },
    });
  };

  const handleColumnOrderChange = ({ columnOrder, milestoneFolderId }) => {
    const milestoneSetViewConfig = viewConfig[milestoneFolderId] || ListViewConfig.defaultValue;
    setViewConfig({
      ...viewConfig,
      [milestoneFolderId]: { ...milestoneSetViewConfig, columnOrder },
    });
  };

  const handleColumnVisibilityChange = ({ visibleColumns, milestoneFolderId }) => {
    const milestoneSetViewConfig = viewConfig[milestoneFolderId] || ListViewConfig.defaultValue;
    setViewConfig({
      ...viewConfig,
      [milestoneFolderId]: { ...milestoneSetViewConfig, visibleColumns },
    });
  };

  const handleSearch = (searchTerm) => {
    localStorage.setItem('searchTerm', searchTerm);
  };

  const handleFilter = (filters) => {
    localStorage.setItem('filters', JSON.stringify(filters));
    setFilters(filters);
  };

  const handleMilestoneEditClick = (milestoneFolderId, milestoneId) => {
    actions.setLastActiveMilestone({
      lastActiveMilestone: milestoneId,
    });
    navigate(
      `/${boardId}/milestoneset/${milestoneFolderId}/milestones/${milestoneId}?view=${LIST_MILESTONE_VIEW_MODE}`,
    );
  };

  const handleMilestoneCreateClick = ({ milestoneSet }) =>
    navigate(`/${boardId}/milestoneset/${milestoneSet.id}/create-milestone?view=${LIST_MILESTONE_VIEW_MODE}`);

  const handleDelete = () => {
    setOpenDeleteDialog(false);

    actions
      .deleteMilestone({
        boardId: activeBoard.id,
        milestone: selectedMilestone,
        milestoneSet: selectedMilestoneSet,
      })
      .then((action) =>
        alertAction({
          action,
          success: intl.get('board.tooltips.milestone.delete.success.message'),
          error: intl.get('board.tooltips.milestone.delete.error.message'),
          onSuccess: () => {},
        }),
      );
  };

  const handleClickOpenDialog = (milestone, milestoneSet) => {
    setSelectedMilestone(milestone);
    setSelectedMilestoneSet(milestoneSet);
    setOpenDeleteDialog(true);
  };

  const rowToMilestoneInfo = (row) => {
    const cardInfo = row.fields.reduce((milestoneInfo, field) => {
      milestoneInfo[field.id] = toPrimitiveFieldValue(field);
      return milestoneInfo;
    }, {});
    return cardInfo.merge({ ...getCardInfo(row, roomMembers) });
  };

  const milestonesToRows = (milestones, milestoneFolderConfig) =>
    milestones.asMutable().map((milestone) => itemToRow({ item: milestone, fieldConfig: milestoneFolderConfig }));

  const renderContainerTitle = (name) => <Typography style={{ marginLeft: 40, fontSize: '1rem' }}>{name}</Typography>;

  const renderMilestoneLinkButton = ({ row, id, milestoneSet }) => {
    const canEdit = canEditMilestone(row.permissions);

    return (
      <Cell>
        <ToggleIconButton
          tooltipText={intl.get(
            canEdit ? 'board.views.list.tooltips.edit_milestone' : 'board.views.list.tooltips.view_milestone',
          )}
          generalIcon={canEdit ? <IconEdit /> : <IconInfo />}
          hoverIcon={canEdit ? <IconEdit /> : <IconInfo />}
          disabled={false}
          onClick={() => handleMilestoneEditClick(id, row.id)}
        />
        <DeleteIconButton
          tooltipText={intl.get('board.tooltips.milestone.delete.question.title')}
          onClick={() => handleClickOpenDialog(row, milestoneSet)}
          disabled={!row.permissions.DELETE}
        />
      </Cell>
    );
  };

  const handleExportClick =
    ({ folderName }) =>
    ({ columns, items }) => {
      const exportRequest = generateExportRequest({ columns, items });

      return exportContent({
        boardId: activeBoard.id,
        exportRequest,
      }).then((response) => Promise.resolve(saveAs(response.data, `${folderName}.csv`)));
    };

  if (isLoadingMilestones) {
    return <LinearProgress />;
  }

  return (
    <PageWrapper title={intl.get('app_bar.milestone_list_view')}>
      {activeBoard.milestones_config.length ? (
        milestones.map((item) => {
          const milestoneSet = activeBoard.milestones_config.filter((config) => config.id === item.id)[0];
          const currentViewConfig = viewConfig[milestoneSet.id] || ListViewConfig.defaultValue;

          return (
            <ListBoard
              key={item.id}
              lastActiveItem={lastActiveMilestone}
              board={item}
              items={milestonesToRows(item.milestones, milestoneSet)}
              height={milestones.length === 1 ? window.innerHeight - 192 : 400}
              style={{ marginBottom: 15 }}
              columns={fieldsToColumns(milestoneSet)}
              renderCellComponent={getCellComponentRenderer(milestoneSet.fields, roomMembers)}
              editing={{
                renderLinkButtons: ({ row, id }) =>
                  renderMilestoneLinkButton({
                    row,
                    id,
                    milestoneSet: milestoneSet.id,
                  }),
                width: 110,
              }}
              title={renderContainerTitle(milestoneSet.name)}
              rowToInfo={rowToMilestoneInfo}
              readOnly={!milestoneSet.permissions.ADD_MILESTONE}
              onItemCreateClick={() => handleMilestoneCreateClick({ milestoneSet })}
              tooltips={{
                create: intl.get('board.tooltips.add_milestone'),
              }}
              filters={filters}
              onFiltersChange={handleFilter}
              onSearch={handleSearch}
              onSortChange={(sorter) =>
                handleSortChange({
                  sorter,
                  milestoneFolderId: milestoneSet.id,
                })
              }
              onColumnOrderChange={(columnOrder) =>
                handleColumnOrderChange({
                  columnOrder,
                  milestoneFolderId: milestoneSet.id,
                })
              }
              onColumnVisibilityChange={(visibleColumns) =>
                handleColumnVisibilityChange({
                  visibleColumns,
                  milestoneFolderId: milestoneSet.id,
                })
              }
              searchTerm={searchTerm}
              selection={{
                enableSelection: true,
                helperText: intl.get('board.views.list.tooltips.cards.export.helper_text'),
                numberOfItemsMessage: generateNumberOfCardsMessage,
              }}
              sorter={currentViewConfig.sorter}
              defaultSorter={[{ columnName: getEntryTitle(milestoneSet.fields) }]}
              columnOrder={currentViewConfig.columnOrder}
              visibleColumns={currentViewConfig.visibleColumns}
              onExportClick={handleExportClick({
                folderName: milestoneSet.name,
              })}
            />
          );
        })
      ) : (
        <Error text={intl.get('milestone.not_found')} />
      )}
      <DeleteMilestoneDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={handleDelete}
        titles={{
          deleteDialogTitle: intl.get('board.tooltips.milestone.delete.question.title'),
          deleteDialogMessage: intl.get('board.tooltips.milestone.delete.question.message'),
        }}
      />
    </PageWrapper>
  );
};

const ConnectedListMilestoneContainer = connect(mapStateToProps, mapDispatchToProps)(ListMilestoneContainer);

export default ConnectedListMilestoneContainer;
