import { User } from "../permission/AuthorityModel";
import { PermissionModel } from "../permission/PermissionModel";
import { Step } from "../folder/StepModel";

export enum CardType {
    MILESTONE = 'MILESTONE',
    QUESTION = 'QUESTION',
    DELIVERY = 'DELIVERY',
}

export interface Option {
    id: string;
    name: string;
}

export enum FieldType {
    string = 'string',
    date = 'date',
    list = 'list',
    "unique_document_id" = 'unique_document_id',
    member = 'member',
    numeric = 'numeric',
    "rich-text" = 'rich-text',
    "sequence-number" = 'sequence-number',
    "task-done" = 'task-done',
    "task-responsible" = 'task-responsible',
    "unknown" = 'unknown'
}

export interface Field {
    id: string;
    name: string;
    type: FieldType;
    value: string;
    limited_to?: string[];
}
export interface Card {
    id: string;
    name: string;
    fields: Field[];
    child_count: number;
    comment_count: number;
    locked: boolean;
    permissions: PermissionModel[];
    owner: string;
    creation_date: Date;
    created_by: string;
    modified_date: Date;
    modified_by: string;
}

export interface Question extends Card {
    steps: Step;
}

export interface CardAttachment {
    type: string;
    id: string;
    parent_id: string;
    name: string;
    path: string;
    created: Date;
    last_modified: Date;
    created_by: User;
    last_modified_by: User;
    owner: User;
    lock_info: any;
    app_data: any;
    access_rights: any;
    version: string;
    mime_type: string;
    file_size: number;
    comment_count: number;
}

export interface CardLog {
    id: string;
    created_by: string;
    created: Date;
    action: string;
}

export enum CardLogType {
    AUDIT_ACTION_CREATE = "AUDIT_ACTION_CREATE",
    AUDIT_ACTION_PROPERTY_CHANGED = "AUDIT_ACTION_PROPERTY_CHANGED",
    AUDIT_ACTION_ADDED_LINK = "AUDIT_ACTION_ADDED_LINK",
    AUDIT_ACTION_EDITED_LINK = "AUDIT_ACTION_EDITED_LINK",
    AUDIT_ACTION_RENAME = "AUDIT_ACTION_RENAME",
    AUDIT_ACTION_ENTRY_MOVED = "AUDIT_ACTION_ENTRY_MOVED",
    AUDIT_ACTION_DELETE_COMMENT = "AUDIT_ACTION_DELETE_COMMENT",
    AUDIT_ACTION_ADD_COMMENT = "AUDIT_ACTION_ADD_COMMENT",
    AUDIT_ACTION_UPDATE_COMMENT = "AUDIT_ACTION_UPDATE_COMMENT",
    AUDIT_ACTION_REVISE = "AUDIT_ACTION_REVISE",
    AUDIT_ACTION_SHARE = "AUDIT_ACTION_SHARE",
    AUDIT_ACTION_DELETE = "AUDIT_ACTION_DELETE",
    AUDIT_ACTION_ADDED_FILE = "AUDIT_ACTION_ADDED_FILE",
    AUDIT_ACTION_FILE_NEW_VERSION = "AUDIT_ACTION_FILE_NEW_VERSION",
    AUDIT_ACTION_MOVED_LINK_TO = "AUDIT_ACTION_MOVED_LINK_TO",
    AUDIT_ACTION_FILE_MOVED_TO = "AUDIT_ACTION_FILE_MOVED_TO",
    AUDIT_ACTION_MOVED_LINK_FROM = "AUDIT_ACTION_MOVED_LINK_FROM",
    AUDIT_ACTION_FILE_MOVED_FROM = "AUDIT_ACTION_FILE_MOVED_FROM",
    AUDIT_ACTION_ENTRY_COPIED = "AUDIT_ACTION_ENTRY_COPIED",
    AUDIT_ACTION_LINK_COPIED = "AUDIT_ACTION_LINK_COPIED",
    AUDIT_ACTION_FILE_COPIED = "AUDIT_ACTION_FILE_COPIED",
    AUDIT_ACTION_CREATE_PUBLIC_LINK = "AUDIT_ACTION_CREATE_PUBLIC_LINK",
    AUDIT_ACTION_DELETE_PUBLIC_LINK = "AUDIT_ACTION_DELETE_PUBLIC_LINK",
    AUDIT_ACTION_PUBLISH = "AUDIT_ACTION_PUBLISH",
    AUDIT_ACTION_ARCHIVE = "AUDIT_ACTION_ARCHIVE",
    AUDIT_ACTION_LOCK = "AUDIT_ACTION_LOCK",
    AUDIT_ACTION_UNLOCK = "AUDIT_ACTION_UNLOCK",
    AUDIT_ACTION_CHANGE_OWNER = "AUDIT_ACTION_CHANGE_OWNER",
    AUDIT_ACTION_EXTERNAL_UPDATE = "AUDIT_ACTION_EXTERNAL_UPDATE",
    AUDIT_ACTION_COMMENT_MENTIONED_EMAIL_SENT = "AUDIT_ACTION_COMMENT_MENTIONED_EMAIL_SENT",
    AUDIT_ACTION_EMAIL_SENT = "AUDIT_ACTION_EMAIL_SENT",
}

export interface CardLog {
    type: CardLogType;
    user: User;
    created: Date;
    value: string;
}