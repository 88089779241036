import {
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedSelection,
  IntegratedSorting,
  EditingState,
  FilteringState,
  RowDetailState,
  SortingState,
  SearchState,
  SelectionState,
  GroupingState,
  PagingState,
  CustomPaging,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  DragDropProvider,
  Table,
  VirtualTable,
  SearchPanel,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableEditColumn,
  TableEditRow,
  TableInlineCellEditing,
  GroupingPanel,
  TableGroupRow,
  TableFilterRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  Toolbar,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

const InteraxoTable = ({
  rows,
  virtual,
  height,
  columns,
  filtering,
  sorting,
  editing,
  columnReordering,
  columnVisibility,
  columnResizing,
  rowDetailing,
  providers,
  gridConfig,
  tableConfig,
  tableHeaderRowConfig,
  toolbarConfig,
  search,
  beforeTable,
  afterTable,
  selection,
  grouping,
  inlineEditing,
  paging,
  plugins,
}) => {
  return (
    <Grid rows={rows} columns={columns} {...gridConfig}>
      <SearchState {...(search ? search.stateConfig : [])} />

      {paging && <PagingState {...paging.stateConfig} />}
      {paging && <CustomPaging {...paging.config} />}

      {filtering && <FilteringState {...filtering.stateConfig} />}
      <IntegratedFiltering {...(filtering ? filtering.config : [])} />

      {sorting && <SortingState {...sorting.stateConfig} />}
      {sorting && <IntegratedSorting {...sorting.config} />}

      {grouping && <GroupingState />}
      {grouping && <IntegratedGrouping {...grouping.config} />}

      {selection && <SelectionState {...selection.stateConfig} />}
      {selection && <IntegratedSelection />}

      {columnReordering && <DragDropProvider {...columnReordering.dragAndDropConfig} />}

      {rowDetailing && <RowDetailState {...rowDetailing.stateConfig} />}

      {editing && <EditingState {...editing.stateConfig} />}

      {Array.isArray(providers) && providers}

      {beforeTable &&
        (Array.isArray(beforeTable) ? beforeTable.map((beforeTableItem) => beforeTableItem) : beforeTable)}

      {virtual && height && <VirtualTable height={height} {...tableConfig} />}
      {!virtual && <Table {...tableConfig} />}

      {afterTable && (Array.isArray(afterTable) ? afterTable.map((afterTableItem) => afterTableItem) : afterTable)}

      {columnResizing && <TableColumnResizing {...columnResizing} />}

      <TableHeaderRow showSortingControls={!!sorting} {...tableHeaderRowConfig} />

      {editing && <TableEditColumn {...editing.config} />}
      {editing && <TableEditRow {...editing.rowConfig} />}

      {inlineEditing && <TableInlineCellEditing {...inlineEditing.config} />}

      {columnReordering && (
        <TableColumnReordering
          defaultOrder={
            columnReordering.config && columnReordering.config.customOrder && columnReordering.config.customOrder.length
              ? columnReordering.config.customOrder
              : columns.map((column: any) => column.name)
          }
          {...columnReordering.config}
        />
      )}

      {rowDetailing && <TableRowDetail {...rowDetailing.config} />}

      {columnVisibility && <TableColumnVisibility {...columnVisibility.config} />}
      {(columnVisibility || toolbarConfig || search) && <Toolbar {...toolbarConfig} />}
      {search && <SearchPanel {...search.panelConfig} />}
      {columnVisibility && <ColumnChooser {...columnVisibility.columnChooserConfig} />}

      {filtering && filtering.rowConfig && <TableFilterRow {...filtering.rowConfig} />}

      {selection && <TableSelection {...selection.config} />}

      {grouping && <TableGroupRow />}
      {grouping && <GroupingPanel {...grouping.panelConfig} />}

      {paging && <PagingPanel {...paging.panelConfig} />}

      {Array.isArray(plugins) && plugins}
    </Grid>
  );
};

const Cell = VirtualTable.Cell;
const Row = VirtualTable.Row;

export { Cell, Row };
export default InteraxoTable;
