import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import intl from 'react-intl-universal';
import queryString from 'query-string';

import LinearProgress from '@mui/material/LinearProgress';
import Boards from './Boards';
import * as boardsActions from '../../thunks/Boards';
import { actions as communityActions } from '../../ducks/Communities';
import { actions as roomActions } from '../../ducks/Rooms';
import * as communitiesActions from '../../thunks/Communities';
import * as roomsActions from '../../thunks/Rooms';
import PageWrapper from '../../common/PageWrapper';
import { selectIsLoadingBoards, selectBoards, selectIsRequested } from '../../ducks/Boards';
import {
  selectCommunities,
  selectActiveCommunity,
  selectIsLoadingCommunities,
  selectWasRequested,
} from '../../ducks/Communities';
import { selectRooms, selectActiveRoom, selectIsLoadingRooms, selectRequestedCommunity } from '../../ducks/Rooms';
import { renderSelectField } from '../../utils/RenderUtil';
import { useLocation, useNavigate } from 'react-router-dom';
import { Board } from '@/models/BoardModel';
import { Room } from '@/models/RoomModel';
import { Community } from '@/models/CommunityModel';
import Box from '@mui/material/Box';

const useStyles = () => ({
  field: {
    minWidth: '200px',
  },
  loading: {
    marginTop: '15px',
  },
});

const mapStateToProps = () =>
  createStructuredSelector({
    boards: selectBoards(),
    isLoading: createSelector(
      selectIsLoadingBoards(),
      selectIsLoadingCommunities(),
      selectIsLoadingRooms(),
      (isLoadingBoards, isLoadingCommunities, isLoadingRooms) =>
        isLoadingBoards || isLoadingCommunities || isLoadingRooms,
    ),
    isBoardsRequested: selectIsRequested(),
    communities: selectCommunities(),
    communityId: selectActiveCommunity(),
    rooms: selectRooms(),
    roomId: selectActiveRoom(),
    wasCommunityRequested: selectWasRequested(),
    roomsRequestedCommunity: selectRequestedCommunity(),
  });

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ...boardsActions,
      ...communitiesActions,
      ...roomsActions,
      setActiveCommunity: communityActions.setActiveCommunity,
      setActiveRoom: roomActions.setActiveRoom,
    },
    dispatch,
  ),
});

const BoardsContainer = ( {
  communityId,
  roomId,
  isBoardsRequested,
  isLoading,
  boards,
  communities,
  rooms,
  setActiveCommunity,
  setActiveRoom,
  fetchBoards,
  fetchRooms,
  roomsRequestedCommunity,
}: {
  communityId: string;
  roomId: string;
  isBoardsRequested: boolean;
  isLoading: boolean;
  boards: Board[];
  communities: Community[];
  rooms: Room[];
  setActiveCommunity: (communityId: string) => void;
  setActiveRoom: (roomId: string) => void;
  fetchBoards: (communityId: string, roomId: string) => void;
  fetchRooms: (communityId: string) => void;
  roomsRequestedCommunity: string;
}) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const resolveCommunityIdFromUrl = () => queryString.parse(location.search).communityId || '';
  const resolveRoomIdFromUrl = () => queryString.parse(location.search).roomId || '';

  const setupRoomsByCommunity = (communityId: string, roomId: string) => {
    if (roomId) {
      setActiveRoom({ roomId });
    }
    if (roomsRequestedCommunity !== communityId) {
      fetchRooms({ communityId });
    }
  };

  const shouldChangeHistory = (currentCommunityId: string, currentRoomId: string) =>
    resolveCommunityIdFromUrl() !== currentCommunityId || resolveRoomIdFromUrl() !== currentRoomId;

  const changeHistory = (currentCommunityId: string, currentRoomId: string) => {
    if (shouldChangeHistory(currentCommunityId, currentRoomId)) {
      const newPath = currentRoomId
        ? `?communityId=${currentCommunityId}&roomId=${currentRoomId}`
        : `?communityId=${currentCommunityId}`;

      navigate(newPath);
    }
  };

  useEffect(() => {
    const roomIdFromUrl = resolveRoomIdFromUrl();
    const currentRoomId = roomIdFromUrl || roomId;

    setupRoomsByCommunity(communityId, currentRoomId);
    changeHistory(communityId, currentRoomId);

    if (
      currentRoomId &&
      (isBoardsRequested.roomId !== currentRoomId || isBoardsRequested.communityId !== communityId)
    ) {
      fetchBoards({ communityId, roomId: currentRoomId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setupRoomsByCommunity(communityId, roomId);
    changeHistory(communityId, roomId);

    if (roomId && (isBoardsRequested.roomId !== roomId || isBoardsRequested.communityId !== communityId)) {
      fetchBoards({ communityId, roomId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId, roomId]);

  const handleCommunityChange = (event: any) => {
    setActiveCommunity({ communityId: event.target.value });
    setActiveRoom({ roomId: '' });
    changeHistory(event.target.value, '');
  };

  const handleRoomChange = (event: any) => {
    setActiveRoom({ roomId: event.target.value });
    changeHistory(communityId, event.target.value);
  };

  const communityOptions = communities.map((community) => {
    let label = community.name;

    if (community.project_name) {
      label = community.name + ' - ' + community.project_name;
    }

    if (community.name === '') {
      label = community.id;
    }

    return {
      id: community.id,
      name: label,
    };
  });

  return (
    <React.Fragment>
      <PageWrapper title={intl.get('app_bar.boards')}>
      <Box sx={{ display: 'flex', gap: 2 }}>
          {renderSelectField({
            name: 'communityId',
            label: intl.get('boards.community'),
            value: communityId,
            handleChange: handleCommunityChange,
            options: communityOptions,
            className: classes.field,
          })}
          {renderSelectField({
            name: 'roomId',
            label: intl.get('boards.room'),
            value: roomId,
            handleChange: handleRoomChange,
            options: rooms,
            className: classes.field,
          })}
        </Box>
        {isLoading && <LinearProgress sx={classes.loading} />}
        {!isLoading && <Boards roomId={roomId} rooms={rooms} boards={boards} />}
      </PageWrapper>
    </React.Fragment>
  );
};

const ConnectedBoardsContainer = connect(mapStateToProps, mapDispatchToProps)(BoardsContainer);

export default ConnectedBoardsContainer;
