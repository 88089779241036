import { Route, Routes } from 'react-router-dom';
import { BoardsContainer as Boards } from './boards';
import { BoardContainer as Board } from './board';
import { CardContainer } from './card';
import { DeliveryContainer } from './delivery';
import { MilestoneContainer } from './milestone';
import { Docs } from './docs';
import Error from '../common/Error';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Boards />} />
    <Route path="/docs" element={<Docs />} />
    <Route path="/:boardId" element={<Board />} />
    <Route path="/:boardId/create-card" element={<CardContainer />} />
    <Route path="/:boardId/cards/:cardId" element={<CardContainer />} />
    <Route path="/:boardId/milestoneset/:milestoneFolderId/create-milestone" element={<MilestoneContainer />} />
    <Route path="/:boardId/milestoneset/:milestoneFolderId/milestones/:milestoneId" element={<MilestoneContainer />} />
    <Route path="/:boardId/create-delivery" element={<DeliveryContainer />} />
    <Route path="/:boardId/deliveries/:deliveryId" element={<DeliveryContainer />} />
    <Route
      path="*"
      element={<Error />}
    />
  </Routes>
);

export default AppRoutes;