import { useState } from 'react';
import { parseISO } from 'date-fns';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import intl from 'react-intl-universal';
import IconOpenTile from '@mui/icons-material/GridOn';
import OpenQuestionList from '@mui/icons-material/List';
import IconDecisionLog from '@mui/icons-material/PlaylistAddCheck';
import OpenDeliveriesList from '@mui/icons-material/LocalShippingOutlined';
import WeekIcon from '@mui/icons-material/DateRange';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  LIST_BOARD_VIEW_MODE,
  LIST_DELIVERY_VIEW_MODE,
  LIST_MILESTONE_VIEW_MODE,
  WEEK_BOARD_VIEW_MODE,
} from '../../utils/ViewModeUtil';
import OpenMilestonesList from '../../common/icons/OutlinedMilestoneIcon';
import { useNavigate } from 'react-router-dom';
import { Theme, useTheme } from '@mui/material';
import { Board } from '@/models/BoardModel';

const useStyles = (theme: Theme) => ({
  card: {
    maxWidth: 400,
    backgroundColor: '#fafafa',
  },
  header: {
    paddingTop: '8px',
    paddingBottom: '0px',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

const BoardItem = ({ board }: { board: Board }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleOpenTileView = () => navigate(`/${board.id}`);
  const handleOpenWeekView = () => navigate(`/${board.id}?view=${WEEK_BOARD_VIEW_MODE}`);
  const handleOpenListView = () => navigate(`/${board.id}?view=${LIST_BOARD_VIEW_MODE}`);
  const handleOpenDecisionLogView = () =>
    navigate(`/${board.id}?view=${LIST_BOARD_VIEW_MODE}&step=${board.step_config.decision_log_step_id}`);
  const handleOpenMilestoneListView = () => navigate(`/${board.id}?view=${LIST_MILESTONE_VIEW_MODE}`);
  const handleOpenDeliveryListView = () => navigate(`/${board.id}?view=${LIST_DELIVERY_VIEW_MODE}`);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={classes.card}>
      <CardHeader sx={classes.header} title={board.name} />
      <CardActions sx={classes.actions} disableSpacing>
        <Tooltip title={intl.get('boards.tooltips.tile_view')}>
          <IconButton onClick={handleOpenTileView} className={'Open-tile-view'}>
            <IconOpenTile />
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.get('boards.tooltips.week_view')}>
          <IconButton onClick={handleOpenWeekView} className={'Open-week-view'}>
            <WeekIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.get('boards.tooltips.decision_log')}>
          <IconButton onClick={handleOpenDecisionLogView} className={'Open-decision-log-view'}>
            <IconDecisionLog />
          </IconButton>
        </Tooltip>
        {board.milestones_config.length ? (
          <Tooltip title={intl.get('boards.tooltips.milestone_list_view')}>
            <IconButton onClick={handleOpenMilestoneListView} className={'Open-milestone-list-view'}>
              <OpenMilestonesList />
            </IconButton>
          </Tooltip>
        ) : (
          <span />
        )}
        <Tooltip title={intl.get('boards.tooltips.question_list_view')}>
          <IconButton onClick={handleOpenListView} className={'Open-question-list-view'}>
            <OpenQuestionList />
          </IconButton>
        </Tooltip>
        {board.delivery_config ? (
          <Tooltip title={intl.get('boards.tooltips.delivery_list_view')}>
            <IconButton onClick={handleOpenDeliveryListView} className={'Open-delivery-list-view'}>
              <OpenDeliveriesList />
            </IconButton>
          </Tooltip>
        ) : (
          <span />
        )}
        <Tooltip title={intl.get('boards.tooltips.expand')}>
          <IconButton
            sx={{
              ...classes.expand,
              [classes.expandOpen]: expanded,
            }}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label={intl.get('boards.tooltips.expand')}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography>
            {intl.get('boards.creation_date', {
              date: parseISO(board.creation_date),
            })}
          </Typography>
          <Typography>
            {intl.get('boards.last_modified_date', {
              date: parseISO(board.last_modified_date),
            })}
          </Typography>
          <Typography paragraph>
            {intl.get('boards.description', {
              description: board.description,
            })}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default BoardItem;
