import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import React from 'react';
import { red } from '@mui/material/colors';

type Props = {
  children?: React.ReactNode;
};

export const AppThemeProvider: React.FC<Props> = ({ children }) => {
  const theme = responsiveFontSizes(
    createTheme({
      overrides: {
        MuiTableRow: {
          root: {
            height: 30,
          },
          head: {
            height: 30,
          },
        },
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: '#3eb1c8',
          },
        },
        MuiPickersDay: {
          '&$selected': {
            backgroundColor: '#3eb1c8',
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: 'standard',
          },
        },
        MuiFormControl: {
          defaultProps: {
            variant: 'standard',
          },
        },
        MuiInputBase: {
          root: {
            fontSize: '0.875rem',
            fontWeight: 500,
          },
        },
      },
      palette: {
        primary: {
          light: '#42719a',
          main: '#13627c',
          dark: '#063544',
          contrastText: '#fff',
        },
        secondary: {
          light: '#42719a',
          main: '#13627c',
          dark: '#063544',
          contrastText: '#fff',
        },
        error: red,
      },
    }),
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
