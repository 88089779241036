import { Box, Theme, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

const useStyles = (theme: Theme) => ({
  root: {
    maxWidth: theme.spacing(25),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    position: 'relative',
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: '4px',
    minHeight: theme.spacing(2),
    '&:before': {
      content: '" "',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: '-1',
      borderRadius: '4px',
      background: theme.palette.primary.main,
      transformOrigin: 'bottom left',
      transform: 'skew(-30deg, 0deg)',
    },
  },
});

const Tab = ({ children }: { children: any }) => {
  const theme = useTheme();
  const classes: any = useStyles(theme);

  return (
    <Box component="div" sx={classes.root}>
      <Typography variant="body2" sx={classes.content}>
        {children}
      </Typography>
    </Box>
  );
};

export default Tab;
