import ReactDOM from 'react-dom/client';
import React from 'react';
import './main.css';

import './main.css';
import { isAuth0 } from '@/utils/AuthUtil';
import { AUTH0_CONFIG, KEYCLOAK_CONFIG } from '@/services/AuthConfig';
import { Auth0Provider } from './common/auth0';
import { KeycloakProvider } from './common/keycloak';
import Authentication from './Authentication';

// @ts-expect-error this is desktop app default object
window.appInfo = {
  version: import.meta.env.VITE_APP_VERSION,
  sha: import.meta.env.VITE_APP_GIT_SHA,
};

const onRedirectCallback = (appState: { targetUrl: string | URL | null | undefined }) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
  );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    {isAuth0() ? (
      <Auth0Provider
        audience={AUTH0_CONFIG.audience}
        domain={AUTH0_CONFIG.domain}
        client_id={AUTH0_CONFIG.clientId}
        scope={AUTH0_CONFIG.scope}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <Authentication />
      </Auth0Provider>
    ) : (
      <KeycloakProvider
        url={KEYCLOAK_CONFIG.url}
        client_id={KEYCLOAK_CONFIG.clientId}
        realm={KEYCLOAK_CONFIG.realm}
        scope={KEYCLOAK_CONFIG.scope}
        redirect_uri={window.location.href}
      >
        <Authentication />
      </KeycloakProvider>
    )}
  </React.StrictMode>,
);
