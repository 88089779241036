import React from 'react';
import intl from 'react-intl-universal';
import { default as Table, Cell } from '../../common/table';
import Toolbar from '../common/Toolbar';
import MuiToolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import DetailedCardRow from './DetailedCardRow';
import {
  COMMENT_COLUMN,
  ATTACHMENT_COLUMN,
  OWNER_COLUMN,
  CREATED_COLUMN,
  CREATED_BY_COLUMN,
  CHANGED_COLUMN,
  CHANGED_BY_COLUMN,
} from '../../utils/ListViewUtil';
import { getCustomSorters } from '../../utils/RenderUtil';
import { connectProps } from '@devexpress/dx-react-core';
import { ColumnChooserButton } from '../common/ColumnChooserButton';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import CommentIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import AttachmentIcon from '@mui/icons-material/AttachFileOutlined';
import { Box, useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';

const useStyles = () => ({
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
  toolbar: {
    borderTop: '1px solid rgb(191, 191, 191)',
    backgroundColor: 'white',
  },
  flexGrow: {
    flex: '1 1 auto',
  },
});

const defaultHiddenColumnNames = [OWNER_COLUMN, CREATED_COLUMN, CREATED_BY_COLUMN, CHANGED_COLUMN, CHANGED_BY_COLUMN];

const ListBoard = (props) => {
  const {
    board,
    items,
    height,
    style,
    columns,
    renderCellComponent,
    editing,
    rowToInfo,
    getRowId,
    selection,
    columnOrder,
    sorter,
    defaultSorter,
    filters,
    lastActiveItem,
    width,
    readOnly,
    title,
    subscription,
    tooltips,
    onItemCreateClick,
    onExportClick,
    onFiltersChange,
    onSearch,
    onSortChange,
    onColumnOrderChange,
    onColumnVisibilityChange,
    searchTerm,
    visibleColumns,
  } = props;
  const classes: any = useStyles();
  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [state, setState] = React.useState({
    filtering: filters && !!filters.length,
    filteredRows: [],
    selectedRows: [],
    hiddenColumnNames: [],
    disableExport: false,
  });

  const vtRef = React.useRef();

  const extractHiddenByDefaultColumns = React.useCallback(
    (cols) => {
      if (visibleColumns && visibleColumns.length > 0) {
        return cols.filter((column) => !visibleColumns.includes(column.name)).map((column) => column.name);
      }

      return cols.filter((column) => defaultHiddenColumnNames.includes(column.name)).map((column) => column.name);
    },
    [visibleColumns],
  );

  const extractHiddenColumns = React.useCallback(
    ({ board, columns }) =>
      columns.length > 1 && isSmallDown
        ? columns
            .filter(
              (column) => board.field_config && board.field_config.question_field === column.name,
            )
            .map((column) => column.name)
        : extractHiddenByDefaultColumns(columns),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, extractHiddenByDefaultColumns],
  );

  const handleItemCreateClick = () => onItemCreateClick();

  const handleExportClick = () => {
    const { hiddenColumnNames, selectedRows, filteredRows } = state;

    const itemsToExport =
      selectedRows.length > 0 ? selectedRows : (filteredRows.length ? filteredRows : items).map((item) => item.id);

    const columnsToExport = columns.filter((column) => !hiddenColumnNames.includes(column.name));
    const includeComments = !hiddenColumnNames.includes(COMMENT_COLUMN);

    return onExportClick({
      columns: columnsToExport,
      items: itemsToExport,
      includeComments,
    });
  };

  const handleFilteringToggle = () => {
    onFiltersChange([]);
    setState((prev) => ({ ...prev, filtering: !prev.filtering }));
  };

  const toolbarRoot = React.useMemo(
    () =>
      connectProps(Toolbar, () => ({
        readOnly,
        filtering: state.filtering,
        handleItemCreateClick,
        handleExportClick,
        handleFilteringToggle,
        tooltips: {
          ...tooltips,
          export: intl.get('board.views.list.tooltips.export'),
          add_filtering: intl.get('board.views.list.tooltips.add_filtering'),
          remove_filtering: intl.get('board.views.list.tooltips.remove_filtering'),
        },
        title,
        subscription,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.filtering, readOnly, title, subscription, tooltips],
  );

  React.useEffect(() => {
    const defaultHiddenColumnNames = extractHiddenColumns({
      board,
      columns,
    });

    if (lastActiveItem) {
      vtRef.current.scrollToRow(lastActiveItem);
    }

    setState((prev) => ({ ...prev, hiddenColumnNames: defaultHiddenColumnNames }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    toolbarRoot.update();
  });

  const defaultColumnWidths = columns.map((column) => ({
    columnName: column.name,
    width: column.name === 'commentCount' || column.name === 'childCount' ? 70 : 170,
  }));

  return (
    <Paper style={style}>
      <Table
        columns={columns || []}
        rows={items}
        virtual
        height={height}
        filtering={
          state.filtering && {
            rowConfig: {
              messages: {
                filterPlaceholder: intl.get('board.views.list.placeholders.filter'),
              },
            },
            stateConfig: {
              columnExtensions: [
                {
                  columnName: COMMENT_COLUMN,
                  filteringEnabled: false,
                },
                {
                  columnName: ATTACHMENT_COLUMN,
                  filteringEnabled: false,
                },
              ],
              onFiltersChange: onFiltersChange,
              defaultFilters: filters,
            },
          }
        }
        gridConfig={{ getRowId: getRowId }}
        selection={
          selection.enableSelection && {
            stateConfig: {
              onSelectionChange: (selection: any) => setState((prev) => ({ ...prev, selectedRows: selection })),
            },
            config: {
              selectByRowClick: true,
              highlightRow: true,
              showSelectionColumn: false,
            },
          }
        }
        sorting={{
          stateConfig: {
            columnExtensions: [
              {
                columnName: COMMENT_COLUMN,
                sortingEnabled: false,
              },
              {
                columnName: ATTACHMENT_COLUMN,
                sortingEnabled: false,
              },
            ],
            defaultSorting: sorter || defaultSorter,
            onSortingChange: (sorting: any) => onSortChange(sorting),
          },
          config: {
            columnExtensions: getCustomSorters(columns),
          },
        }}
        tableHeaderRowConfig={{
          messages: {
            sortingHint: intl.get('board.views.list.tooltips.sort'),
          },
          contentComponent: ({ children, column }: any) => (
            <TableHeaderRow.Content>
              {column.name === 'commentCount' ? (
                <CommentIcon />
              ) : column.name === 'childCount' ? (
                <AttachmentIcon />
              ) : (
                children
              )}
            </TableHeaderRow.Content>
          ),
        }}
        editing={{
          config: {
            cellComponent: ({ row }: any) => {
              return editing.renderLinkButtons({ row, id: board.id });
            },
            width: editing.width,
          },
        }}
        columnResizing={{
          defaultColumnWidths,
        }}
        columnReordering={{
          config: {
            customOrder: columnOrder,
            onOrderChange: (order: any) => onColumnOrderChange(order),
          },
        }}
        columnVisibility={{
          config: {
            defaultHiddenColumnNames: extractHiddenColumns({
              board,
              columns,
            }),
            onHiddenColumnNamesChange: (hiddenColumnNames: any) => {
              const visibleColumns = columns
                .map((column: any) => column.name)
                .filter((columnName: any) => !hiddenColumnNames.includes(columnName));
              onColumnVisibilityChange(visibleColumns);
              setState((prev) => ({ ...prev, hiddenColumnNames }));
            },
          },
          columnChooserConfig: {
            messages: {
              showColumnChooser: intl.get('board.views.list.tooltips.show_hide_columns'),
            },
            toggleButtonComponent: ColumnChooserButton,
          },
        }}
        rowDetailing={{
          config: {
            contentComponent: ({ row }: any) => <DetailedCardRow row={row} columns={columns} rowToInfo={rowToInfo} />,
          },
        }}
        toolbarConfig={{
          rootComponent: toolbarRoot,
        }}
        search={{
          panelConfig: {
            messages: {
              searchPlaceholder: intl.get('board.views.list.placeholders.search'),
            },
          },
          stateConfig: {
            defaultValue: searchTerm,
            onValueChange: onSearch,
          },
        }}
        tableConfig={{
          estimatedRowHeight: 75,
          ref: vtRef,
          cellComponent: (obj) => <Cell>{renderCellComponent(obj)}</Cell>,
          messages: {
            noData: <Box sx={classes.noData}>{intl.get('common.content.empty')}</Box>,
          },
        }}
      />
      {selection.enableSelection && (
        <MuiToolbar sx={classes.toolbar}>
          {selection.helperText || ''}
          <Box sx={classes.flexGrow} />
          {selection.numberOfItemsMessage &&
            selection.numberOfItemsMessage({
              totalCount: items.length,
              selectedCount: state.selectedRows.length,
              filteredCount: state.filteredRows.length,
            })}
        </MuiToolbar>
      )}
    </Paper>
  );
};

export default ListBoard;
