import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Header from './Header';
import { selectUserProfile } from '../ducks/Auth';
import { selectShowUserMenu, selectUserMenuLocation } from '../ducks/App';
import * as authActions from '../thunks/Auth';
import { actions as appActions } from '../ducks/App';

const mapStateToProps = () =>
  createStructuredSelector({
    user: selectUserProfile(),
    showUserMenu: selectShowUserMenu(),
    userMenuLocation: selectUserMenuLocation(),
  });

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...authActions,
      ...appActions,
    },
    dispatch,
  ),
});

const HeaderContainer = ({
  actions,
  showUserMenu,
  onSidebarClick,
  activeBoard,
  userMenuLocation,
  user,
  sidebarOpen,
  onLogout,
}: {
  actions: any;
  showUserMenu: boolean;
  onSidebarClick: () => void;
  activeBoard: any;
  userMenuLocation: string;
  user: any;
  sidebarOpen: boolean;
  onLogout: () => void;
}) => {
  const handleUserMenu = (location: any) => {
    const locationId = location.id ? location.id : null;
    actions.showUserMenu({
      show: !showUserMenu,
      location: locationId,
    });
  };
  const handleLogout = () => {
    actions.showUserMenu({ show: false, location: null });
    actions.logout();
    onLogout();
  };
  const handleHideUserMenu = () => {
    actions.showUserMenu({ show: false });
  };

  return (
    <Header
      activeBoard={activeBoard}
      onSidebarClick={onSidebarClick}
      onUserMenuClick={handleUserMenu}
      onHideUserMenu={handleHideUserMenu}
      showUserMenu={showUserMenu}
      userMenuLocation={userMenuLocation}
      user={user}
      sidebarOpen={sidebarOpen}
      onLogout={handleLogout}
    />
  );
};

const ConnectedHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);

export default ConnectedHeaderContainer;
