import React, { useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Box } from '@mui/material';

const EDITOR_LANGUAGES = ['en', 'no', 'se'];
const EDITOR_CUSTOM_STYLESHEET = ['/resources/css/tinymce.css'];
const EDITOR_DEFAULT_INSERT_BUTTONS = ['link', 'hr', 'specialchar'];
const EDITOR_FILE_UPLOAD_BUTTON = 'fileupload';
const EDITOR_FILE_UPLOAD_BUTTON_INDEX = 1;
const EDITOR_INSERT_TABLE_BUTTON = 'table';
const EDITOR_INSERT_TABLE_BUTTON_INDEX = 2;

const useStyles = () => ({
  error: {
    position: 'relative',
    bottom: '-15px',
    fontSize: '12px',
    lineHeight: '12px',
    color: 'rgb(244, 67, 54)',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: '16px',
  },
});

// eslint-disable-next-line
export const isEmptyContent = (content: string) => {
  content = (content || '').trim();
  return (
    !content ||
    content === '<p></p>' ||
    content === '<p><br /></p>' ||
    content === `<p>\n  <br /></p>` ||
    content === '<br _moz_editor_bogus_node="TRUE" />'
  );
};

function RTEditor({
  enableMentions,
  enableInsertTable,
  enableImageDimensions,
  enableFileUpload,
  insertImageAsThumbnail,
  includeLabel,
  id,
  placeholder,
  uploadHandler,
  minHeight,
  maxHeight,
  height,
  width,
  label,
  errorText,
  ...props
}: {
  enableMentions: boolean;
  enableInsertTable: boolean;
  enableImageDimensions: boolean;
  enableFileUpload: boolean;
  insertImageAsThumbnail: boolean;
  includeLabel: boolean;
  id: string;
  placeholder: string;
  uploadHandler: (file: File) => void;
  minHeight: number;
  maxHeight: number;
  height: number;
  width: number;
  label: string;
  errorText: string;
  mentionsFetch: (searchTerm: string, callback: (userinfo: any) => void) => void;
  listeners: any;
  style: any;
}) {
  const classes: any = useStyles();
  const editorRef = useRef(null);

  const getSelectorId = (id: string) => () => `field-${id.split(':')[1] || id.split(':')[0]}-RTE`;
  const getEditorConfig = () => {
    const insertButtons = EDITOR_DEFAULT_INSERT_BUTTONS.slice();
    let editorPlugins = 'code link hr fullscreen advlist lists';
    const toolbar =
        'image | undo redo | forecolor backcolor formatpainter | bold italic underline strikethrough | numlist bullist | formatselect | alignleft aligncenter alignright alignjustify | outdent indent | table | hr link';

    // Enable the fileupload-button if specified and not already added.
    if (enableFileUpload && EDITOR_DEFAULT_INSERT_BUTTONS.indexOf(EDITOR_FILE_UPLOAD_BUTTON) === -1) {
      insertButtons.splice(EDITOR_FILE_UPLOAD_BUTTON_INDEX, 0, EDITOR_FILE_UPLOAD_BUTTON);
    }

    // Enable table features if specified.
    if (enableInsertTable && EDITOR_DEFAULT_INSERT_BUTTONS.indexOf(EDITOR_INSERT_TABLE_BUTTON) === -1) {
      insertButtons.splice(EDITOR_INSERT_TABLE_BUTTON_INDEX, 0, EDITOR_INSERT_TABLE_BUTTON);
    }

    if (enableFileUpload) {
      editorPlugins += ' powerpaste image';
    } else {
      editorPlugins += ' paste';
    }

    if (enableMentions) {
      editorPlugins += ' mentions';
    }

    if (enableInsertTable) {
      editorPlugins += ' table';
    }

    return {
      plugins: editorPlugins,
      content_style:
        '.mention {' +
        'display: inline-block;\n' +
        'background: #efefef;\n' +
        'border: 1px solid #3eb1c8;\n' +
        'border-radius: 10px;\n' +
        'padding: 2px 4px;' +
        '} ' +
        '.thumbnail {' +
        'max-width:200px;\n' +
        'max-height:200px;\n' +
        '}',
      selector: '#' + getSelectorId(id),
      placeholder: placeholder,
      autosubmit: false,
      entity_encoding: 'raw',
      paste_data_images: enableFileUpload,
      images_upload_handler: uploadHandler,
      image_dimensions: enableImageDimensions,
      automatic_uploads: true,
      paste_preprocess: function (plugin, args) {
        if (!args.content) {
          return;
        }

        if (args.source === 'image' || args.source === 'imagedrop') {
          if (insertImageAsThumbnail) {
            args.content = args.content.replaceAll('<img', '<img class="thumbnail"');
          }
        } else {
          args.content = args.content.replace(/<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g, '');
        }
      },
      mentions_selector: '.mention',
      mentions_fetch: enableMentions ? (query, success) => {
        if (props.mentionsFetch) {
          props.mentionsFetch(query.term, success);
        }
      } : undefined,
      mentions_menu_hover: enableMentions ? (mentionNode, callback) => {
        const div = document.createElement('div');
        div.innerHTML = `<div class="card"><b>${mentionNode.name}</b></div>`;
        callback(div);
      } : undefined,
      mentions_menu_complete: enableMentions ? handleMentionSelection : undefined,
      convert_urls: false,
      file_picker_types: 'image',
      min_height: includeLabel ? minHeight - 30 : minHeight,
      height: includeLabel ? height - 30 : height,
      max_height: includeLabel ? maxHeight - 30 : maxHeight,
      statusbar: false,
      codeview: {
        enabled: true,
        showButton: false,
      },
      formats: {
        borderstyle: {
          selector: 'td,th',
          styles: {
            borderTopStyle: 'solid',
            borderRightStyle: 'solid',
            borderBottomStyle: 'solid',
            borderLeftStyle: 'solid',
          },
          remove_similar: true,
        },
        bordercolor: {
          selector: 'td,th',
          styles: {
            borderTopColor: '#32CD32',
            borderRightColor: '#32CD32',
            borderBottomColor: '#32CD32',
            borderLeftColor: '#32CD32',
          },
          remove_similar: true,
        },
        backgroundcolor: {
          selector: 'td,th',
          styles: { backgroundColor: '#006400' },
          remove_similar: true,
        },
        formatpainter_removeformat: [
          {
            selector: 'b,strong,em,i,font,u,strike,sub,sup,dfn,code,samp,kbd,var,cite,mark,q,del,ins',
            remove: 'all',
            split: true,
            expand: false,
            block_expand: true,
            deep: true,
          },
          {
            selector: 'span',
            attributes: ['style', 'class'],
            remove: 'empty',
            split: true,
            expand: false,
            deep: true,
          },
          {
            selector: '*:not(tr,td,th,table)',
            attributes: ['style', 'class'],
            split: false,
            expand: false,
            deep: true,
          },
        ],
      },
      spelling: false,
      menubar: false,
      toolbar_drawer: 'sliding',
      toolbar: toolbar,
      css: {
        stylesheets: EDITOR_CUSTOM_STYLESHEET,
      },
      languages: EDITOR_LANGUAGES,
      setup: function (editor) {
        if (props.listeners) {
          Object.keys(props.listeners).forEach(function (key) {
            editor.on(key, function (event) {
              if (props.listeners[key]) {
                props.listeners[key].apply(me, [event]);
              }
            });
          });
        }
      },
    };
  };

  const handleMentionSelection = (editor, userinfo) => {
    if (!editor || !userinfo) return null;
    
    const span = editor.getDoc().createElement('span');
    span.className = 'mention';
    // store the user id in the mention so it can be identified later
    span.setAttribute('data-mentionid', userinfo.id);
    span.appendChild(editor.getDoc().createTextNode(userinfo.rawname || userinfo.name));

    return span;
  };

  const setHeight = (includeLabel, heightInPX) => {
    if (editorRef && editorRef.current) {
      editorRef.current.editorContainer.style.height = `${includeLabel ? heightInPX - 30 : heightInPX}px`;
    }
  };

  useEffect(() => {
    setHeight(includeLabel, height);
  }, [includeLabel, height]);

  return (
    <Box component="div" sx={{ ...props.style, height, width }}>
      {label && (
        <React.Fragment>
          <Box component="label" sx={classes.label}>
            {label}
          </Box>
          <br />
        </React.Fragment>
      )}
      <Editor
        {...props}
        init={getEditorConfig()}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={props.onChange}
      />
      <Box component="div" sx={classes.error}>
        {errorText}
      </Box>
    </Box>
  );
}

export default RTEditor;
