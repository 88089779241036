import intl from 'react-intl-universal';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { renderSelectField } from '../../utils/RenderUtil';
import { isShowingOnlyDefaultStep } from '../../utils/FilterUtil';
import { Box } from '@mui/material';
import { Board } from '@/models/BoardModel';

const useStyles = () => ({
  divider: {
    marginTop: '10px',
  },
  menuList: {
    outline: 'none',
  },
  resetFilter: {
    marginLeft: '24px',
    height: '32px',
  },
  field: {
    marginLeft: '16px',
    marginRight: '16px',
    paddingBottom: '3px',
    height: '48px',
  },
  filterContainer: { outline: 'none' },
});

const StepsFilter = ({
  title,
  resetTitle,
  viewConfig,
  board,
  stepIdsToShowOnBoard,
  onChange,
}: {
  title: string;
  resetTitle: string;
  viewConfig: any;
  board: any;
  stepIdsToShowOnBoard: any;
  onChange: any;
}) => {
  const classes: any = useStyles();
  const handleResetFilter = () => {
    onChange(viewConfig.setIn(['stepIdsToShowOnBoard'], [board.step_config.initial_step_id]));
  };

  const handleFilterChange = (event: any) => {
    onChange(viewConfig.setIn(['stepIdsToShowOnBoard'], event.target.value));
  };

  const listField = ({ board, viewConfig, style }: { board: Board, viewConfig: any, style: any }) => {
    return [
      renderSelectField({
        style,
        name: 'stepsToShowOnBoard',
        label: intl.get('docs.steps'),
        value: viewConfig.stepIdsToShowOnBoard,
        handleChange: handleFilterChange,
        options: board.step_config.steps,
        multiple: true,
      }),
    ];
  };

  return (
    <Box sx={classes.filterContainer}>
      <Divider sx={classes.divider} />
      <ListSubheader sx={classes.menuList}>
        {title}
        <Button
          sx={classes.resetFilter}
          size="small"
          variant="outlined"
          disabled={isShowingOnlyDefaultStep(board, stepIdsToShowOnBoard)}
          onClick={handleResetFilter}
        >
          {resetTitle}
        </Button>
      </ListSubheader>
      <MenuItem key={'steps'} sx={classes.field}>
        {listField({
          board,
          viewConfig,
          style: { width: '250px' },
        })}
      </MenuItem>
    </Box>
  );
};

export default StepsFilter;
