import { startOfDay } from 'date-fns';
import { CardType } from '@/models/card/CardModel';
import { getNoonOfDay } from './DateUtil';
import { flattenArray } from './FieldUtil';
import { getFilters } from './FilterUtil';
import Immutable from 'seamless-immutable';
import { cardSorter } from './SortUtil';

export const milestoneFields = {
  date_field: 'date_field',
  met_date_field: 'met_date_field',
};

export const milestoneStatuses = {
  FUTURE: 'FUTURE',
  ONTIME: 'ONTIME',
  OVERDUE: 'OVERDUE',
  NOTMET: 'NOTMET',
};

export const milestoneStatusMessages = {
  [milestoneStatuses.FUTURE]: 'board.tooltips.milestone.status.future',
  [milestoneStatuses.ONTIME]: 'board.tooltips.milestone.status.on_time',
  [milestoneStatuses.OVERDUE]: 'board.tooltips.milestone.status.overdue',
  [milestoneStatuses.NOTMET]: 'board.tooltips.milestone.status.not_met',
};

export const DISPLAY_ENTRY_TITLE = '_entryTitle';

export const calcMilestoneStatus = (date, metDate, dateNow) => {
  if (!date && !metDate) return milestoneStatuses.NOTMET;
  if (!date) return milestoneStatuses.OVERDUE;

  if (!metDate) {
    return startOfDay(date) >= startOfDay(dateNow) ? milestoneStatuses.FUTURE : milestoneStatuses.NOTMET;
  }

  return startOfDay(metDate) <= startOfDay(date) ? milestoneStatuses.ONTIME : milestoneStatuses.OVERDUE;
};

export const prepareMilestoneCardsForWeekBoard = (milestonesSets, milestonesConfigs, viewConfig) => {
  const milestonesAsObjects = milestonesSets.map((milestoneSet) => {
    const milestones = milestoneSet.milestones;
    const milestoneConfig = milestonesConfigs.find((milestoneConfig) => milestoneConfig.id === milestoneSet.id);
    if (!milestones || !milestoneConfig) return [];
    const filters = getFilters({ viewConfig, type: CardType.MILESTONE });

    return Immutable.asMutable(milestones)
      .filter((milestone) => {
        const filterResults = filters.map((filter) =>
          filter.filterFn({
            fieldId: filter.fieldId,
            filterValues: viewConfig.field_filters[CardType.MILESTONE][filter.fieldId],
            card: milestone,
          }),
        );
        return !filterResults.length || filterResults.indexOf(true) < 0;
      })
      .map((milestone) => {
        const dateField = milestone.fields.find((field) => field.id === milestoneConfig[milestoneFields.date_field]);
        const metDateField = milestone.fields.find(
          (field) => field.id === milestoneConfig[milestoneFields.met_date_field],
        );

        const dueDate = dateField && dateField.value ? getNoonOfDay(new Date(dateField.value)) : undefined;
        const metDate = metDateField && metDateField.value ? getNoonOfDay(new Date(metDateField.value)) : undefined;
        const now = Date.now();
        const status = calcMilestoneStatus(dueDate, metDate, now);
        return {
          ...milestone,
          dueDate,
          metDate: metDate,
          status,
          disciplines: [{ id: 'milestones' }],
          milestoneId: milestone.id,
          milestoneFolderId: milestoneConfig.id,
          isOverdue: !metDate && dueDate && dueDate < startOfDay(now),
          type: CardType.MILESTONE,
        };
      })
      .sort(cardSorter(viewConfig['milestoneTitle']));
  });
  return flattenArray(milestonesAsObjects);
};
