import { useState, useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Formik } from 'formik';
import { default as Editor } from '../../common/richtext';

import { getSelectFieldOptions, getInputProps } from '../../utils/FieldUtil';
import SubscriptionContainer from '../../common/SubscriptionContainer';
import { validateRequiredFields, validateEntryTitleField } from '../../utils/Validators';
import { renderField, renderSelectField, renderSwitchField } from '../../utils/RenderUtil';
import DatePicker from '../../common/DatePicker';
import StopClickPropagation from '../../common/StopClickPropagation';
import Value from '../../common/Value';
import SplitButton from '../../common/SplitButton';
import { Box, Theme, useTheme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  field: {
    width: 540,
  },
  textField: {
    width: 540,
    marginBottom: 15,
  },
  dateField: {
    width: 300,
  },
  listField: {
    width: 300,
  },
  listItem: {
    minHeight: 36,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    flexWrap: 'wrap',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  metaInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '70%',
  },
  column: {
    verticalAlign: 'bottom',
    marginLeft: '2%',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const Form = ({
  formRef,
  restrictions = {
    'task-done': () => ({}),
    'task-responsible': () => ({}),
    'rich-text': () => ({}),
    'auto-number': () => ({}),
    'unique-document-id': () => ({}),
    'sequence-number': () => ({}),
    member: () => ({}),
    list: () => ({}),
    date: () => ({}),
    string: () => ({}),
    numeric: () => ({}),
  },
  formValidation = {
    answered_date_field: () => ({}),
  },
  type,
  onDelete = () => {},
  onCancel = () => {},
  onFetchPossibleResponsible = () => {},
  onSubmit = () => {},
  item,
  possibleResponsible = [],
  requiredFields,
  initialValues,
  disabledFields,
  fieldConfig,
  deleteIcon,
  metaInfo = null,
  submitOptions = [],
}) => {
  const theme = useTheme();
  const classes: any = useStyles(theme);
   
  const [initializedEditors, setInitializedEditors] = useState({});
   
  const [closeOnSubmit, setCloseOnSubmit] = useState(false);
  const [editorStates, setEditorStates] = useState({});

  const handleFocus = useCallback((id) => {
    setEditorStates((prev) => ({ ...prev, [id]: true }));
  }, []);

  const validate = useCallback(
    (values) => {
      const errors = {};
      validateRequiredFields({ requiredFields, values, errors });
      validateEntryTitleField({ fieldConfig, values, errors });
      return errors;
    },
    [requiredFields, fieldConfig],
  );

  const getFieldDef = useCallback(({ fieldConfig, fieldId }: { fieldConfig: any, fieldId: string }) => {
    return fieldConfig.fields.filter((def: any) => def.id === fieldId)[0];
  }, []);

  const taskDone = useCallback(({ field, values, disabled, handleChange, handleBlur, getFieldError }) => {
    return [
      renderSwitchField({
        name: field.id,
        label: field.name,
        value: values[field.id],
        disabled,
        handleChange,
        handleBlur,
        error: getFieldError({ id: field.id }),
      }),
    ];
  }, []);

  const taskResponsible = useCallback(
    ({ field, values, disabled, handleChange, handleBlur, getFieldError }) => {
      return [
        renderSelectField({
          name: field.id,
          label: field.name,
          value: values[field.id] || '',
          disabled: possibleResponsible ? disabled || possibleResponsible.length === 0 : disabled,
          handleChange,
          handleBlur,
          error: getFieldError({ id: field.id }),
          options: [''].concat(possibleResponsible || []),
          className: classes.listField,
          itemStyle: classes.listItem,
        }),
      ];
    },
    [possibleResponsible, classes.listField, classes.listItem],
  );

  const listField = useCallback(
    ({ field, values, disabled, handleChange, handleBlur, getFieldError, setFieldValue }) => {
      return [
        renderSelectField({
          name: field.id,
          label: field.name,
          value: values[field.id] || '',
          disabled,
          handleChange: (e) => {
            if (onFetchPossibleResponsible && field.id === fieldConfig.to_member_field) {
              onFetchPossibleResponsible({
                disciplines: e.target.value,
              });
              setFieldValue('phentry:taskResponsible', []);
            }
            handleChange(e);
          },
          handleBlur,
          error: getFieldError({ id: field.id }),
          options: getSelectFieldOptions({
            field,
            fieldConfig,
            values,
          }),
          className: classes.listField,
          itemStyle: classes.listItem,
          multiple: getFieldDef({
            fieldConfig,
            fieldId: field.id,
          }).multiple,
        }),
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onFetchPossibleResponsible, fieldConfig, getFieldDef],
  );

  const dateField = useCallback(
    ({ field, values, disabled, getFieldError, setFieldValue, disablePast, disableFuture }) => {
      return [
        <DatePicker
          key={`field-${field.id}`}
          field={field}
          value={values[field.id] ? new Date(values[field.id]) : null}
          handleChange={setFieldValue}
          disabled={disabled}
          error={getFieldError({ id: field.id })}
          disablePast={disablePast}
          disableFuture={disableFuture}
          sx={classes.dateField}
        />,
      ];
    },
    [classes.dateField],
  );

  const richTextField = useCallback(
    ({ field, values, disabled, handleBlur, getFieldError, setFieldValue }) => {
      const id = field.id;
      return [
        <Editor
          id={id}
          label={field.name}
          includeLabel
          value={values[id]}
          errorText={getFieldError({ id: field.id })}
          style={{ marginTop: 0, marginBottom: 0 }}
          width={540}
          height={editorStates[id] ? 280 : 160}
          onChange={(event, editor) => {
            if (!Object.prototype.hasOwnProperty.call(initializedEditors, field.id)) {
              setEditorStates({ ...editorStates, [field.id]: true });
            } else {
              setFieldValue(id, editor.getContent());
            }
          }}
          disabled={disabled}
          onFocus={() => {
            handleFocus(id);
          }}
          key={`field-${id}-RTE`}
        />,
      ];
    },
    [handleFocus, editorStates, initializedEditors],
  );

  const defaultField = useCallback(
    ({ field, values, disabled, handleChange, handleBlur, getFieldError, touched, ...other }) => {
      return renderField({
        name: field.id,
        label: field.name,
        value: values[field.id],
        disabled: disabled || field.type === 'auto-number',
        handleChange,
        handleBlur,
        error: getFieldError({ id: field.id }),
        className: classes.textField,
        ...getInputProps({
          field,
          fieldConfig
        }),
        ...other,
      });
    },
    [classes.textField, fieldConfig],
  );

  const renderFieldMap = useMemo(() => ({
    'task-done': taskDone,
    'task-responsible': taskResponsible,
    'rich-text': richTextField,
    'auto-number': defaultField,
    'unique-document-id': defaultField,
    'sequence-number': defaultField,
    member: listField,
    list: listField,
    date: dateField,
    string: defaultField,
    numeric: defaultField,
  }), [taskDone, taskResponsible, richTextField, defaultField, listField, dateField]);

  const isFieldDisabled = useCallback(
    (disabledFields: any, values: any, fieldId: string) => {
      const key = Object.keys(formValidation).find((key) => fieldConfig[key] === fieldId);
      return key
        ? disabledFields.includes(fieldId) || !formValidation[key](fieldConfig, values)
        : disabledFields.includes(fieldId);
    },
    [fieldConfig, formValidation],
  );

  const buildFormItems = useCallback(
    ({ item, errors, values, disabledFields, handleChange, handleBlur, touched, setFieldValue }: { item: any, errors: any, values: any, disabledFields: any, handleChange: any, handleBlur: any, touched: any, setFieldValue: any }) => {
      let fields = item.fields || [];
      if (!item.id) {
        fields = fields.filter((field: any) => field.type !== 'task-done');
      }

      if ('QUESTION' === type) {
        const getFieldError = ({ id }: { id: string }) => errors[id];
        return (
          <Box key={`f-${item.id}`} sx={classes.container}>
            {fields.map((field) =>
              renderFieldMap[field.type]({
                field,
                values,
                disabled: isFieldDisabled(disabledFields, values, field.id),
                handleChange,
                handleBlur,
                getFieldError,
                touched,
                setFieldValue,
                ...restrictions[field.type](fieldConfig, field),
              }),
            )}
          </Box>
        );
      }
      return null;
    },
     
    [type, classes, restrictions, fieldConfig, isFieldDisabled, renderFieldMap],
  );

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit({
          values,
          item,
          closeOnSuccess: closeOnSubmit,
        });
      }}
      validate={validate}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty,
      }) => (
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={classes.header}>
                <Typography sx={classes.heading}>{intl.get('common.form.info')}</Typography>
                <StopClickPropagation>
                  {deleteIcon}
                  {item.id && <SubscriptionContainer id={item.id} />}
                </StopClickPropagation>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {buildFormItems({
                item,
                disabledFields,
                errors,
                values,
                handleChange,
                handleBlur,
                touched,
                setFieldValue,
              })}
            </AccordionDetails>
            <Divider />
            <AccordionActions
              style={{
                justifyContent: metaInfo ? 'space-between' : 'flex-end',
              }}
            >
              {metaInfo && (
                <Box sx={classes.metaInfo}>
                  <Box sx={classes.column}>
                    <Value caption={intl.get('card.owner')} value={metaInfo.owner} sx={{ caption: classes.title }} />
                  </Box>
                  <Box sx={classes.column}>
                    <Value
                      caption={intl.get('card.created.by')}
                      value={metaInfo.createdBy}
                      sx={{ caption: classes.title }}
                    />
                    <Value
                      caption={intl.get('card.created')}
                      value={metaInfo.creationDate}
                      sx={{ caption: classes.title }}
                    />
                  </Box>
                  <Box sx={classes.column}>
                    <Value
                      caption={intl.get('card.changed.by')}
                      value={metaInfo.modifiedBy}
                      sx={{ caption: classes.title }}
                    />
                    <Value
                      caption={intl.get('card.changed')}
                      value={metaInfo.modifiedDate}
                      sx={{ caption: classes.title }}
                    />
                  </Box>
                </Box>
              )}
              <Box sx={{ display: 'flex' }}>
                {!isSubmitting && (
                  <Button size="small" onClick={onCancel}>
                    {intl.get('common.form.back')}
                  </Button>
                )}
                <SplitButton
                  disabled={!isValid || !dirty || isSubmitting}
                  isLoading={isSubmitting}
                  options={submitOptions}
                />
              </Box>
            </AccordionActions>
          </Accordion>
      )}
    </Formik>
  );
};

export default Form;
