import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import CircularProgress from '@mui/material/CircularProgress';
import green from '@mui/material/colors/green';
import { Box, Theme, useTheme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  flexWrapper: {
    display: 'flex',
  },
  relativeWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  progress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-30px',
  },
  submitButton: {
    padding: '6px 10px',
  },
  arrowDropDownButton: {
    padding: '4px 8px',
  },
  menu: {
    paddingBottom: '4px',
    paddingTop: '4px',
  },
  menuItem: {
    paddingBottom: '3px',
    paddingTop: '3px',
  },
});

export default function SplitButton({
  options,
  disabled,
  isLoading,
  width,
}: {
  options: any;
  disabled: boolean;
  isLoading: boolean;
  width: number;
}) {
  const findDefaultOption = () => {
    const index = options.findIndex((option) => !!option.default);
    return index >= 0 ? index : 0;
  };

  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(findDefaultOption);

  const anchorRef = React.useRef(null);
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleMenuItemClick = (option) => {
    setSelected(option.index);
    setOpen(false);

    option.handleClick();
  };

  const handleClick = () => {
    setOpen(false);

    const selectedOption = options[selected];
    if (selectedOption) {
      selectedOption.handleClick();
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box component="div" sx={classes.flexWrapper}>
      <Box component="div" sx={classes.relativeWrapper}>
        <ButtonGroup variant="contained" color="primary" disabled={disabled} ref={anchorRef}>
          <Button
            onClick={handleClick}
            sx={classes.submitButton}
            style={{ width: width - 40 + 'px' }} // 40 is a select menu button width
            disabled={disabled}
          >
            {options[selected].title || ''}
          </Button>
          <Button
            color="primary"
            size="small"
            disabled={disabled}
            sx={classes.arrowDropDownButton}
            aria-controls={open ? 'button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        {isLoading && <CircularProgress size={21} sx={classes.progress} />}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement={'bottom-end'}
          style={{ zIndex: 1 }}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper style={{ width: width }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="button-menu" sx={classes.menu}>
                    {options
                      .map((option, index) =>
                        Object.defineProperty(option, 'index', {
                          value: index,
                        }),
                      )
                      .filter((option) => option.index !== selected)
                      .map((option) => (
                        <MenuItem key={option.index} sx={classes.menuItem} onClick={() => handleMenuItemClick(option)}>
                          {option.title}
                        </MenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
}
