import { compose } from 'recompose';

import { DropTarget } from 'react-dnd';
import green from '@mui/material/colors/green';

import {
  DEFAULT_CELL_WIDTH,
  HIGH_READABILITY_CELL_WIDTH,
  HIGH_READABILITY_MINIMUM_CELL_WIDTH,
  MILESTONE_CELL,
  MINIMUM_CELL_WIDTH,
} from '../../utils/Constants';
import CardList from '../../common/CardList';
import CardFolderOpen from '../../common/CardFolderOpen';
import CardFolderClosed from '../../common/CardFolderClosed';
import MilestoneCardItem from './MilestoneCardItem';
import { calculateCellWidth, getEntryTitle, QUESTION_TITLE } from '../../utils/FieldUtil';
import { Box } from '@mui/material';
import { CardType } from '@/models/card/CardModel';
import { Card } from '@/models/card/CardModel';

const useStyles = () => ({
  cell: {
    backgroundColor: '#e4e4e4',
    minHeight: '54px',
    borderRadius: '4px',
    height: '100%',
    paddingTop: '1px',
  },
  cellHighReadability: {
    backgroundColor: '#bdbdbd',
    minHeight: '70px',
    borderRadius: '4px',
    height: '100%',
    paddingTop: '1px',
  },
  cellIsOver: {
    backgroundColor: green[100],
  },
});

const cardTarget = {
  canDrop(props: any, monitor: any) {
    const { columnIndex, rowIndex } = props;
    return props.canDrop({
      card: monitor.getItem().card,
      sourceRowIndex: monitor.getItem().rowIndex,
      sourceColumnIndex: monitor.getItem().columnIndex,
      columnIndex,
      rowIndex,
    });
  },

  drop(props: any, monitor: any) {
    const item = monitor.getItem();
    const { rowIndex, columnIndex } = props;
    props.onUpdateCard({
      card: item.card,
      rowIndex,
      columnIndex,
      sourceRowIndex: monitor.getItem().rowIndex,
      sourceColumnIndex: monitor.getItem().columnIndex,
    });
  },
};

const collect = (connect: any, monitor: any) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

const getCardCount = (questions: any, deliveries: any) => questions.length + deliveries.length;

const Cell = (props: any) => {
  const {
    expanded,
    data,
    board,
    canDrop,
    isOver,
    connectDropTarget,
    onCardClick,
    viewConfig,
    type,
    rowIndex,
    columnIndex,
    onExpand,
    onCollapse,
  } = props;

  const classes = useStyles();

  const handleExpand = () => {
    onExpand({ key: `${rowIndex}-${columnIndex}` });
  };

  const handleCollapse = () => {
    onCollapse({ key: `${rowIndex}-${columnIndex}` });
  };

  const calculateQuestionCellWidth = ({ fields, viewConfig }) => {
    if (viewConfig.highReadability) {
      return calculateCellWidth({
        areCellsExpanded: viewConfig.expandedTileCells.length === 0,
        fields,
        minimumCellWidth: HIGH_READABILITY_MINIMUM_CELL_WIDTH,
        defaultWidth: HIGH_READABILITY_CELL_WIDTH,
        titleId: viewConfig[QUESTION_TITLE] || getEntryTitle(fields),
      });
    }

    return calculateCellWidth({
      areCellsExpanded: viewConfig.expandedTileCells.length === 0,
      fields,
      minimumCellWidth: MINIMUM_CELL_WIDTH,
      defaultWidth: DEFAULT_CELL_WIDTH,
      titleId: viewConfig[QUESTION_TITLE] || getEntryTitle(fields),
    });
  };

  const width =
    type === CardType.QUESTION
      ? calculateQuestionCellWidth({
          fields: board.field_config.fields,
          viewConfig,
        })
      : viewConfig.highReadability
        ? HIGH_READABILITY_CELL_WIDTH
        : DEFAULT_CELL_WIDTH;

  if (MILESTONE_CELL === type) {
    return connectDropTarget(
      <div style={{ paddingRight: '2px' }}>
        <Box
          component="div"
          sx={{
            ...(viewConfig.highReadability ? classes.cellHighReadability : classes.cell),
            ...(isOver && canDrop && classes.cellIsOver),
            width: width - 2,
          }}
        >
          <CardList
            board={board}
            viewConfig={viewConfig}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            items={data}
            CardComponent={MilestoneCardItem}
            onCardClick={onCardClick}
            type={type}
          />
        </Box>
      </div>,
    );
  }

  const questions = data.filter((item: Card) => item.type !== CardType.DELIVERY);
  const deliveries = data.filter((item: Card) => item.type === CardType.DELIVERY);
  const cardCount = getCardCount(questions, deliveries);

  return connectDropTarget(
    <div style={{ paddingRight: '2px' }}>
      <Box
        component="div"
        sx={{
          ...(viewConfig.highReadability ? classes.cellHighReadability : classes.cell),
          ...(isOver && canDrop && classes.cellIsOver),
          width: width - 2,
        }}
      >
        {(!expanded || cardCount === 0) && (
          <CardFolderClosed viewConfig={viewConfig} cardCount={cardCount} onExpand={handleExpand} />
        )}
        {expanded && cardCount > 0 && (
          <CardFolderOpen
            board={board}
            viewConfig={viewConfig}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            questions={questions}
            deliveries={deliveries}
            onCollapse={handleCollapse}
            onCardClick={onCardClick}
            type={type}
          />
        )}
      </Box>
    </div>,
  );
};

const ConnectedCell = compose(DropTarget('CARD', cardTarget, collect))(Cell);

export default ConnectedCell;
