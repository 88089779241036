import { compose } from 'recompose';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import green from '@mui/material/colors/green';
import IconButton from '@mui/material/IconButton';
import MailIcon from '@mui/icons-material/Note';
import Badge from '@mui/material/Badge';
import amber from '@mui/material/colors/amber';
import { DropTarget } from 'react-dnd';

import { canMoveToStep } from '../../utils/PermissionUtils';
import { LIST_BOARD_VIEW_MODE } from '../../utils/ViewModeUtil';
import CardFolderOpen from '../../common/CardFolderOpen';
import CardFolderClosed from '../../common/CardFolderClosed';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

const useStyles = () => ({
  step: {
    marginTop: '25px',
    width: '100%',
  },
  stepHeader: {
    backgroundColor: '#f3f3f3',
    height: '10px',
    borderBottom: '#dedede 1px solid',
  },
  stepBackground: {
    backgroundColor: '#e4e4e4',
  },
  stepIsOver: {
    backgroundColor: green[200],
  },
  iconButton: {
    paddingTop: '15px',
  },
  icon: {
    color: amber[500],
    width: '48px',
    height: '48px',
  },
  iconHighReadability: {
    color: amber[500],
    width: '64px',
    height: '64px',
  },
  iconBadge: {
    top: '0px',
    right: '-2px',
    transform: 'scale(1)',
  },
  iconBadgeHighReadability: {
    top: '0px',
    right: '-2px',
    transform: 'scale(1.5)',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  cardContentHighReadability: {
    backgroundColor: '#bdbdbd',
  },
});

const cardTarget = {
  canDrop(props: any, monitor: any) {
    return canMoveToStep({
      card: monitor.getItem().card,
      step: props.step,
    });
  },

  drop(props: any, monitor: any) {
    const item = monitor.getItem();
    props.onMoveCardToStep({
      card: item.card,
      step: props.step,
      board: props.board,
    });
  },
};

const collect = (connect: any, monitor: any) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

const Step = (props: any) => {
  const {
    cards,
    step,
    board,
    viewConfig,
    expanded,
    connectDropTarget,
    canDrop,
    isOver,
    onCardClick,
    onExpand,
    onCollapse,
  } = props;
  const navigate = useNavigate();
  const classes = useStyles();

  const handleExpand = () => {
    onExpand(step.id);
  };

  const handleCollapse = () => {
    onCollapse(step.id);
  };

  const handleDecisionLogStepClick = () => {
    navigate(`/${board.id}?view=${LIST_BOARD_VIEW_MODE}&step=${board.step_config.decision_log_step_id}`);
  };

  let children;

  if (step.id === board.step_config.decision_log_step_id) {
    children = (
      <CardContent
        sx={{
          ...(viewConfig.highReadability && classes.cardContentHighReadability),
          ...classes.cardContent,
        }}
      >
        <IconButton id={'decision-log-step'} onClick={handleDecisionLogStepClick} sx={classes.iconButton}>
          <Badge
            sx={{
              ...(viewConfig.highReadability && classes.iconBadgeHighReadability),
              ...classes.iconBadge,
            }}
            badgeContent={`${cards.length}`}
            color="primary"
          >
            <MailIcon
              sx={{
                ...(viewConfig.highReadability ? classes.iconHighReadability : classes.icon),
                marginRight: '-5px',
                marginTop: '-10px',
              }}
            />
          </Badge>
        </IconButton>
      </CardContent>
    );
  } else {
    children = (
      <CardContent sx={viewConfig.highReadability ? classes.cardContentHighReadability : null}>
        {!expanded && <CardFolderClosed viewConfig={viewConfig} cardCount={cards.length} onExpand={handleExpand} />}
        {expanded && (
          <CardFolderOpen
            board={board}
            viewConfig={viewConfig}
            questions={cards}
            onCollapse={handleCollapse}
            onCardClick={onCardClick}
          />
        )}
      </CardContent>
    );
  }

  return connectDropTarget(
    <div>
      <Box
        sx={{
          ...classes.step,
          ...(isOver && canDrop && classes.stepIsOver),
        }}
      >
        <Card
          key={`step-${step.id}`}
          sx={{
            ...classes.stepBackground,
            ...(isOver && canDrop && classes.stepIsOver),
          }}
        >
          <CardHeader
            sx={classes.stepHeader}
            title={<Typography variant="body2">{step.name}</Typography>}
            action={step.final && <LockIcon sx={{ marginTop: '-10px' }} />}
          />
          {children}
        </Card>
      </Box>
    </div>,
  );
};

const ConnectedStep = compose(DropTarget('CARD', cardTarget, collect))(Step);

export default ConnectedStep;
