import intl from 'react-intl-universal';
import {
  addDays,
  addHours,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfDay,
  endOfISOWeek,
  format,
  getDay,
  getISOWeek,
  isWeekend,
  isWithinInterval,
  startOfDay,
  startOfISOWeek,
  getISOWeekYear,
  differenceInDays,
} from 'date-fns';
import { sortBy, union, unionBy } from 'lodash';
import { WEEK_BOARD_VIEW_MODE } from './ViewModeUtil';
import { getLocale } from './LocaleUtil';
import {
  ATTACHMENT_COLUMN,
  CHANGED_BY_COLUMN,
  CHANGED_COLUMN,
  COMMENT_COLUMN,
  CONCLUDED_COLUMN,
  CREATED_BY_COLUMN,
  CREATED_COLUMN,
  OWNER_COLUMN,
  STEP_COLUMN,
  MARGIN_COLUMN,
} from './ListViewUtil';
import { getNoonOfDay } from './DateUtil';
import { DateFormatter, DateTimeFormatter } from '../common/DateFormatter';
import { isEmptyContent } from '../common/richtext';
import Immutable from 'seamless-immutable';
import { DISPLAY_ENTRY_TITLE, milestoneStatusMessages } from './MilestoneUtil';
import { isGroup } from './GroupUtil';
import { Card, CardType, Field } from '@/models/card/CardModel';
import { FieldConfig } from 'formik';

export const QUESTION_TITLE = 'questionTitle';
export const MILESTONE_TITLE = 'questionTitle';

export const MONDAY = 1;

export const weekBoardDefaultColumnIds = {
  NO_DUE_DATE: 'No due date',
  OVERDUE: 'Overdue',
};

export const deliveryStatuses = {
  DELIVERED: 'DELIVERED',
  NOT_DELIVERED: 'NOT_DELIVERED',
};

export const deliveryStatusMessages = {
  [deliveryStatuses.DELIVERED]: 'board.deliveries.statuses.delivered',
  [deliveryStatuses.NOT_DELIVERED]: 'board.deliveries.statuses.not_delivered',
};

// DARWIN-20428: ids for disabling fields when Automatic entry Id is enabled in Adv workflow folder
export const UNIQUE_DOC_ID = 'phentry:uniquedocid';
export const MANUAL_SEQUENCE_ID = 'phentry:manualsequence';

const automaticEntryIdFields = [UNIQUE_DOC_ID, MANUAL_SEQUENCE_ID];

/**
 * Returns the initial values of a card which are passed to a Form.
 * @param  {object} item  Item(Card, Milestone) being shown
 * @param  {array} fields Array of all fields from config
 * @return {object}       Object with field id as key and it's value.
 */
export const populateInitialValues = ({ item, fields }) => {
  const initialValues = {};
  // Populate initial values on form
  item.fields.forEach((f) => {
    const def = fields.find((def) => def.id === f.id);
    let val = f.value;
    if (Array.isArray(val)) {
      val = val.map((v) => (v.id ? v.id : v));
      if (!def.multiple) {
        val = val[0];
      }
    }
    if (f.type === 'numeric') {
      val = (val / 100).toFixed(2); // Last two digits are decimals
    }
    initialValues[f.id] = val;
  });

  return initialValues;
};

/**
 * Returns the required fields of a card based on the workflow configuration.
 * @param  {object} item  Item which are beeing shown
 * @param  {array} fields Array of all fields from config
 * @return {array}        Array of fields which are required to have values.
 */
export const getRequiredFields = ({ item, fields }) => {
  return item.fields
    .filter((f) => {
      const def = fields.find((def) => def.id === f.id);
      // DARWIN-20428: As we are disabling the uniquedocid field, we need to remove it from required fields
      if (def.id === UNIQUE_DOC_ID && def.mandatory) {
        return false;
      }
      return def.mandatory;
    })
    .map((f) => f.id);
};

/**
 * Extract field ids that are not allowed to be changed
 */
export const calculateDisabledFieldsForCard = ({ permissions, fieldConfig }) => {
  const disabledFieldsByPermissions = calculateDisabledByPermissionsFieldsForCard(permissions, fieldConfig);
  const readOnlyFields = calculateReadOnlyFields(fieldConfig);

  return union(disabledFieldsByPermissions, readOnlyFields, automaticEntryIdFields);
};

/**
 * Extract field ids that are read only
 */
export const calculateReadOnlyFields = (fieldConfig) =>
  fieldConfig.fields.filter((field) => field.read_only).map((field) => field.id);

/**
 * Extract field ids that are not allowed to be changed by permissions
 */
export const calculateDisabledByPermissionsFieldsForCard = (permissions, fieldConfig) => {
  if (!permissions || !fieldConfig) {
    throw new Error('Permissions not found');
  }

  return fieldConfig.fields
    .filter((field) => {
      if (
        // User can edit all fields, if he belongs to the from and to groups
        permissions.FULL_CONTROL ||
        (permissions.ASK && permissions.ANSWER)
      ) {
        return false;
      }
      // Questioner can edit all the fields except answer and answered date
      if (permissions.ASK) {
        return field.id === fieldConfig.answer_field || field.id === fieldConfig.answered_date_field;
      }
      // Answerer can edit answer and answered date fields
      if (permissions.ANSWER) {
        return !(
          field.id === fieldConfig.answer_field ||
          field.id === fieldConfig.answered_date_field ||
          field.id === 'phentry:taskDone'
        );
      }

      return true;
    })
    .map((field) => field.id);
};

// Extract milestone field IDs that cannot be changed due to permissions.
export const calculateDisabledFieldsForMilestone = ({ permissions, fields }) => {
  if (!permissions || !fields) {
    throw new Error('Milestone permissions or milestone fields not found');
  }

  // If the user has the UPDATE permission, enable all fields. Otherwise, disable them.
  return union(!permissions.UPDATE ? fields.map((field) => field.id) : [], automaticEntryIdFields);
};

// Extract delivery field IDs that cannot be changed due to permissions.
export const calculateDisabledFieldsForDelivery = ({ permissions, fields }) => {
  if (!permissions || !fields) {
    throw new Error('Delivery permissions or delivery fields not found');
  }

  return union(permissions.UPDATE ? [] : fields.map((field) => field.id), automaticEntryIdFields);
};

export const unassignedGroupId = '';
export const getUnassignedColumn = () => ({
  id: unassignedGroupId,
  name: intl.get('board.views.tile.columns.unassigned'),
});

export const isUnassignedColumn = ({ column }) => column.id === unassignedGroupId || column.id === '';

export const setMemberFieldValue = ({ value, field, fieldsConfig }) => {
  const potentialMembers = fieldsConfig.find((f) => f.id === field.id).limited_to;

  if (Array.isArray(value)) {
    return field.set(
      'value',
      value.map((v) => potentialMembers.find((m) => m.id === v)),
    );
  }

  const potentialMember = potentialMembers.find((m) => m.id === value);

  return field.set('value', potentialMember ? [potentialMember] : []);
};

export const setTaskResponsibleFieldValue = ({ value, field }) => {
  if (Array.isArray(value)) {
    return field.set('value', value);
  }

  if (value === '' || value === undefined) {
    return field.set('value', []);
  }

  return field.set('value', [{ id: value, type: isGroup(value) ? 'group' : 'user' }]);
};

export const setListFieldValue = ({ value, field }) => {
  if (value) {
    return field.set('value', Array.isArray(value) ? value : [value]);
  }

  return field.set('value', []);
};

export const setNumericFieldValue = ({ value, field }) => {
  return field.set('value', value * 100); // Last two digits are expected to be decimals
};

const setFieldValueMap = {
  member: setMemberFieldValue,
  'task-responsible': setTaskResponsibleFieldValue,
  list: setListFieldValue,
  numeric: setNumericFieldValue,
};

/**
 * Set's the fields value based on the values provided by form
 *
 * Special case for member fields:
 * Need to send the whole member object, and not just the ID. Therefore we need
 * to lookup the member objects and retrieve the correct ones based on the
 * selected values in the form.
 *
 * @param {object} values     Form values
 * @param {object} field       Current field
 * @param {object} fieldsConfig Configuration of fields in the folder in which the card is created
 */
const setFieldValue = ({ values, field, fieldsConfig }) => {
  const value = values[field.id];
  const setter = setFieldValueMap[field.type];

  return setter ? setter({ value, field, fieldsConfig }) : field.set('value', value);
};

/**
 * Returns an array of fields which have been updated. The fields will hold the updated value.
 * @param  {object} values        Form values
 * @param  {object} item          The card (question / milestone) which was created or updated
 * @param  {object} fieldsConfig  Configuration of fields in the folder in which the card is created
 * @return {array}                The updated fields with values
 */
export const getUpdatedFieldsAndValues = ({ values, item, fieldsConfig }) => {
  const updatedFields = item.fields.filter((field) => {
    if (Array.isArray(field.value)) {
      return true; // Don't ask
    }
    return values[field.id] !== field.value;
  });
  return updatedFields.map((field) => setFieldValue({ values, field, fieldsConfig }));
};

/**
 * Format custom field value to primitive values
 */
export const toPrimitiveFieldValue = (field) => {
  if (field.type === 'task-done') {
    return field.value ? intl.get('common.yes') : intl.get('common.no');
  }
  if (!field.value) {
    return '';
  }
  if (field.type === 'list') {
    return field.value.join(', ');
  }
  if (field.type === 'member' || field.type === 'task-responsible') {
    return field.value.map((authority) => authority.name).join(', ');
  }
  if (field.type === 'date') {
    return DateFormatter({ value: field.value });
  }
  return field.value;
};

export const getBoardFields = ({ board, card }) => {
  const type = board.type;
  if (type === CardType.QUESTION || type === CardType.DELIVERY) {
    return {
      from: getField({
        board,
        card,
        field: board.field_config.from_member_field,
      }),
      to: getField({
        board,
        card,
        field: board.field_config.to_member_field,
      }),
      question: getField({
        board,
        card,
        field: board.field_config.question_field,
      }),
      answer: getField({
        board,
        card,
        field: board.field_config.answer_field,
      }),
      due: getField({
        board,
        card,
        field: board.field_config.answer_due_field,
      }),
    };
  }
  // other types
  return undefined;
};

export const getField = ({ card, field }: { card: Card; field: string }) => card.fields.find((f) => f.id === field);

const containsField = (fieldList, targetField) => {
  return fieldList.some((field) => field.name === targetField.name && field.type === targetField.type);
};

const getAvailableValuesInFields = ({ eligibleFields, setsToCheck }) => {
  return eligibleFields.map((field) => {
    // Fields which are not list or members returned as is
    if (field.type !== 'member' && field.type !== 'list') {
      return field;
    }
    // Need to return all values from the different identical fields combined.
    // There might be a case for instance when a identical member field in another milestone folder contains
    // one more option to be selected.
    let combinedValues = field.limited_to || field.options;
    setsToCheck.forEach((milestoneSet) => {
      const milestoneSetField = milestoneSet.fields.find((f) => f.name === field.name && f.type === field.type);
      if (milestoneSetField) {
        const fieldOptions = milestoneSetField.limited_to || milestoneSetField.options;
        fieldOptions.forEach((value) => {
          if (!combinedValues.find((v) => (v.id !== undefined && v.id === value.id) || v === value)) {
            combinedValues = combinedValues.concat(value);
          }
        });
      }
    });
    return field.set(field.type === 'member' ? 'limited_to' : 'options', combinedValues);
  });
};

export const getIdenticalMilestoneFields = ({ board }) => {
  if (board.milestones_config.length <= 0) {
    // The milestone has no fields. Only display the default value (added below).
    return [];
  }

  if (board.milestones_config.length === 1) {
    // There is only one milestone set. Display all of its fields (provided they are the right type), in addition to the
    // default value.
    return board.milestones_config[0].fields;
  }

  // There are several milestone sets. Loop through the first milestone set's fields, and include any field that is
  // present in all the other milestone sets. The field must have both the same name and the same type.
  const fields = board.milestones_config[0].fields;

  // We need to check all sets except the first.
  const setsToCheck = board.milestones_config.slice(1);
  const eligibleFields = fields.filter((field) => {
    return setsToCheck.every((milestoneSet) => containsField(milestoneSet.fields, field));
  });

  return getAvailableValuesInFields({ eligibleFields, setsToCheck });
};

export const getCardDate = (data, board, locale) => {
  if (data.date) {
    return format(data.date, 'dd MMM', {
      locale: getLocale(locale),
    }).toLowerCase();
  } else {
    const dueDate = getFieldValueFromCard(data, board, 'answer_due_field');
    return dueDate
      ? format(new Date(dueDate), 'dd MMM', {
          locale: getLocale(locale),
        }).toLowerCase()
      : '';
  }
};

export const getFromAndToFields = ({ board, card, values }) => {
  const fromField = board.field_config.from_member_field;
  const toField = board.field_config.to_member_field;
  const newValues = {
    [fromField]: values.from,
    [toField]: values.to,
  };
  if (hasAnyMemberFieldChanged({ fields: [fromField, toField], card, newValues })) {
    return [
      {
        id: fromField,
        type: 'member',
        value: [values.from],
      },
      {
        id: toField,
        type: 'member',
        value: isUnassignedColumn({ column: values.to }) ? [] : values.to,
      },
    ];
  }
  return undefined;
};

export const calculateToValue = ({ board, card, oldValue, newValue }) => {
  if (!newValue.id) {
    return [];
  }

  const toField = getFieldFromCard(card, board, 'to_member_field');
  const toFieldConfig = getFieldFromConfig(board, 'to_member_field');

  if (toFieldConfig.multiple) {
    const values = toField.value.filter(
      (field) => field.id !== (oldValue ? oldValue.id : '') && field.id !== newValue.id,
    );

    return union(values, [newValue]);
  }

  return [newValue];
};

export const shouldToValuesBeUpdated = ({ board, card, stepId, values }) => {
  const initialStepId = board.step_config.initial_step_id;
  const toFieldConfig = getFieldFromConfig(board, 'to_member_field');
  const toField = getFieldFromCard(card, board, 'to_member_field');

  return !(toFieldConfig.multiple && stepId === initialStepId && toField.value !== values.to);
};

export const hasResponsibleField = ({ fieldConfig }) =>
  fieldConfig?.fields?.find((field) => field.type === 'task-responsible');

export const hasAnyMemberFieldChanged = ({ fields, card, newValues }) => {
  return (
    fields.filter((field) => {
      const cardField = card.fields.find((f) => f.id === field);
      if (cardField.value.length === 0 && newValues[field]) {
        return true;
      }
      return cardField.value[0].id !== newValues[field].id;
    }).length > 0
  );
};

export const getInputProps = ({ field, fieldConfig }: { field: Field; fieldConfig: FieldConfig }) => {
  let inputProps;
  if ('string' === field.type) {
    if (field.id === fieldConfig.answer_field || field.id === fieldConfig.question_field) {
      inputProps = {
        type: 'text',
      };
    }
  } else if ('numeric' === field.type) {
    inputProps = {
      type: 'number',
    };
  } else if ('date' === field.type) {
    inputProps = {
      type: 'date',
    };
  }
  return inputProps;
};

const createColumn = ({ id, name, weekNumber, year, weekend, dayNumber, dayColumn, date }: { id: string, name: string, weekNumber: number, year: number, weekend: boolean, dayNumber: number, dayColumn: boolean, date: Date }) => ({
  id: id + '',
  name: name + '',
  weekNumber,
  year,
  weekend,
  dayNumber,
  dayColumn,
  date,
});

// javascript consider Sunday as the first day of the week
// (0 corresponds to Sunday, 1 - to Monday and so on).
// as we need week to start from Monday the we need to modify indexes
// the function below does this transformation.
export const reorderedWeekDayNumber = (weekDayNumber) => (weekDayNumber + 6) % 7;

const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const DEFAULT_WEEKS_IN_VIEW = 10;

function getRows(field_config, viewConfig) {
  // prettier-ignore
  const xDisciplines = field_config.fields
    .find(f => f.id === field_config.to_member_field)
    .limited_to
    .filter(f => viewConfig && viewConfig.to ? viewConfig.to.find(group => group.id === f.id) : true);

  return [{ id: 'milestones' }].concat(xDisciplines);
}

function expandWeekToDays(week, start, end) {
  const columns = [];
  const weekNumber = getISOWeek(week);
  const startWeek = getNoonOfDay(startOfISOWeek(week));
  const year = getISOWeekYear(week, { weekStartsOn: MONDAY });
  start = startWeek <= start ? start : startWeek;
  eachDayOfInterval({
    start: start,
    end: end || getNoonOfDay(endOfISOWeek(week)),
  }).forEach((day) => {
    const weekDayNumber = reorderedWeekDayNumber(getDay(day));
    columns.push(
      createColumn({
        id: `${weekNumber}-${weekDays[weekDayNumber]}`,
        name: `${weekNumber}-(${weekDays[weekDayNumber]})`,
        dayColumn: true,
        weekNumber: weekNumber,
        dayNumber: weekDayNumber,
        weekend: isWeekend(day),
        date: addHours(day, 12),
        year,
      }),
    );
  });
  return columns;
}

// creates cell or array of cells if week is expanded
function getWeek(week, isExpanded, dateFrom, dateTo) {
  const weekNumber = getISOWeek(week);
  const year = getISOWeekYear(week, { weekStartsOn: MONDAY });
  const dateFromWeek = getISOWeek(dateFrom);
  const dateToWeek = getISOWeek(dateTo);
  if (!isExpanded) {
    return createColumn({
      id: `${weekNumber}:${year}`,
      year,
      name: `${weekNumber}`,
      weekNumber: weekNumber,
      date: getNoonOfDay(addDays(endOfISOWeek(week), -2)),
    });
  }
  return expandWeekToDays(
    week,
    dateFromWeek === weekNumber ? dateFrom : undefined,
    dateToWeek === weekNumber ? dateTo : undefined,
  );
}

function getColumns(expandedColumns, viewConfig) {
  // default columns for week view
  let columns = [
    createColumn({ id: weekBoardDefaultColumnIds.NO_DUE_DATE }),
    createColumn({ id: weekBoardDefaultColumnIds.OVERDUE }),
  ];

  eachWeekOfInterval(
    {
      start: viewConfig.dateFrom,
      end: viewConfig.dateTo,
    },
    { weekStartsOn: MONDAY },
  ).forEach((woi) => {
    const weekNumber = getISOWeek(woi);
    const year = getISOWeekYear(woi, { weekStartsOn: MONDAY });
    const isExpanded = expandedColumns.indexOf(`${weekNumber}:${year}`) >= 0;
    const weekCells = getWeek(woi, isExpanded, viewConfig.dateFrom, viewConfig.dateTo);
    columns = columns.concat(weekCells);
  });
  return columns;
}

function getWeekModeConfig(field_config, viewConfig, expandedColumns) {
  const rows = getRows(field_config, viewConfig);
  const columns = getColumns(expandedColumns, viewConfig);
  return { xColumns: rows, yColumns: columns };
}

function getMonthModeConfig(field_config, viewConfig) {
  // prettier-ignore
  const xDisciplines = field_config.fields
    .find(f => f.id === field_config.from_member_field)
    .limited_to
    .filter(f => viewConfig && viewConfig.from ? viewConfig.from.find(group => group.id === f.id) : true);

  // prettier-ignore
  const yDisciplines = field_config.fields
    .find(f => f.id === field_config.to_member_field)
    .limited_to
    .filter(f => viewConfig && viewConfig.to ? viewConfig.to.find(group => group.id === f.id) : true);
  const yColumns = [getUnassignedColumn()].concat(yDisciplines);

  return { xColumns: xDisciplines, yColumns };
}

export const getBoardConfig = (field_config, type, viewConfig, expandedColumns) =>
  type === WEEK_BOARD_VIEW_MODE
    ? getWeekModeConfig(field_config, viewConfig, expandedColumns)
    : getMonthModeConfig(field_config, viewConfig);

/**
 * Add already selected groups to allowed authorities to be shown in UI as selected value/values
 * @param allowedAuthorities [{ id: "GROUP_1" }, { id: "GROUP_2" }]
 * @param selectedAuthorities [{ id: "GROUP_1" }, { id: "GROUP_3" }]
 * @returns [{ id: "GROUP_1" }, { id: "GROUP_2" }, { id: "GROUP_3" }, ]
 */
const buildAuthorityOptions = ({ allowedAuthorities, selectedAuthorities }) =>
  sortBy(unionBy(selectedAuthorities, allowedAuthorities, 'id'), 'name').map(({ id, name }) => ({
    id,
    label: name,
  }));

/**
 * @param selectFieldValue "" or "GROUP_.." or [] or ["GROUP_..", "GROUP_.."]
 * @param allAuthorities [{ id: "GROUP_...", name: "My Group" }, ...]
 * @return [] or [{ id: "GROUP_...", name: "My Group" }] or [{ id: "GROUP_...", name: "My Group" }, ...]
 */
const toAuthorities = ({ selectFieldValue, allAuthorities }) => {
  // Transform multiple select value
  if (Array.isArray(selectFieldValue)) {
    return selectFieldValue.length === 0
      ? []
      : allAuthorities.filter((authority) => selectFieldValue.includes(authority.id));
  }
  // Transform single select value
  const selectedAuthority = allAuthorities.find((authority) => authority.id === selectFieldValue);
  return selectedAuthority ? [selectedAuthority] : [];
};

const extractAllowedAuthorities = ({ fieldId, fieldConfig, limitedTo }) => {
  if (fieldId === fieldConfig.from_member_field) {
    return fieldConfig.allowed_from_authorities;
  }
  if (fieldId === fieldConfig.to_member_field) {
    return fieldConfig.allowed_to_authorities;
  }
  if (fieldId === fieldConfig.delivery_from_field) {
    return fieldConfig.allowed_from_authorities;
  }
  return limitedTo;
};

export const getSelectFieldOptions = ({ field, fieldConfig, values }) => {
  const fieldDefinition = fieldConfig.fields.find((configs) => configs.id === field.id);

  if (field.type === 'member') {
    const limitedTo = fieldDefinition.limited_to;
    const selectedAuthorities = toAuthorities({
      selectFieldValue: values[field.id],
      allAuthorities: limitedTo,
    });
    const deselectOption = {
      id: unassignedGroupId,
      name: '',
    };

    return buildAuthorityOptions({
      allowedAuthorities: extractAllowedAuthorities({
        fieldId: field.id,
        fieldConfig,
        limitedTo,
      }),
      // Add deselect option to single member select component
      selectedAuthorities: fieldDefinition.multiple
        ? selectedAuthorities
        : [deselectOption].concat(selectedAuthorities),
    });
  }
  return fieldDefinition.multiple ? fieldDefinition.options : [''].concat(fieldDefinition.options);
};

export const getRichTextFields = (fields) =>
  fields.filter((field) => field.type === 'rich-text').map((field) => field.id);

export const getNumberFields = (fields) => fields.filter((field) => field.type === 'numeric').map((field) => field.id);

export const getFieldFromCard = (card, board, fieldName) => {
  const field = card.fields.find((field) => field.id === board.field_config[fieldName]);

  return field || {};
};

export const getFieldFromConfig = (board, fieldName) => {
  const field = board.field_config.fields.find((field) => field.id === board.field_config[fieldName]);

  return field || {};
};

// Look up the definition of the field with the given fieldName in the field definition of the given board. Find the
// corresponding field in the given card, and return its value - or an empty string, if the field was not found.
export const getFieldValueFromCard = (card, board, fieldName) => {
  const field = getFieldFromCard(card, board, fieldName);

  return getValueFromField(field);
};

export const getValueFromField = (field) => {
  let value = field.value || '';

  if (field.type === 'member' && Array.isArray(value)) {
    value = value.map((item) => item.name);
    value = value.join(', ');
  }

  if (field.type === 'list' && Array.isArray(value)) {
    value = value.join(', ');
  }

  return value;
};

export const getTypeFromField = (field) => (field ? field.type || '' : '');

// From the given card, return the answer field, as configured in the given board. Return an empty object
// if no answer was found.
export const getAnswer = (card, board) => {
  return getFieldValueFromCard(card, board, 'answer_field');
};

// From the given card, return the value of the due date field, as configured in the given board. Return an empty string
// if no due date was found.
export const getDueDate = (card, board) => {
  const dueDate = getFieldValueFromCard(card, board, 'answer_due_field');
  return formatDateToLocalizedString(dueDate);
};

// Remove extra spaces from the given user's name property. If the name is empty, use the e-mail address instead.
export const verifyName = (user) => {
  // Some names are stored with extra spaces. Get rid of those.
  if (user.name) {
    user.name = user.name.trim();
  }
  // If there's nothing left, use the ID as the name. The ID contains the user's e-mail address.
  if (!user.name) {
    user.name = user.id;
  }
};

export const formatDateToLocalizedString = (date) => {
  const locale = intl.getInitOptions().currentLocale;
  return date
    ? format(new Date(date), 'eee, dd MMM yyyy', {
        locale: getLocale(locale),
      })
    : '';
};

export const getFieldValue = (field) => {
  let value = toPrimitiveFieldValue(field);

  if (field.type === 'date') {
    value = formatDateToLocalizedString(field.value);
  }

  return value;
};

export const getFieldValuesToDisplayInTooltip = (data) =>
  Immutable(
    data.fields.map((field) => {
      const type = getTypeFromField(field);
      let value = getFieldValue(field);

      if (type === 'rich-text') {
        // to limit size of RTF in tooltip
        value = !isEmptyContent(value)
          ? `<div style="width: 300px; max-height: 300px; overflow: auto;">${value}</div>`
          : '';
      }

      if (type === 'numeric') {
        value = (value / 100).toFixed(2); // Last two digits are decimals
      }

      return {
        key: field.name,
        type: type,
        value: value,
      };
    }),
  ).asMutable();

export const getQuestionFieldValuesToDisplayInTooltip = (data) => {
  const values = getFieldValuesToDisplayInTooltip(data);

  values.push({
    key: intl.get('board.tooltips.step'),
    value: data.step.name,
  });

  return values;
};

export const geMilestoneFieldValuesToDisplayInTooltip = (data) => {
  const values = getFieldValuesToDisplayInTooltip(data);

  values.push({
    key: intl.get('board.tooltips.status'),
    value: intl.get(milestoneStatusMessages[data.status] || 'common.toast.error'),
  });

  return values;
};

export const getDeliveryFieldValuesToDisplayInTooltip = (data) => {
  const values = getFieldValuesToDisplayInTooltip(data);
  const deliveryMetDateField = data.metDate;

  values.push({
    key: intl.get('board.tooltips.status'),
    value: deliveryMetDateField
      ? intl.get('board.deliveries.statuses.delivered')
      : intl.get('board.deliveries.statuses.not_delivered'),
  });

  return values;
};

export const getDefaultFieldValue = (field) => {
  let value = null;
  if (field.type === 'list' || field.type === 'member') {
    value = field.multiple ? [] : '';
  } else if (field.type === 'number') {
    value = 0;
  }

  return value;
};

export const getInitialFieldValue = (field) => {
  const value = field.type === 'member' ? field.initial_value.id : field.initial_value;

  return field.multiple ? [value] : value;
};

// Return the ID of the (first) entry title field found in the given list of fields.
export const getEntryTitle = (fieldList) => {
  return fieldList.find((field) => field.entry_title).id;
};

export const getCellComponentRenderer = (fields, roomMembers) => {
  const richTextFields = getRichTextFields(fields);
  const numberFields = getNumberFields(fields);

  return (cell) => {
    let value;

    switch (cell.column.name) {
      case COMMENT_COLUMN:
        return cell.row.comment_count ? <div style={{ display: 'flex' }}>{cell.row.comment_count}</div> : <span />;
      case ATTACHMENT_COLUMN:
        return cell.row.child_count ? <div style={{ display: 'flex' }}>{cell.row.child_count}</div> : <span />;
      case CREATED_COLUMN:
      case CHANGED_COLUMN:
        return DateTimeFormatter({ value: cell.row[cell.column.name] });
      case OWNER_COLUMN:
      case CREATED_BY_COLUMN:
      case CHANGED_BY_COLUMN:
        return getMemberNameById({
          id: cell.row[cell.column.name],
          members: roomMembers,
        });
      case CONCLUDED_COLUMN:
        return cell.row[CONCLUDED_COLUMN] ? DateTimeFormatter({ value: cell.row[CONCLUDED_COLUMN] }) : <span />;
      case MARGIN_COLUMN: {
        const margin = cell.row[cell.column.name];
        const marginColor = margin < 0 ? 'red' : 'green';

        return <span style={{ color: marginColor }}>{margin}</span>;
      }
      default:
        value = cell.row[cell.column.name] || '';
        if (richTextFields.indexOf(cell.column.name) !== -1) {
          return <div dangerouslySetInnerHTML={{ __html: value }} />;
        }
        if (numberFields.indexOf(cell.column.name) !== -1) {
          return value / 100;
        }
        return value;
    }
  };
};

export const itemToRow = ({ item, decision_log_step_id, fieldConfig }) => {
  const row = {};

  item.fields.forEach((field) => {
    row[field.id] = toPrimitiveFieldValue(field);
  });

  row.permissions = item.permissions;
  row.child_count = item.child_count;
  row.comment_count = item.comment_count;
  row.locked = item.locked;
  row.name = item.name;
  row.id = item.id;
  row.fields = item.fields;

  row.owner = item.owner;
  row.creationDate = item.creation_date;
  row.createdBy = item.created_by;
  row.modifiedDate = item.modified_date;
  row.modifiedBy = item.modified_by;

  if (fieldConfig.margin_tracking_enabled && row[fieldConfig.answer_due_field]) {
    const answerDate = row[fieldConfig.answered_date_field]
      ? new Date(row.fields.filter((field) => field.id === fieldConfig.answered_date_field)[0].value)
      : new Date();
    const answerDue = new Date(row.fields.filter((field) => field.id === fieldConfig.answer_due_field)[0].value);
    row[MARGIN_COLUMN] = differenceInDays(answerDue, answerDate);
  }

  if (item.step && item.step.name) {
    row[STEP_COLUMN] = item.step.name;
  }

  if (
    item.step &&
    item.step.id &&
    decision_log_step_id &&
    decision_log_step_id === item.step.id &&
    item.locked &&
    item.modified_date
  ) {
    row[CONCLUDED_COLUMN] = item.modified_date;
  }

  return row;
};

/**
 * Check if date exists (otherwise it's a "No due date") and that it been in the past
 * @param date Date()
 * @returns boolean
 */
export const isOverdue = (date) => {
  const now = Date.now();
  return date && startOfDay(date) < startOfDay(now);
};

/**
 * Map array of raw questions into common objects
 * with extractDeliveries()
 * @param questions
 * @param field_config
 * @returns {{dueDate: string, met: boolean}[]}
 */
export const extractQuestions = (questions, field_config) => {
  return flattenArray(questions).map((question) => {
    let dueDate = question.fields.find((field) => field_config.answer_due_field === field.id);
    const met = !!(field_config.answered_date_field
      ? question.fields.find((field) => field_config.answered_date_field === field.id).value
      : question.fields.find((field) => field_config.answer_field === field.id).value);
    dueDate = dueDate ? dueDate.value : null;
    return { dueDate, met };
  });
};

/**
 * Map array of raw deliveries into common objects
 * with extractQuestions()
 * @param deliveries
 * @param delivery_config
 * @returns {{dueDate: string, met: boolean}[]}
 */
export const extractDeliveries = (deliveries, delivery_config) => {
  return !delivery_config
    ? []
    : deliveries.map((delivery) => {
        const dueDate = delivery.fields.find((field) => delivery_config.delivery_date_field === field.id).value;
        const met = !!delivery.fields.find((field) => delivery_config.delivery_met_date_field === field.id).value;
        return {
          dueDate,
          met,
        };
      });
};

/**
 * Calculate total and met(green) amount of cards for the period
 * @param dateFrom
 * @param dateTo
 * @param cards
 * @returns {{total: number, green: number}}
 */
export const calculatePPC = (dateFrom, dateTo, cards) => {
  const filteredCards = cards
    .filter((card) => card.dueDate)
    .filter((card) => {
      const date = startOfDay(new Date(card.dueDate));
      return date >= startOfDay(dateFrom) && date <= startOfDay(dateTo);
    });
  const green = filteredCards.filter((card) => card.met).length;
  return {
    total: filteredCards.length,
    green,
  };
};

// this needed for IE cause it doesn't support flat()
export const flattenArray = (array) => {
  return array.reduce(function (a, b) {
    return a.concat(b);
  }, []);
};

// check if card should be marked
export const isCardInPPCPeriod = (item, board, viewConfig) => {
  const dueDate = Date.parse(getDueDate(item, board));
  return (
    dueDate &&
    isWithinInterval(dueDate, {
      start: startOfDay(viewConfig.ppc_dateFrom),
      end: endOfDay(viewConfig.ppc_dateTo),
    })
  );
};

export const getCardMetaInfo = (card, roomMembers) => ({
  [OWNER_COLUMN]: getMemberNameById({ id: card.owner, members: roomMembers }),
  [CREATED_COLUMN]: DateTimeFormatter({
    value: card.creation_date || card.creationDate,
  }),
  [CREATED_BY_COLUMN]: getMemberNameById({
    id: card.created_by || card.createdBy,
    members: roomMembers,
  }),
  [CHANGED_COLUMN]: DateTimeFormatter({
    value: card.modified_date || card.modifiedDate,
  }),
  [CHANGED_BY_COLUMN]: getMemberNameById({
    id: card.modified_by || card.modifiedBy,
    members: roomMembers,
  }),
});

export const getCardInfo = (row, roomMembers) => ({
  ...getCardMetaInfo(row, roomMembers),
  [COMMENT_COLUMN]: row.comment_count,
  [ATTACHMENT_COLUMN]: row.child_count,
});

export const getMemberNameById = ({ id, members }) => {
  const user = members.find((member) => member.id === id);

  return user ? user.name : id;
};

export const isNumberField = ({ fields, titleId }) => {
  const field = fields.find((field) => field.id === titleId);
  return field ? field.type === 'numeric' || field.type === 'auto-number' : false;
};

export const calculateCellWidth = ({ areCellsExpanded, fields, minimumCellWidth, defaultWidth, titleId }) => {
  if (areCellsExpanded) {
    return minimumCellWidth;
  }

  return isNumberField({ fields, titleId }) ? minimumCellWidth : defaultWidth;
};

// Return the title of a card to be displayed. The name of the field that contains the title is stored in viewConfig.
// However, for milestones (whose field names may vary between milestone sets), there is a special value,
// DISPLAY_ENTRY_TITLE, that will cause the milestone's entry title to be displayed. The entry title is also the default
// value if no title field has been configured in viewConfig.
export const getTitle = (card, viewConfig, titleField) => {
  let field;

  if (titleField && viewConfig[titleField] && viewConfig[titleField] !== DISPLAY_ENTRY_TITLE) {
    field = card.fields.find((field) => field.id === viewConfig[titleField]);
    if (field) {
      if (field.type === 'date') {
        return DateFormatter({ value: field.value });
      }
      if (field.type === 'numeric') {
        return (field.value / 100).toFixed(2); // Last two digits are decimals
      }
      return field.value;
    }
  }
  return card.name;
};

// DARWIN-20428: if manual sequence ID is not mandatory, then disable it
export const getNonMandatoryManualSequenceId = (fields) =>
  fields.filter((field) => field.id === MANUAL_SEQUENCE_ID && !field.mandatory).map((field) => field.id);
