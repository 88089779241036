import { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import LinearProgress from '@mui/material/LinearProgress';

import { alertAction } from '../../thunks/Alerts';
import { flattenArray, getFromAndToFields, shouldToValuesBeUpdated } from '../../utils/FieldUtil';
import { TILE_BOARD_VIEW_MODE } from '../../utils/ViewModeUtil';
import * as boardsActions from '../../thunks/Boards';
import * as cardActions from '../../thunks/Cards';
import {
  selectBoardCards,
  selectIsLoadingCards,
  selectViewConfig,
  actions as viewActions,
  selectPossibleResponsible,
} from '../../ducks/Cards';
import {
  selectActiveBoard,
  selectBoardConfig,
  selectIsLoadingActiveBoard,
  selectTileScrollPos,
  actions as bActions,
} from '../../ducks/Boards';
import PageWrapper from '../../common/PageWrapper';
import Steps from './Steps';
import TileBoard from './TileBoard';
import Toolbar from './Toolbar';

import { selectShowSidebar, selectZoomLevel } from '../../ducks/App';
import AddCardButton from './AddCardButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { Card } from '@/models/card/CardModel';
import { Board } from '@/models/BoardModel';

const mapStateToProps = () =>
  createStructuredSelector({
    activeBoard: selectActiveBoard(),
    isLoadingActiveBoard: selectIsLoadingActiveBoard(),
    isLoadingCards: selectIsLoadingCards(),
    cards: selectBoardCards(),
    config: selectBoardConfig(),
    viewConfig: selectViewConfig(),
    scrollPos: selectTileScrollPos(),
    showSidebar: selectShowSidebar(),
    zoomLevel: selectZoomLevel(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      ...boardsActions,
      ...bActions,
      ...cardActions,
      setViewConfig: viewActions.setViewConfig,
    },
    dispatch,
  ),
});

const TileBoardContainer = ({
  cards,
  activeBoard,
  isLoadingActiveBoard,
  isLoadingCards,
  config,
  viewConfig,
  scrollPos,
  possibleResponsible,
  showSidebar,
  actions,
}: {
  cards: Card[];
  activeBoard: Board;
  isLoadingActiveBoard: boolean;
  isLoadingCards: boolean;
  config: any;
  viewConfig: any;
  scrollPos: number;
  possibleResponsible: any;
  showSidebar: boolean;
  actions: any;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (cards.length > 1 || activeBoard.id.toString() !== params.boardId) {
      actions.fetchCards({ boardId: params.boardId }).then((action) =>
        alertAction({
          action,
          error: intl.get('board.views.tile.fetch_cards.error'),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCardClick = ({ card }) => {
    navigate(`/${activeBoard.id}/cards/${card.id}`);
  };

  const handleSetScrollPos = ({ pos }: { pos: number }) => {
    // actions.setTileScrollPos({ scrollPos: pos });
  };

  const handleCreateCard = () => {
    navigate(`/${activeBoard.id}/create-card`);
  };

  const renderCreateCardButton = () => (
    <AddCardButton onClick={handleCreateCard} title={intl.get('board.tooltips.add_card')} />
  );

  const handleUpdateCard = ({ card, values }) => {
    const initialStepId = activeBoard.step_config.initial_step_id;

    const updatedValues = getFromAndToFields({
      board: activeBoard,
      card,
      values,
    });

    actions
      .updateCardImmediately({
        boardId: activeBoard.id,
        card,
        stepId: initialStepId,
        data: updatedValues || [],
      })
      .then((action) =>
        alertAction({
          action,
          error: intl.get('board.views.tile.update_card.error'),
          success: intl.get('board.views.tile.update_card.success'),
        }),
      );
  };

  const handleMoveCard = ({ board, card, step, values }) => {
    const toField = card.fields.find((field) => field.id === board.field_config.to_member_field);

    const shouldToBeUpdated = shouldToValuesBeUpdated({
      board,
      card,
      stepId: step.id,
      values,
    });

    if (values && !shouldToBeUpdated) {
      values.to = toField.value;
    }

    actions
      .updateCardImmediately({
        boardId: board.id,
        cardId: card.id,
        stepId: step.id,
        card,
        data: values || [],
      })
      .then((action) => {
        alertAction({
          action,
          error: intl.get('board.views.tile.move_card.error'),
          success: intl.get('board.views.tile.move_card.success'),
        });
      });
  };

  const handleConfigChange = (viewConfig) => {
    actions.setViewConfig(viewConfig);
  };

  const handleExpandAll = () => {
    handleConfigChange(
      viewConfig.set(
        'expandedTileRows',
        config.xColumns.map((column) => column.id),
      ),
    );
  };

  const handleCollapseAll = () => {
    handleConfigChange(viewConfig.set('expandedTileRows', []));
  };

  const handleExpandAllCells = () => {
    const cells = [];
    config.xColumns.map((xCol, xIndex) => config.yColumns.map((yCol, yIndex) => cells.push(`${xIndex}-${yIndex}`)));
    handleConfigChange(
      viewConfig
        .set('expandedTileCells', cells)
        .set(
          'expandedTileRows',
          viewConfig.expandedTileRows.length === 0
            ? config.xColumns.map((column) => column.id)
            : viewConfig.expandedTileRows,
        ),
    );
  };

  const handleCollapseAllCells = () => {
    handleConfigChange(viewConfig.set('expandedTileCells', []));
  };

  const handleStepSidebarClick = () => {
    handleConfigChange(viewConfig.set('stepsSidebarOpen', !viewConfig.stepsSidebarOpen));
  };

  if (isLoadingActiveBoard || isLoadingCards) {
    return <LinearProgress />;
  }

  const containerWidth = isMediumUp
    ? window.innerWidth - (viewConfig.stepsSidebarOpen ? 410 : 115) - (showSidebar ? 205 : 0)
    : window.innerWidth;

  return (
    <PageWrapper title={intl.get('app_bar.tile_view')}>
      <Toolbar
        renderCreateCardButton={renderCreateCardButton}
        board={activeBoard}
        onExpandAll={handleExpandAll}
        onCollapseAll={handleCollapseAll}
        expandedRows={viewConfig.expandedTileRows}
        expandedCells={viewConfig.expandedTileCells}
        onExpandAllCells={handleExpandAllCells}
        onCollapseAllCells={handleCollapseAllCells}
        viewConfig={viewConfig}
        onChange={handleConfigChange}
        subscription
        possibleResponsible={possibleResponsible}
        viewName={TILE_BOARD_VIEW_MODE}
      />
      <TileBoard
        width={containerWidth}
        config={config}
        viewConfig={viewConfig}
        scrollPos={scrollPos}
        cards={flattenArray(cards)}
        board={activeBoard}
        onUpdateCard={handleUpdateCard}
        onCardClick={handleCardClick}
        onChange={handleConfigChange}
        onSetScrollPos={handleSetScrollPos}
      />
      <Steps
        open={viewConfig.stepsSidebarOpen}
        onSidebarClick={handleStepSidebarClick}
        board={activeBoard}
        cards={cards[1]}
        steps={activeBoard.step_config.steps.filter((step) => step.id !== activeBoard.step_config.initial_step_id)}
        viewConfig={viewConfig}
        onCardClick={handleCardClick}
        onMoveCardToStep={handleMoveCard}
        onChange={handleConfigChange}
      />
    </PageWrapper>
  );
};

const ConnectedTileBoardContainer = connect(mapStateToProps, mapDispatchToProps)(TileBoardContainer);

export default ConnectedTileBoardContainer;
