import intl from 'react-intl-universal';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Gravatar from 'react-gravatar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/icons-material/Menu';
import Help from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography';
import { ENVIRONMENTAL_VARIABLES } from '../utils/Environment';
import { Theme, useTheme } from '@mui/material';
import { Box } from '@mui/material';

const useStyles = (theme: Theme) => ({
  flexGrow: {
    flex: '1 1 auto',
  },
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    position: 'fixed',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      marginLeft: 0,
      width: '100%',
    },
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      marginLeft: '45px',
      width: 'calc(100% - 45px)',
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  iconElementLeft: {
    display: 'flex',
    color: 'black',
    padding: '10px',
  },
  sidebarButton: {
    padding: 0,
  },
  helpButton: {
    height: '42px',
    width: '42px',
    color: 'black',
  },
  menuIcon: {
    color: 'black',
  },
  avatar: {
    flex: 1,
    paddingRight: '10px',
  },
  userInitials: {
    flex: 4,
    '& h3': {
      margin: 0,
    },
    '& p': {
      fontStyle: 'italic',
      margin: 0,
    },
    '& span': {
      display: 'inline-block',
      paddingTop: '7px',
    },
    '& a': {
      cursor: 'pointer',
    },
  },
  userMenu: {
    height: '170px',
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    flex: 3,
  },
  bottomMenu: {
    borderTop: '1px solid #3eb1c8',
    padding: '10px',
    flex: 1,
  },
  appBarShift: {
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      marginLeft: 0,
      width: '100%',
      zIndex: 1199,
    },
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
      marginLeft: '250px',
      width: 'calc(100% - 250px)',
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  titleShift: {
    marginLeft: '16px',
  },
});

const handleHelpClick = (event: any) => {
  const helpWindow = window.open('', '_blank') as Window;

  helpWindow.opener = null;
  helpWindow.location = ENVIRONMENTAL_VARIABLES.HELP_CENTER_URL + intl.get('app_bar.help.language');
};

const Header = ({
  user,
  showUserMenu,
  userMenuLocation,
  onUserMenuClick,
  onSidebarClick,
  onLogout,
  sidebarOpen,
  activeBoard,
}: {
  user: any;
  showUserMenu: boolean;
  userMenuLocation: string;
  onUserMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSidebarClick: () => void;
  onLogout: () => void;
  sidebarOpen: boolean;
  activeBoard: any;
}) => {
  const theme = useTheme();
  const classes: any = useStyles(theme);

  const handleUserMenu = (event: any) => {
    event.preventDefault();
    onUserMenuClick(event.currentTarget);
  };

  return (
    <AppBar
      sx={[
        {
          ...classes.appBar,
        },
        sidebarOpen && classes.appBarShift,
      ]}
    >
      <Toolbar disableGutters>
        {!sidebarOpen && (
          <Box component="div" sx={classes.iconElementLeft}>
            <Tooltip title={intl.get('app_bar.sidebar.open.tooltip')}>
              <IconButton sx={classes.sidebarButton} onClick={onSidebarClick}>
                <Menu sx={classes.menuIcon} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box component="div" sx={sidebarOpen ? classes.titleShift : null}>
          <Typography variant="h6">{activeBoard && activeBoard.name}</Typography>
          <Typography variant="caption">
            {activeBoard &&
              activeBoard.workflow_config &&
              `${activeBoard.workflow_config.community_id} / ${activeBoard.workflow_config.room_id} / ${activeBoard.workflow_config.workflow_name}`}
          </Typography>
        </Box>
        <Box component="div" sx={classes.flexGrow} />
        <Tooltip title={intl.get('app_bar.user_profile.tooltip')}>
          <IconButton id="userMenuButton" onClick={(event) => handleUserMenu(event)}>
            <Avatar>
              <Gravatar email={user.email || ''} default="mm" />
            </Avatar>
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.get('app_bar.help.tooltip')}>
          <IconButton onClick={handleHelpClick}>
            <Help sx={classes.helpButton} />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Popover
        open={showUserMenu}
        anchorEl={document.querySelector(`#${userMenuLocation}`)}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        onClose={onUserMenuClick}
      >
        <Box component="div" sx={classes.userMenu}>
          <Box component="div" sx={classes.userInfo}>
            <Box component="div" sx={classes.avatar}>
              <Gravatar email={user.email || ''} default="mm" />
            </Box>
            <Box component="div" sx={classes.userInitials}>
              <Typography variant="h6">{user.name || intl.get('app_bar.unknown')}</Typography>
              <Typography variant="body2">{user.email || ''}</Typography>
            </Box>
          </Box>
          <Box component="div" sx={classes.bottomMenu}>
            <Button variant="contained" color="primary" onClick={onLogout}>
              {intl.get('app_bar.logout').defaultMessage('Logout')}
            </Button>
          </Box>
        </Box>
      </Popover>
    </AppBar>
  );
};
export default Header;
