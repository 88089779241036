import intl from 'react-intl-universal';
import Paper from '@mui/material/Paper';
import { Theme, useTheme } from '@mui/material';
import { logException } from '@/store/analytics';
import { useEffect } from 'react';

const useStyles = (theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    color: theme.palette.error.main,
  },
});

const Error = ({ text }: { text?: string }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    logException({
      type: 'NAVIGATION',
      message: `Failed to process route: ${text}`,
    })

  }, [text]);

  return (
    <Paper sx={classes.root}>
      <h2>{text || intl.get('common.error.page_not_found')}</h2>
    </Paper>
  );
};

export default Error;
