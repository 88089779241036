import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { Field } from '@/models/card/CardModel';


const DayPicker = ({ field, disabled, handleChange, error, ...other }: {
  field: Field;
  disabled: boolean;
  handleChange: (id: string, value: any) => void;
  error: string;
  other: any;
}) => {

  return (
    <MuiDatePicker
      disabled={disabled}
      slotProps={{ textField: { variant: 'standard', } }}
      format="yyyy-MM-dd"
      label={field.name}
      name={field.id}
      onChange={(value) => {
        const formattedDate = value ? format(value, 'yyyy-MM-dd') : null;
        handleChange(field.id, formattedDate);
      }}
      {...other}
    />
  );
};

export default DayPicker;
