import { map } from 'lodash';

import * as api from '../services/API';
import { actions } from '../ducks/Deliveries';
import onError from './Error';
import Immutable from 'seamless-immutable';
import { sortAuthorities } from '../utils/SortUtil';
import { Comment } from '@/models/CommentModel';
import { Dispatch } from 'redux';
import { Field } from '@/models/card/CardModel';
import { Delivery } from '@/models/DeliveryModel';

export const fetchDeliveries = ({ boardId }) => {
  return async (dispatch) => {
    dispatch(actions.fetchDeliveriesRequest());

    try {
      const deliveries = await api.fetchDeliveries({ boardId });
      return dispatch(actions.fetchDeliveriesSuccess({ deliveries }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchDeliveriesError({ error }),
      });
    }
  };
};

export const createDelivery = ({ boardId, data }) => {
  return async (dispatch) => {
    dispatch(actions.createDeliveryRequest());

    try {
      const delivery = await api.createDelivery({
        boardId,
        data,
      });
      return dispatch(actions.createDeliverySuccess({ delivery }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.createDeliveryError({ error }),
      });
    }
  };
};

export const updateDelivery = ({ boardId, deliveryId, data }) => {
  return async (dispatch) => {
    dispatch(actions.updateDeliveryRequest());

    try {
      const delivery = await api.updateDelivery({
        boardId,
        deliveryId,
        data,
      });
      return dispatch(
        actions.updateDeliverySuccess({
          delivery,
        }),
      );
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.updateDeliveryError({ error }),
      });
    }
  };
};

export const updateDeliveryImmediately = ({ boardId, delivery, data }: { boardId: string, delivery: Delivery, data: Field[] }) => {
  return async (dispatch: Dispatch) => {
    const updatedDelivery = delivery.merge({
      fields: delivery.fields.map((field: Field) => {
        const fields = data.filter((updatedField: Field) => updatedField.id === field.id);
        return fields.length ? Immutable(fields[0]) : field;
      }),
      fakeModel: true,
    });

    dispatch(
      actions.updateDeliveryImmediatelySuccess({
        delivery: updatedDelivery,
      }),
    );

    try {
      const cardFromBackend = await api.updateDelivery({
        boardId,
        deliveryId: delivery.id,
        data: {
          type: 'entry',
          fields: data,
        },
      });

      return dispatch(actions.updateDeliverySuccess({ delivery: cardFromBackend }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.updateDeliveryImmediatelyError({ error, delivery }),
      });
    }
  };
};

export const deleteDelivery = ({ boardId, delivery }: { boardId: string, delivery: Delivery }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.deleteDeliveryRequest());

    try {
      await api.deleteDelivery({
        boardId,
        deliveryId: delivery.id,
      });
      return dispatch(actions.deleteDeliverySuccess({ boardId, delivery }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.deleteDeliveryError({ error }),
      });
    }
  };
};

export const fetchAttachments = ({ boardId, deliveryId }: { boardId: string, deliveryId: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchAttachmentsRequest());
    try {
      const attachments = await api.fetchDeliveryAttachments({
        boardId,
        deliveryId,
      });
      return dispatch(actions.fetchAttachmentsSuccess({ attachments }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchAttachmentsError({ error }),
      });
    }
  };
};

export const uploadAttachment = ({ boardId, deliveryId, files }: { boardId: string, deliveryId: string, files: File[] }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.uploadAttachmentRequest());
    try {
      const uploads = [];
      const attachUpload = (file: File) =>
        uploads.push(
          api.uploadDeliveryAttachment({
            boardId,
            deliveryId,
            file,
          }),
        );
      map(files, attachUpload);
      const attachments = await Promise.all(uploads);
      return dispatch(actions.uploadAttachmentSuccess({ deliveryId, attachments }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.uploadAttachmentError({ error }),
      });
    }
  };
};

export const addDeliveryLink = ({ boardId, deliveryId, link }: { boardId: string, deliveryId: string, link: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.addLinkRequest());
    try {
      const createdLink = await api.addDeliveryLink({
        boardId,
        deliveryId,
        link,
      });
      return dispatch(actions.addLinkSuccess({ deliveryId, link: createdLink }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.addLinkError({ error }),
      });
    }
  };
};

export const deleteAttachment = ({ boardId, deliveryId, attachmentId }: { boardId: string, deliveryId: string, attachmentId: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.deleteAttachmentsRequest());
    try {
      await api.deleteDeliveryAttachment({
        boardId,
        deliveryId,
        attachmentId,
      });
      return dispatch(actions.deleteAttachmentsSuccess({ deliveryId, attachmentId }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.deleteAttachmentsError({ error }),
      });
    }
  };
};

export const createComment = ({ boardId, deliveryId, content }: { boardId: string, deliveryId: string, content: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.createCommentRequest());
    try {
      const comment = await api.createDeliveryComment({
        boardId,
        deliveryId,
        content,
      });
      comment.cardId = deliveryId;
      return dispatch(actions.createCommentSuccess({ comment }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.createCommentError({ error }),
      });
    }
  };
};

export const updateComment = ({ boardId, deliveryId, comment }: { boardId: string, deliveryId: string, comment: Comment }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.updateCommentRequest());
    try {
      const updatedComment = await api.updateDeliveryComment({
        boardId,
        deliveryId,
        comment,
      });
      updatedComment.cardId = deliveryId;
      return dispatch(actions.updateCommentSuccess({ comment: updatedComment }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.updateCommentError({ error }),
      });
    }
  };
};

export const deleteComment = ({ boardId, deliveryId, commentId }: { boardId: string, deliveryId: string, commentId: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.deleteCommentRequest());
    try {
      await api.deleteDeliveryComment({ boardId, deliveryId, commentId });
      return dispatch(actions.deleteCommentSuccess({ commentId: commentId }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.deleteCommentError({ error }),
      });
    }
  };
};

export const fetchComments = ({ boardId, deliveryId }: { boardId: string, deliveryId: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchCommentsRequest());
    try {
      const comments = await api.fetchDeliveryComments({
        boardId,
        deliveryId,
      });
      comments.forEach((comment: Comment) => {
        comment.cardId = deliveryId;
      });
      return dispatch(actions.fetchCommentsSuccess({ comments }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchCommentsError({ error }),
      });
    }
  };
};

export const fetchLog = ({ boardId, cardId }: { boardId: string, cardId: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchLogRequest());
    try {
      const log = await api.fetchCardLog({ boardId, cardId });
      return dispatch(actions.fetchLogSuccess({ log }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchLogError({ error }),
      });
    }
  };
};

export const fetchPossibleResponsible = ({ boardId }: { boardId: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchPossibleResponsibleRequest({ boardId }));
    try {
      // todo: implement a new API to get users and groups who have access to the folder,
      //  but decided to use all room members (users and groups) as a quick fix
      const allRoomMembers = await api.getAllRoomMembers({ boardId });
      const possibleResponsible = sortAuthorities(allRoomMembers);

      return dispatch(actions.fetchPossibleResponsibleSuccess({ possibleResponsible }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchPossibleResponsibleError({ error }),
      });
    }
  };
};
