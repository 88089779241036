import React from 'react';
import intl from 'react-intl-universal';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Minimize from '@mui/icons-material/Remove';

import QuestionCardItem from '../routes/board/QuestionCardItem';
import DeliveryCardItem from '../routes/board/DeliveryCardItem';
import CardList from './CardList';
import { Box } from '@mui/material';

const useStyles = () => ({
  minimize: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginBottom: '-8px',
  },
  minimizeIcon: {
    width: '24px',
    height: '24px',
    padding: '0px',
  },
});

const hasItems = (list: []) => list && list.length > 0;

const CardFolderOpen = ({
  board,
  viewConfig,
  rowIndex,
  columnIndex,
  questions,
  deliveries,
  onCollapse,
  onCardClick,
  type,
}: { board: object; viewConfig: { highReadability: boolean }; rowIndex: number; columnIndex: number; questions: []; deliveries: []; onCollapse: () => void; onCardClick: () => void; type: string }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {(hasItems(questions) || hasItems(deliveries)) && (
        <React.Fragment>
          <Box component="div" sx={classes.minimize}>
            <Tooltip title={intl.get('boards.tooltips.close')}>
              <IconButton sx={classes.minimizeIcon} onClick={onCollapse}>
                <Minimize />
              </IconButton>
            </Tooltip>
          </Box>
          <CardList
            board={board}
            viewConfig={viewConfig}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            items={questions || []}
            CardComponent={QuestionCardItem}
            onCardClick={onCardClick}
            type={type}
          />
          <CardList
            board={board}
            viewConfig={viewConfig}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            items={deliveries || []}
            CardComponent={DeliveryCardItem}
            onCardClick={onCardClick}
            type={type}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CardFolderOpen;
