import React, { useState, useEffect, useContext } from 'react';

import Keycloak from 'keycloak-js';
import PropTypes from 'prop-types';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const KeycloakContext = React.createContext();
// eslint-disable-next-line
export const useKeycloak = () => useContext(KeycloakContext);
export const KeycloakProvider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [authClient, setAuthClient] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initKeycloak = async () => {
      const keycloak = new Keycloak({
        url: initOptions.url, // 'http://localhost:7777/auth'
        clientId: initOptions.client_id, // 'interaxo-admin'
        realm: initOptions.realm, // 'INTERAXO'
        scope: initOptions.scope, // 'openid profile ix:web-api:all'
      });

      setAuthClient(keycloak);

      const isAuthenticated = await keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        redirectUri: initOptions.redirect_uri || '/', // 'http://localhost:3000/admin/marvel-x-men'
      });

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await keycloak.loadUserProfile();
        setUser(user);
      }

      setLoading(false);
    };
    initKeycloak();
    // eslint-disable-next-line
  }, []);

  return (
    <KeycloakContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        authClient,
        loginWithRedirect: (...p) => {
          return;
        },
        getTokenSilently: async (...p) => {
          await authClient.updateToken(30);
          return authClient.token;
        },
        logout: (...p) => authClient.logout(...p),
      }}
    >
      {children}
    </KeycloakContext.Provider>
  );
};

KeycloakProvider.propTypes = {
  children: PropTypes.node,
  onRedirectCallback: PropTypes.func,
};
