import intl from 'react-intl-universal';

export const validateRequiredFields = ({ requiredFields, values, errors }) => {
  requiredFields.forEach((f) => {
    if (!values[f]) {
      errors[f] = intl.get('common.form.validators.required');
    }
  });
};

export const validateEntryTitleField = ({ fieldConfig, values, errors }) => {
  const entryTitle = fieldConfig.fields.find((field) => field.entry_title);
  const entryTitleValidator = entryTitle.type === 'date' ? /[/?*"><|\\]/ : /[/?*:"><|\\]/;
  if (entryTitleValidator.test(values[entryTitle.id])) {
    errors[entryTitle.id] = intl.get('common.form.validators.entry_title');
  }
};

export const validateAnsweredDateField = ({ fieldConfig, values }) =>
  // When Answer field is empty, the Answered date
  // field is disabled. To change the Answered date
  // field, you must fill in the Answer field.
  !!values[fieldConfig.answer_field];

export const validateAnswerField = ({ fieldConfig, values }) =>
  // When both fields (Answer and Answered date) have a value,
  // the Answer field becomes disabled. To change the Answer
  // field, the Answered date field has to be cleared manually.
  !values[fieldConfig.answered_date_field];

export const validateFileName = (value) => {
  const fileNameRegex = /(.*["*\\><?/:|]+.*)|(.*[.]?.*[.]+$)|(.*[ ]+$)/i;
  const initialWhiteSpaceRegex = /^\s+$/;
  return !fileNameRegex.test(value) && !initialWhiteSpaceRegex.test(value);
};
