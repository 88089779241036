// Redux
import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import Immutable from 'seamless-immutable';
import logger from 'redux-logger';
import { actionLogger } from './analytics';
import reducer from './reducer';

export default () => {
  const middlewares = [thunk, actionLogger];

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(logger);
  }

  // Redux devtools enhancement
  const composeEnhancer =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const enhancer = composeEnhancer(applyMiddleware(...middlewares));

  const store = createStore(reducer, Immutable({}), enhancer);

  return store;
};
