import React from 'react';
import intl from 'react-intl-universal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';

const useStyles = () => ({
  dialog: {
    width: 'calc(100% - 16px)',
  },
  root: {
    height: '110px',
  },
  listItem: {
    lineHeight: '1.43',
    fontSize: '0.875rem',
  },
  subheader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    height: '56px',
    lineHeight: '56px',
    textAlign: 'left',
    paddingLeft: '24px',
  },
});

const DeleteAttachmentsDialog = ({ open, record, onClose, onDelete }: any) => {
  const classes: any = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': classes.dialog,
      }}
    >
      <DialogTitle>{intl.get('common.attachments.delete')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.get('common.attachments.delete.confirmation')}</DialogContentText>
        <Paper sx={classes.root}>
          <List
            subheader={
              <ListSubheader sx={classes.subheader}>{intl.get('common.attachments.columns.name')}</ListSubheader>
            }
          >
            <React.Fragment>
              <Divider />
              <ListItem style={{ height: '53px' }}>
                <ListItemText classes={{ primary: classes.listItem }} primary={record.name} />
              </ListItem>
            </React.Fragment>
          </List>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.get('common.dialog.cancel')}
        </Button>
        <Button onClick={onDelete} color="secondary">
          {intl.get('common.dialog.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAttachmentsDialog;
