import { useEffect } from 'react';
import createRoutes from './routes';
import intl from 'react-intl-universal';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import ReduxToastr from 'react-redux-toastr';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

// Date picker
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { isSmartTV, isMobile } from 'react-device-detect';

import { SessionExpirationWarning, SessionExpirationMessage } from './common/session';
import { actions as appActions } from './ducks/App';
import {
  actions as authActions,
  selectIsUserProfileLoading,
  selectIsUserProfileRequested,
  selectUserProfile,
} from './ducks/Auth';
import { getUserProfile } from './thunks/Auth';
import { selectShowSidebar } from './ducks/App';
import { selectSessionExpired, selectSessionExpiring } from './ducks/Auth';
import { selectActiveBoard } from './ducks/Boards';
import { actions as communityActions, selectActiveCommunity, selectWasRequested } from './ducks/Communities';
import Header from './common/HeaderContainer';
import Sidebar from './common/Sidebar';
import { getLanguage } from './utils/LocaleUtil';
import initLocale from './initLocale';
import * as communitiesActions from './thunks/Communities';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { initialize as initializeGoogleAnalytics, logPageView } from './store/analytics';
import ZoomDetector from './common/ZoomDetector';
import CircularProgress from '@mui/material/CircularProgress';
import queryString from 'query-string';
import { AppThemeProvider } from './themes/AppThemeProvider';
import { useLocation } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { Board } from './models/BoardModel';

initializeGoogleAnalytics();

const useStyles = () => ({
  root: {
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    paddingTop: '24px',
    height: 'calc(100% - 56px)',
    marginTop: '40px',
  },
});

const mapStateToProps = () =>
  createStructuredSelector({
    showSidebar: selectShowSidebar(),
    communityId: selectActiveCommunity(),
    wasCommunitiesRequested: selectWasRequested(),
    activeBoard: selectActiveBoard(),
    sessionExpired: selectSessionExpired(),
    sessionExpiring: selectSessionExpiring(),
    userProfile: selectUserProfile(),
    isUserProfileLoading: selectIsUserProfileLoading(),
    isUserProfileRequested: selectIsUserProfileRequested(),
  });

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...appActions,
      ...authActions,
      ...communitiesActions,
      setActiveCommunity: communityActions.setActiveCommunity,
      getUserProfile,
    },
    dispatch,
  ),
});

const App = ({
  communityId,
  isUserProfileRequested,
  wasCommunitiesRequested,
  actions,
  showSidebar,
  activeBoard,
  sessionExpired,
  sessionExpiring,
  isUserProfileLoading,
  userProfile,
  onResetSessionTimeoutTrackers,
  onLogout,
}: {
  communityId: string;
  isUserProfileRequested: boolean;
  wasCommunitiesRequested: boolean;
  actions: any;
  showSidebar: boolean;
  activeBoard: Board;
  sessionExpired: boolean;
  sessionExpiring: boolean;
  isUserProfileLoading: boolean;
  userProfile: any;
  onResetSessionTimeoutTrackers: () => void;
  onLogout: () => void;
}) => {

  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    const communityIdFromUrl = queryString.parse(location.search).communityId || '';
    let currentCommunityId = communityIdFromUrl || communityId;

    if (!wasCommunitiesRequested) {
      actions.fetchCommunities().then((action: any) => {
        currentCommunityId = currentCommunityId || action.payload.communities[0].id;
        actions.setActiveCommunity({ communityId: currentCommunityId });

        if (!isUserProfileRequested) {
          actions.getUserProfile({ communityId: currentCommunityId });
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  const handleSidebar = () => actions.showSidebar({ show: !showSidebar });

  const calculateMarginLeft = () => {
    if (window.matchMedia('(max-width: 960px)').matches) {
      return 0;
    }
    if (showSidebar) {
      return 250;
    }
    return 45;
  };

  if (isUserProfileLoading || !isUserProfileRequested) {
    return (
      <div
        style={{
          height: `${window.innerHeight}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const marginLeft = calculateMarginLeft();
  const width = `calc(100% - ${marginLeft}px)`;
  // const locale = intl.getInitOptions().currentLocale;

  if (userProfile) {
    initLocale(getLanguage(userProfile.locale));
  } else {
    initLocale();
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AppThemeProvider>
        <CssBaseline />
        <ZoomDetector />
        <Box component="div" sx={classes.root}>
          <Box component="div" sx={classes.appFrame}>
            <Sidebar show={showSidebar} onMenuClick={handleSidebar} activeBoard={activeBoard} />
            <Header
              activeBoard={activeBoard}
              sidebarOpen={showSidebar}
              onSidebarClick={handleSidebar}
              onLogout={onLogout}
            />

            <Box component="div" sx={classes.content} style={{ width, marginLeft }}>
              {createRoutes()}
            </Box>
            {sessionExpiring && !sessionExpired && (
              <SessionExpirationWarning
                open
                handleClose={() => {
                  onResetSessionTimeoutTrackers();
                  actions.setSessionExpiring(false);
                }}
                title={intl.get('session.expiration.warning.title')}
                message={intl.get('session.expiration.warning.message')}
                yesText={intl.get('common.yes')}
                noText={intl.get('common.no')}
              />
            )}
            {sessionExpired && (
              <SessionExpirationMessage
                open
                title={intl.get('session.expired.title')}
                message={intl.get('session.expired.message')}
                okText={intl.get('common.ok')}
              />
            )}
          </Box>
        </Box>
        <ReduxToastr
          timeOut={1500}
          newestOnTop={true}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
        />
      </AppThemeProvider>
    </LocalizationProvider>
  );
};

const ConnectedApp = compose(
  DragDropContext(isSmartTV || isMobile ? TouchBackend : HTML5Backend),
  connect(mapStateToProps, mapDispatchToProps),
)(App);

export default ConnectedApp;
