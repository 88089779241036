import { Board } from '@/models/BoardModel';
import { Card } from '@/models/card/CardModel';
import { DragSource } from 'react-dnd';

const cardSource = {
  canDrag(props: { card: Card, board: Board, rowIndex: number, columnIndex: number }) {
    const { ASK, UPDATE, MOVE } = props.card.permissions;
    // Don't allow to drag and drop cards that already was dropped and waiting
    // for response from server. fakeModel is a flag for such models.
    // disabled drag-n-drop for question cards in non-initial steps on TileBoard && QuestionBoard until business logic clarified
    return (
      !props.card.fakeModel &&
      (!props.card.step ||
        props.board.step_config.initial_step_id === props.card.step.id ||
        (props.rowIndex === 0 && props.columnIndex === 0)) &&
      // MOVE permission contains steps as keys, and true or false as values
      (ASK || UPDATE || Object.values(MOVE).some((el) => el))
    );
  },
  beginDrag(props: { card: Card, rowIndex: number, columnIndex: number }) {
    return {
      card: props.card,
      rowIndex: props.rowIndex,
      columnIndex: props.columnIndex,
    };
  },
};

const collect = (connect: any, monitor: any) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

const Draggable = ({
  children,
  connectDragSource,
  isDragging,
}: {
  children?: React.ReactNode;
  connectDragSource: (node: React.ReactNode) => React.ReactNode;
  isDragging?: boolean;
}) => {
  return connectDragSource(<div style={{ opacity: isDragging ? '0.2' : '1' }}>{children}</div>);
};

const ConnectedDraggable = DragSource('CARD', cardSource, collect)(Draggable);

export default ConnectedDraggable;
