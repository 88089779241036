import queryString from 'query-string';

export const LIST_BOARD_VIEW_MODE = 'list';
export const LIST_MILESTONE_VIEW_MODE = 'milestone_list';
export const LIST_DELIVERY_VIEW_MODE = 'delivery_list';
export const TILE_BOARD_VIEW_MODE = 'tile';
export const WEEK_BOARD_VIEW_MODE = 'week';

export const extractViewMode = () => queryString.parse(window.location.search).view;

export const extractStep = () => queryString.parse(window.location.search).step;

export const withViewModeQuery = (url) => {
  const viewMode = extractViewMode();
  return viewMode ? `${url}?view=${viewMode}` : url;
};

export const withViewModeAndStepQuery = (url) => {
  const step = extractStep();
  const viewMode = extractViewMode();

  const urlWithViewMode = viewMode ? `${url}?view=${viewMode}` : url;
  return step ? `${urlWithViewMode}&step=${step}` : urlWithViewMode;
};
