import { PermissionModel } from '@/models/permission/PermissionModel';
import { Step } from '@/models/folder/StepModel';
import { Card, CardType } from '@/models/card/CardModel';

/**
 * Calculate permissions for move to step
 */
export const canMoveToStep = ({ step, card }: { step: Step; card: Card }) => {
  if (card.type === CardType.MILESTONE || card.type === CardType.DELIVERY) return false;
  if (step.id === card.step.id) {
    return false;
  }

  return !!card.permissions.MOVE[step.id];
};

export const isInStep = ({ step, card }: { step: Step; card: Card }) => {
  return step.id === card.step.id;
};

export const canEditItem = (permissions: PermissionModel) =>
  permissions.ADD_ATTACHMENT || permissions.ADD_COMMENT || permissions.DELETE_ATTACHMENT;

export const canEditQuestion = (permissions: PermissionModel) =>
  canEditItem(permissions) ||
  permissions.ANSWER ||
  permissions.ASK ||
  // MOVE permission contains steps as keys, and true or false as values
  Object.values(permissions.MOVE).some((el) => el);

export const canEditDelivery = (permissions: PermissionModel) => canEditItem(permissions) || permissions.UPDATE;

export const canEditMilestone = (permissions: PermissionModel) => canEditItem(permissions) || permissions.UPDATE;
