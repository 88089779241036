import intl from 'react-intl-universal';
import CardItem from './CardItem';
import { STATUS_RED, STATUS_GREEN } from './CardItem';
import { milestoneStatusMessages } from '../../utils/MilestoneUtil';
import Milestone from '@mui/icons-material/Stop';
import { geMilestoneFieldValuesToDisplayInTooltip } from '../../utils/FieldUtil';
import { Card, CardType } from '@/models/card/CardModel';
import { Board } from '@/models/BoardModel';

const useStyles = () => ({
  typeIcon: {
    height: '32px',
    width: '32px',
    color: '#d64c48',
  },
  typeIconHighReadability: {
    height: '48px',
    width: '48px',
    color: '#d64c48',
  },
});

function isMilestoneMet(card: Card) {
  return !!card.metDate;
}

const MilestoneCardItem = ({
  card,
  board,
  viewConfig,
  onCardClick,
  rowIndex = 0,
  columnIndex = 0,
  type,
}: {
  card: Card;
  board: Board;
  viewConfig: any;
  onCardClick: ({ card, type }: { card: Card; type: CardType }) => void;
  rowIndex: number;
  columnIndex: number;
  type: CardType;
}) => {
  const classes = useStyles();
  const isMet = isMilestoneMet(card);

  return (
    <CardItem
      key={`card-${card.id}`}
      card={card}
      board={board}
      viewConfig={viewConfig}
      onCardClick={(card: Card) => {
        onCardClick({ card, type: CardType.MILESTONE });
      }}
      getFieldMap={geMilestoneFieldValuesToDisplayInTooltip}
      typeIcon={
        <Milestone
          sx={{
            transform: 'rotate(45deg)',
            ...(viewConfig.highReadability ? classes.typeIconHighReadability : classes.typeIcon),
          }}
        />
      }
      typeIconTooltip={intl.get('board.tooltips.milestone')}
      statusColour={isMet ? STATUS_GREEN : STATUS_RED}
      statusTooltip={intl.get(milestoneStatusMessages[card.status])}
      titleField="milestoneTitle"
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      type={type}
    />
  );
};

export default MilestoneCardItem;
