import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';

import { alertAction } from '../../thunks/Alerts';
import { selectActiveBoard } from '../../ducks/Boards';
import * as boardsActions from '../../thunks/Boards';
import * as milestoneActions from '../../thunks/Milestones';
import { actions as milestonesActions, selectPossibleResponsible } from '../../ducks/Milestones';
import { selectActiveCommunity } from '../../ducks/Communities';
import { selectActiveRoom } from '../../ducks/Rooms';
import {
  getNonMandatoryManualSequenceId,
  populateInitialValues,
  getUpdatedFieldsAndValues,
  getRequiredFields,
  UNIQUE_DOC_ID,
} from '../../utils/FieldUtil';
import PageWrapper from '../../common/PageWrapper';
import { withViewModeQuery } from '../../utils/ViewModeUtil';
import Grid from '@mui/material/Grid';
import Form from '../common/Form';
import MilestoneAttachments from '../common/Attachments';
import MilestoneComments from '../common/Comments';
import memoize from 'lodash/memoize';

const mapStateToProps = () =>
  createStructuredSelector({
    activeCommunity: selectActiveCommunity(),
    activeRoom: selectActiveRoom(),
    board: selectActiveBoard(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...milestoneActions,
      ...milestonesActions,
      ...boardsActions,
    },
    dispatch,
  ),
});

const MilestoneCreateContainer = ({ activeCommunity, activeRoom, board, actions, possibleResponsible }: any) => {
  const formRef = useRef();
  const [closeOnSuccess, setCloseOnSuccess] = useState(false);
  const navigate = useNavigate();
  const { milestoneFolderId } = useParams();

  const getMilestoneSet = memoize((board) => board.milestones_config.find((config) => config.id === milestoneFolderId));

  const handleSubmit = ({ values, item }: { values: any; item: any }) => {
    return actions
      .createMilestone({
        boardId: board.id,
        containerId: milestoneFolderId,
        data: {
          type: 'entry',
          fields: getUpdatedFieldsAndValues({
            values,
            item,
            fieldsConfig: getMilestoneSet(board).fields,
          }),
        },
      })
      .then((action: any) => {
        alertAction({
          action,
          error: intl.get('milestone.create.error'),
          success: intl.get('milestone.create.success'),
          onSuccess: () =>
            navigate(
              withViewModeQuery(
                closeOnSuccess
                  ? `/${board.id}`
                  : `/${board.id}/milestoneset/${action.payload.milestoneSet}/milestones/${action.payload.milestone.id}`,
              ),
            ),
        });
      });
  };

  const handleCancel = () => {
    navigate(withViewModeQuery(`/${board.id}`));
  };

  const handleSaveClick = (closeForm) => {
    setCloseOnSuccess(!!closeForm);

    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  // Create new dummy card
  const milestone = {
    type: 'entry',
    fields: getMilestoneSet(board)
      .fields.filter((f) => f.type !== 'auto-number')
      .map((f) => {
        let value = null;
        if (f.type === 'list' || f.type === 'member') {
          if (f.multiple) {
            value = [];
          } else {
            value = '';
          }
        } else if (f.type === 'number') {
          value = 0;
        }
        return { id: f.id, name: f.name, type: f.type, value: value };
      }),
    permissions: {},
  };

  return (
    <PageWrapper title={intl.get('app_bar.milestone_card')}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={8}>
          <Form
            formRef={formRef}
            item={milestone}
            initialValues={populateInitialValues({
              item: milestone,
              fields: getMilestoneSet(board).fields,
            })}
            disabledFields={[UNIQUE_DOC_ID, ...getNonMandatoryManualSequenceId(getMilestoneSet(board).fields)]}
            disableDelete={true}
            requiredFields={getRequiredFields({
              item: milestone,
              fields: getMilestoneSet(board).fields,
            })}
            type={board.type}
            fieldConfig={getMilestoneSet(board)}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            restrictions={{
              'task-done': (fieldConfig, field) => ({}),
              'task-responsible': (fieldConfig, field) => ({}),
              'rich-text': (fieldConfig, field) => ({}),
              'auto-number': (fieldConfig, field) => ({}),
              'unique-document-id': (fieldConfig, field) => ({}),
              'sequence-number': (fieldConfig, field) => ({}),
              member: (fieldConfig, field) => ({}),
              list: (fieldConfig, field) => ({}),
              date: (fieldConfig, field) => {
                if (fieldConfig.met_date_field === field.id) {
                  return { disableFuture: true };
                }
              },
              string: (fieldConfig, field) => ({}),
              numeric: (fieldConfig, field) => ({}),
            }}
            possibleResponsible={possibleResponsible}
            submitOptions={[
              {
                title: intl.get('common.form.save_and_close'),
                handleClick: () => handleSaveClick(true),
              },
              {
                title: intl.get('common.form.save'),
                default: true,
                handleClick: () => handleSaveClick(false),
              },
            ]}
          />
          <MilestoneAttachments
            activeCommunity={activeCommunity}
            activeRoom={activeRoom}
            item={milestone}
            attachments={[]}
            isUploading={false}
            isDeleting={false}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <MilestoneComments board={board} item={milestone} comments={[]} members={[]} />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

const ConnectedMilestoneCreateContainer = connect(mapStateToProps, mapDispatchToProps)(MilestoneCreateContainer);
export default ConnectedMilestoneCreateContainer;
