import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ViewColumn from '@mui/icons-material/ViewColumn';

export const ColumnChooserButton = ({ onToggle, getMessage, buttonRef, active, ...restProps }: any) => (
  <Tooltip title={getMessage('showColumnChooser')} placement="bottom" enterDelay={300}>
    <IconButton onClick={onToggle} buttonRef={buttonRef} {...restProps}>
      <ViewColumn />
    </IconButton>
  </Tooltip>
);
