import React from 'react';
import intl from 'react-intl-universal';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertAction } from '../../thunks/Alerts';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Value from '../../common/Value';
import Typography from '@mui/material/Typography';
import { selectComments } from '../../ducks/Cards';
import { fetchComments } from '../../thunks/Cards';
import { DateTimeFormatter } from '../../common/DateFormatter';
import { actions } from '../../ducks/Cards';
import ReactHtmlParser from 'react-html-parser';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useParams } from 'react-router-dom';

const useStyles = () => ({
  caption: {
    display: 'inline-block',
    fontWeight: 'bold',
  },
  value: {
    display: 'inline-block',
  },
});

const mapStateToProps = () =>
  createStructuredSelector({
    comments: selectComments(),
  });

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators({ fetchComments, ...actions }, dispatch),
});

const DetailedCardRow = (props: any) => {
  const { row, columns, comments, rowToInfo, actions } = props;
  const params = useParams();
  const classes: any = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const newComments = comments.filter((comment) => row.id === comment.cardId);

  React.useEffect(() => {
    actions.cleanCommentForCardId({
      cardId: row.id,
    });
    handleFetchComments({
      boardId: params.boardId,
      cardId: row.id,
    });

    return () => {
      actions.cleanCommentForCardId({
        cardId: row.id,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.id, params.boardId, actions]);

  const handleFetchComments = ({ boardId, cardId }) => {
    actions
      .fetchComments({
        boardId,
        cardId,
      })
      .then((action) =>
        alertAction({
          action,
          error: intl.get('common.comments.fetch.error'),
        }),
      );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const commentColumns = [];

  // Add comment column
  commentColumns.push({
    id: 'content',
    label: intl.get('board.views.list.columns.comment'),
    type: 'string',
    minWidth: 170,
    width: 620,
    format: (comment) => ReactHtmlParser(comment.content),
  });

  commentColumns.push({
    id: 'modified',
    label: intl.get('question.list.comment.posted_by'),
    type: 'string',
    minWidth: 50,
    format: (comment) => {
      return intl.get('question.list.comment.posted_by.and.on', {
        name: comment.modified_by.name,
        date: DateTimeFormatter({ value: comment.modified }),
      });
    },
  });

  const columnNamesMap = columns.reduce((map, column) => {
    map[column.name] =
      column.name === 'commentCount'
        ? intl.get('board.views.list.columns.comment')
        : column.name === 'childCount'
          ? intl.get('board.views.list.columns.attachment')
          : column.title;
    return map;
  }, {});

  return (
    <Card>
      <CardContent>
        {Object.entries(rowToInfo(row)).map((entry, index) =>
          entry[0].startsWith('phentry:richText') ? (
            <React.Fragment key={`item-${index}`}>
              <Typography sx={classes.caption}>
                {columnNamesMap[entry[0]]}
                :&nbsp;
              </Typography>
              <Typography sx={classes.value} dangerouslySetInnerHTML={{ __html: String(entry[1]) }} />
            </React.Fragment>
          ) : (
            <Value
              key={`item-${index}`}
              highReadability={false}
              caption={columnNamesMap[entry[0]]}
              value={String(entry[1])}
            />
          ),
        )}
      </CardContent>
      <Paper sx={{ width: '100%', overflow: 'hidden' }} style={{ display: newComments.length > 0 ? 'block' : 'none' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {commentColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newComments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((comment) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {commentColumns.map((column) => {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format(comment)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={newComments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{ display: newComments.length > 5 ? 'block' : 'none' }}
        />
      </Paper>
    </Card>
  );
};

const ConnectedDetailedCardRow = connect(mapStateToProps, mapDispatchToProps)(DetailedCardRow);

export default ConnectedDetailedCardRow;
