import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Immutable from 'seamless-immutable';
import { TextField } from '@mui/material';

export const renderField = (
  { name, type, label, placeholder, value, disabled, handleChange, handleBlur, error, ...other }: any,  
) => (
  <TextField
    key={name}
    error={error ? true : false}
    type={type ? type : undefined}
    helperText={error}
    disabled={disabled}
    name={name}
    label={label}
    placeholder={placeholder}
    value={value || ''}
    onChange={handleChange}
    onBlur={handleBlur}
    variant='standard'
    {...other}
  />
);

export const renderSelectField = (
  {
    style,
    name,
    label,
    disabled,
    handleChange,
    handleBlur,
    error,
    options,
    className,
    itemStyle,
    value,
    multiple,
    ...other
  }: any,  
) => (
  <FormControl style={style} key={name} sx={className} variant='standard'>
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <Select
      error={!!error}
      disabled={disabled}
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
      value={multiple ? Immutable(value).asMutable() : value}
      multiple={!!multiple}
      // todo: remove it when position problem will be fixed
      // https://github.com/mui-org/material-ui/issues/19245
      MenuProps={{
        getContentAnchorEl: () => null,
      }}
      {...other}
    >
      {options.map((option) => {
         
        /**
         * If passing in options as objects, must match this pattern:
         * {
         *  id: 'actual value',
         *  label: 'The property label' // can be skipped
         * }
         */
        if (option instanceof Object) {
          return (
            <MenuItem sx={itemStyle} key={option.id} value={option.id}>
              {option.label || option.name || option.id}
            </MenuItem>
          );
        }
        return (
          <MenuItem sx={itemStyle} key={option} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
    {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

export const renderSwitchField = (
  { name, value, label, disabled, handleChange, handleBlur, ...other },  
) => (
  <FormControlLabel
    key={name}
    control={
      <Switch
        name={name}
        checked={value}
        disabled={disabled}
        size="small"
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        {...other}
      />
    }
    label={label}
  />
);

// Compare two strings according to the current browser language settings.
export const compareStrings = (a, b) => (typeof a === 'string' ? a.localeCompare(b) : 1);

// Compare two autonumbers by converting them to numbers.
export const compareAutonumbers = (a, b) => {
  a = parseInt(a);
  b = parseInt(b);

  return Math.min(Math.max(a - b, -1), 1);
};

export const compareDates = (a, b) => {
  // Convert empty or null values to be considered greater than any non-empty value
  if (!a || a.trim() === '') {
    return 1;
  }

  if (!b || b.trim() === '') {
    return -1;
  }

  const dateA = new Date(a);
  const dateB = new Date(b);

  if (isNaN(dateA) || isNaN(dateB)) {
    return 0;
  }

  if (dateA < dateB) {
    return -1;
  } else if (dateA > dateB) {
    return 1;
  } else {
    return 0;
  }
};

// Create custom sorters for all columns that contain strings. The default sorting algorithm does not sort Scandinavian
// characters correctly, even if the browser is set to the correct language. It is also case insensitive (that is, it
// sorts "Z" before "a").
//
// Create custom sorters for autonumber columns. These are strings, but need to be sorted as numbers.
export const getCustomSorters = (columns) => {
  return columns.reduce((sorters, column) => {
    if (column.type === 'string') {
      sorters.push({
        columnName: column.name,
        compare: compareStrings,
      });
    }
    if (column.type === 'auto-number') {
      sorters.push({
        columnName: column.name,
        compare: compareAutonumbers,
      });
    }
    if (column.type === 'date') {
      sorters.push({
        columnName: column.name,
        compare: compareDates,
      });
    }
    return sorters;
  }, []);
};
