import Fab from '@mui/material/Fab';
import IconCreate from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

const AddCardButton = ({ onClick, title }: { onClick: () => void; title: string }) => {
  return (
    <Tooltip title={title}>
      <Fab id={'add-card'} size="small" style={{ marginRight: 20 }} color="primary" onClick={onClick}>
        <IconCreate />
      </Fab>
    </Tooltip>
  );
};

export default AddCardButton;
