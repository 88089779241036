import intl from 'react-intl-universal';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { renderSelectField } from '../../utils/RenderUtil';
import { filterOnNoValue, isAnyFilterAvailable, getFieldFilterId } from '../../utils/FilterUtil';
import { Field } from '@/models/card/CardModel';

const useStyles = () => ({
  divider: {
    marginTop: '10px',
  },
  menuList: {
    outline: 'none',
  },
  resetFilter: {
    marginLeft: '24px',
    height: '32px',
  },
  field: {
    marginLeft: '16px',
    marginRight: '16px',
    paddingBottom: '3px',
    height: '48px',
  },
});

const FieldFilters = ({ viewConfig, fields, type, title, resetTitle, onChange }: { viewConfig: any; fields: Field[]; type: string; title: string; resetTitle: string; onChange: (any) => void }) => {
  const classes = useStyles();
  const handleFilterChange = (event: any) => {
    onChange(viewConfig.setIn(['field_filters', type, event.target.name], event.target.value));
  };

  const handleResetFilter = () => {
    onChange(viewConfig.setIn(['field_filters', type], null));
  };

  const listField = ({ field, viewConfig, style, type }: { field: Field; viewConfig: any; style: any; type: string }) => {
    const fieldFilterId = getFieldFilterId(field);
    const options = field.options || field.limited_to || [];

    return [
      renderSelectField({
        style,
        name: fieldFilterId,
        label: field.name,
        value: (viewConfig.field_filters[type] && viewConfig.field_filters[type][fieldFilterId]) || [],
        handleChange: handleFilterChange,
        options: [
          ...options,
          {
            id: filterOnNoValue,
            label: intl.get('board.toolbar.filters.no_value'),
          }
        ],
        multiple: true,
      }),
    ];
  };

  return (
    <div style={{ outline: 'none' }}>
      <Divider sx={classes.divider} />
      <ListSubheader sx={classes.menuList}>
        {title}
        <Button
          sx={classes.resetFilter}
          size="small"
          variant="outlined"
          disabled={!isAnyFilterAvailable(viewConfig.field_filters[type])}
          onClick={handleResetFilter}
        >
          {resetTitle}
        </Button>
      </ListSubheader>
      {fields.map((field: Field) => (
        <MenuItem key={field.name} sx={classes.field}>
          {listField({
            style: { width: '250px' },
            field,
            viewConfig,
            type,
          })}
        </MenuItem>
      ))}
    </div>
  );
};

export default FieldFilters;
