import intl from 'react-intl-universal';
import AccordionDetails from '@mui/material/AccordionDetails';
import { default as Table } from '../../common/table';
import Immutable from 'seamless-immutable';
import DeleteIconButton from '../../common/DeleteIconButton';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box } from '@mui/material';

const useStyles = () => ({
  mobile: {
    flexDirection: 'column',
  },
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
});

const DeleteButton = ({ onExecute }: { onExecute: () => void }) => (
  <DeleteIconButton tooltipText={intl.get('common.attachments.delete')} onClick={onExecute} />
);

const AttachmentsMobile = ({
  item,
  attachments,
  formatAttachmentLink,
  commitChanges,
}: {
  item: any;
  attachments: any;
  formatAttachmentLink: any;
  commitChanges: any;
}) => {
  const classes: any = useStyles();
  // Note that the attachments table must be mutable; otherwise, IE 11 will display an empty table.
  return (
    <AccordionDetails sx={classes.mobile}>
      <Table
        rows={Immutable(attachments || []).asMutable()}
        columns={[
          {
            name: 'name',
            title: intl.get('common.attachments.columns.name'),
          },
        ]}
        editing={{
          stateConfig: {
            onCommitChanges: commitChanges,
          },
          config: {
            width: 120,
            showDeleteCommand: item.permissions.DELETE_ATTACHMENT,
            commandComponent: DeleteButton,
          },
        }}
        providers={[<DataTypeProvider key={'linkProvider'} for={['name']} formatterComponent={formatAttachmentLink} />]}
        tableConfig={{
          messages: {
            noData: <Box sx={classes.noData}>{intl.get('common.content.empty')}</Box>,
          },
        }}
      />
    </AccordionDetails>
  );
};

export default AttachmentsMobile;
