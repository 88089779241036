import React from 'react';
import intl from 'react-intl-universal';

import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

import AddCardButton from './AddCardButton';
import { Theme, useTheme } from '@mui/material';
import { Board } from '@/models/BoardModel';

const useStyles = (theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

const CreateCardMenu = ({
  board,
  onCreateQuestion,
  onCreateMilestone,
  onCreateDelivery,
}: {
  board: Board;
  onCreateQuestion: () => void;
  onCreateMilestone: (props: any) => void;
  onCreateDelivery: () => void;
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const withMilestonesOrDeliveries = board.delivery_config || board.milestones_config.length > 0;
  const cascading = withMilestonesOrDeliveries && board.milestones_config.length > 1;

  return (
    <div>
      <AddCardButton
        onClick={withMilestonesOrDeliveries ? handleClick : onCreateQuestion}
        title={intl.get('board.tooltips.add_card')}
      />
      {withMilestonesOrDeliveries && (
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem id={'create-question-item'} key={'create-question-item'} onClick={onCreateQuestion}>
            {intl.get('board.tooltips.question')}
          </MenuItem>
          {board.milestones_config.length > 0 && (
            <div>
              <MenuItem
                id={'create-milestone-item'}
                key={'create-milestone-item'}
                autoFocus={false}
                style={cascading ? { opacity: 1 } : {}}
                onClick={
                  cascading
                    ? null
                    : () =>
                        onCreateMilestone({
                          milestoneSet: board.milestones_config[0],
                        })
                }
                disabled={cascading ? true : !board.milestones_config[0].permissions.ADD_MILESTONE}
              >
                {intl.get('board.tooltips.milestone')}
              </MenuItem>
              {cascading && (
                <MenuList component="div" disablePadding>
                  {board.milestones_config.map((milestoneSet) => (
                    <MenuItem
                      sx={classes.nested}
                      id={'create-milestone-set-item-' + milestoneSet.name}
                      key={'create-milestone-set-item-' + milestoneSet.name}
                      disabled={!milestoneSet.permissions.ADD_MILESTONE}
                      onClick={() => onCreateMilestone({ milestoneSet })}
                    >
                      {milestoneSet.name}
                    </MenuItem>
                  ))}
                </MenuList>
              )}
            </div>
          )}
          {board.delivery_config && (
            <MenuItem
              id={'create-delivery-item'}
              key={'create-delivery-item'}
              onClick={onCreateDelivery}
              disabled={!board.delivery_config.permissions.ADD_DELIVERY}
            >
              {intl.get('board.tooltips.delivery')}
            </MenuItem>
          )}
        </Menu>
      )}
    </div>
  );
};

export default CreateCardMenu;
