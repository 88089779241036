import { Box, useTheme } from '@mui/material';
import Value from '../../common/Value';
import Tooltip from '@mui/material/Tooltip';

const useStyles = (theme: any) => ({
  lightTooltip: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
  },
  container: {
    marginRight: '8px',
    width: '300px',
    overflow: 'hidden',
  },
});

const CardItemTooltip = ({ viewConfig, map, children }: { viewConfig: any; map: any; children: any }) => {
  const theme = useTheme();
  const classes: any = useStyles(theme);

  return (
    <Tooltip
      title={
        <Box component="div" sx={classes.container}>
          {map.map((item: any) => (
            <Value
              key={'tooltip-item-' + item.key}
              highReadability={viewConfig.highReadability}
              caption={item.key}
              value={item.type === 'rich-text' ? <div dangerouslySetInnerHTML={{ __html: item.value }} /> : item.value}
            />
          ))}
        </Box>
      }
      classes={{ tooltip: classes.lightTooltip }}
    >
      {children}
    </Tooltip>
  );
};

export default CardItemTooltip;
