import intl from 'react-intl-universal';
import React from 'react';
import { Box, Tooltip } from '@mui/material';
import Value from '../../common/Value';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  lightTooltip: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
  },
  ppcContainer: {
    width: '100%',
    position: 'relative',
    height: 15,
  },
  progressBar: {
    position: 'relative',
    background: '#fd0e0e',
    height: 3,
    borderRadius: 3,
    top: 9,
    width: '75%',
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 3,
    height: 3,
    background: '#309269',
    display: 'inline-block',
  },
  percent: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '25%',
    textAlign: 'center',
  },
  placeHolder: {
    height: 15,
  },
  title: {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 8,
  },
  titleHighReadability: {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 8,
  },
});

const PPCProgress = ({ viewConfig, ppc, dateFrom, dateTo, title, style }: any) => {
  const theme = useTheme();
  const classes: any = useStyles(theme);

  if (!ppc.total) return <Box sx={classes.placeHolder} style={style} />;

  const progress = ppc.total ? (ppc.green / ppc.total) * 100 : 0;

  return (
    <Tooltip
      title={
        <React.Fragment>
          <Typography sx={viewConfig.highReadability ? classes.titleHighReadability : classes.title}>
            {title}
          </Typography>
          <Value
            highReadability={viewConfig.highReadability}
            caption={intl.get('board.tooltips.completed')}
            value={`${ppc.green}`}
          />
          <Value
            highReadability={viewConfig.highReadability}
            caption={intl.get('board.tooltips.remaining')}
            value={`${ppc.total - ppc.green}`}
          />
          <Value
            highReadability={viewConfig.highReadability}
            caption={intl.get('board.tooltips.total')}
            value={`${ppc.total}`}
          />
          <Value
            highReadability={viewConfig.highReadability}
            caption={intl.get('board.toolbar.date.from.tooltip')}
            value={`${dateFrom}`}
          />
          <Value
            highReadability={viewConfig.highReadability}
            caption={intl.get('board.toolbar.date.to.tooltip')}
            value={`${dateTo}`}
          />
        </React.Fragment>
      }
      classes={{ tooltip: classes.lightTooltip }}
      style={style}
    >
      <Box sx={classes.ppcContainer}>
        <Box sx={classes.progressBar}>
          <Box
            sx={classes.progress}
            style={{
              width: progress + '%',
            }}
          />
        </Box>
        <Box sx={classes.percent}>{progress.toFixed(0)}%</Box>
      </Box>
    </Tooltip>
  );
};

export default PPCProgress;
