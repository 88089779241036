import React from 'react';
import Menu from '@mui/material/Menu';

import intl from 'react-intl-universal';

import IconButton from '@mui/material/IconButton';
import FromToDatePicker from './FromToDatePicker';
import { addYears } from 'date-fns';
import { getNoonOfDay } from '../utils/DateUtil';
import { actions as viewActions } from '../ducks/Cards';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import CalendarIcon from '@mui/icons-material/DateRange';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';

const useStyles = () => ({
  ppcCalendar: {
    marginRight: '20px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      setViewConfig: viewActions.setViewConfig,
    },
    dispatch,
  ),
});

const PPCPeriodPicker = ({ viewConfig, actions }: any) => {
  const classes: any = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleConfigChange = (viewConfig: any) => {
    actions.setViewConfig(viewConfig);
  };

  const handleDateChange = (field: any, value: any) => {
    handleConfigChange(viewConfig.set('ppc_' + field, getNoonOfDay(value)));
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Tooltip title={intl.get('board.toolbar.ppc.change_period')}>
        <IconButton size="small" sx={classes.ppcCalendar} color="primary" onClick={handleClick}>
          <CalendarIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} aria-labelledby="form-dialog-title">
        <ListSubheader style={{ outline: 'none' }}>{intl.get('board.toolbar.ppc.change_period')}</ListSubheader>
        <MenuItem>
          <FromToDatePicker
            minDateFrom={addYears(Date.now(), -5)}
            maxDateFrom={viewConfig.ppc_dateTo}
            valueFrom={viewConfig.ppc_dateFrom}
            minDateTo={viewConfig.ppc_dateFrom}
            maxDateTo={addYears(Date.now(), 5)}
            valueTo={viewConfig.ppc_dateTo}
            handleChange={handleDateChange}
          />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

const ConnectedPPCPeriodPicker = connect(null, mapDispatchToProps)(PPCPeriodPicker);

export default ConnectedPPCPeriodPicker;
