import { getBeginAndEndOfDay, getBeginAndEndOfWeek } from './DateUtil';
import { prepareDeliveryCardsForWeekBoard } from './DeliveryUtil';
import { prepareQuestionCardsForWeekBoard } from './QuestionUtil';
import { prepareMilestoneCardsForWeekBoard } from './MilestoneUtil';
import { ANSWERED_ROW } from './Constants';

export const filterMilestonesToShow = (milestones, dateFrom, dateTo, col) => {
  return milestones.filter(
    (milestone) =>
      !milestone.date ||
      (dateFrom <= milestone.date && milestone.date <= dateTo) ||
      (!milestone.metDate && !col.weekNumber),
  );
};

export const getEmptyStructure = (config) => {
  const structure = config.xColumns.map(() => {
    return config.yColumns.map(() => {
      return {
        data: [],
      };
    });
  });
  structure[ANSWERED_ROW] = [];
  return structure;
};

export const getColumnTimeIntervals = (config) => {
  return config.yColumns.slice(2).map((column) => {
    const dateLimits = column.dayColumn ? getBeginAndEndOfDay(column.date) : getBeginAndEndOfWeek(column.date);
    return {
      fromDate: dateLimits.begin,
      toDate: dateLimits.end,
    };
  });
};

export const enrichCellsWithCards = (weekBoardStructure, cards, rowIds, columnLimits) => {
  cards.forEach((card) => {
    if (!card.disciplines) {
      return;
    }

    const matchingRows = card.disciplines.map((from) => rowIds.indexOf(from.id)).filter((index) => index >= 0);

    // no due date
    if (!card.dueDate) {
      matchingRows.forEach((row) => {
        weekBoardStructure[row][0].data.push(card);
      });
      return;
    }

    // overdue
    if (card.isOverdue) {
      matchingRows.forEach((row) => {
        weekBoardStructure[row][1].data.push(card);
      });
    }

    // lookup for correct column
    const columnIndex = columnLimits.findIndex(
      (dateLimit) => dateLimit.fromDate <= card.dueDate && card.dueDate <= dateLimit.toDate,
    );
    if (columnIndex >= 0) {
      matchingRows.forEach((row) => {
        // shift two columns: no_due_date and overdue
        weekBoardStructure[row][columnIndex + 2].data.push(card);
      });
    }

    if (card.isAnswered) {
      weekBoardStructure[ANSWERED_ROW].push(card);
    }
  });
};

export const getWeekBoardStructure = (board, viewConfig, deliveries, cards, milestones, config) => {
  let deliveryDTOs;
  if (!board.delivery_config || !viewConfig.showDeliveries) {
    deliveryDTOs = [];
  } else {
    deliveryDTOs = prepareDeliveryCardsForWeekBoard(deliveries, board, viewConfig);
  }

  let milestoneDTOs;
  if (milestones) {
    const milestonesConfigs = board.milestones_config;
    milestoneDTOs = prepareMilestoneCardsForWeekBoard(milestones, milestonesConfigs, viewConfig);
  } else {
    milestoneDTOs = [];
  }

  let questionDTOs;
  if (viewConfig.showQuestions) {
    questionDTOs = prepareQuestionCardsForWeekBoard(cards, board, viewConfig);
  } else {
    questionDTOs = [];
  }

  const rowIds = config.xColumns.map((row) => row.id);
  const weekBoardStructure = getEmptyStructure(config);
  const columnTimeIntervals = getColumnTimeIntervals(config);

  enrichCellsWithCards(
    weekBoardStructure,
    deliveryDTOs.concat(questionDTOs).concat(milestoneDTOs),
    rowIds,
    columnTimeIntervals,
  );
  return weekBoardStructure;
};
