import { client } from './API';

export const getStatus = ({ boardId, exportId }: { boardId: string, exportId: string }): Promise<any> => client.get(`/v1/boards/${boardId}/exports/${exportId}`);

export const download = ({ boardId, exportId, targetFileName }: { boardId: string, exportId: string, targetFileName: string }): Promise<Blob> => {
  const config = {
    responseType: 'blob', // important
    params: { targetFileName },
  };

  return client.get(`/v1/boards/${boardId}/exports/${exportId}/download`, config as any);
};

export const exportContent = ({ boardId, exportRequest }: { boardId: string, exportRequest: any }): Promise<Blob> => {
  return client
    .post(`/v1/boards/${boardId}/exports`, exportRequest)
    .then((response) => exportIterate(response, boardId, exportRequest.file_name));
};

const exportIterate = (response: any, boardId: string, targetFileName: string): Promise<Blob> => {
  const exportId = response.data.id;
  if (response.data.status !== 'DONE') {
    return new Promise((resolve) => {
      setTimeout(() => {
        return getStatus({ boardId, exportId }).then((statusResponse) =>
          resolve(exportIterate(statusResponse, boardId, targetFileName)),
        );
      }, 5000);
    });
  } else {
    return download({ boardId, exportId, targetFileName });
  }
};
