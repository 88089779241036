import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import IconInfo from '@mui/icons-material/InfoOutlined';
import IconEdit from '@mui/icons-material/EditOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import { URLSearchParams } from '../../utils/UrlUtil';

import * as cardActionThunks from '../../thunks/Cards';
import { fetchRoomMembers } from '../../thunks/Boards';
import ListBoard from './ListBoard';
import {
  selectCards,
  selectIsLoadingCards,
  selectLastActiveCard,
  selectListViewConfig,
  actions as cardActions,
} from '../../ducks/Cards';
import { actions as boardActions } from '../../ducks/Boards';
import { selectActiveBoard, selectRoomMembers } from '../../ducks/Boards';
import { LIST_BOARD_VIEW_MODE } from '../../utils/ViewModeUtil';
import {
  questionFieldsToColumns,
  STEP_COLUMN,
  generateNumberOfCardsMessage,
  COMMENT_COLUMN,
  MARGIN_COLUMN,
  CONCLUDED_COLUMN,
} from '../../utils/ListViewUtil';
import PageWrapper from '../../common/PageWrapper';
import { alertAction } from '../../thunks/Alerts';
import {
  getCellComponentRenderer,
  itemToRow,
  toPrimitiveFieldValue,
  getCardInfo,
  getEntryTitle,
} from '../../utils/FieldUtil';
import { canEditQuestion } from '../../utils/PermissionUtils';
import { Cell } from '../../common/table';
import ToggleIconButton from '../../common/ToggleIconButton';
import { exportContent } from '../../services/Export';
import saveAs from 'file-saver';
import { generateExportRequest } from '../../utils/ExportUtil';
import { Board } from '@/models/BoardModel';
import { Card } from '@/models/card/CardModel';

let searchTerm = '';

const mapStateToProps = () =>
  createStructuredSelector({
    activeBoard: selectActiveBoard(),
    isLoadingCards: selectIsLoadingCards(),
    cards: selectCards(),
    lastActiveCard: selectLastActiveCard(),
    roomMembers: selectRoomMembers(),
    listViewConfig: selectListViewConfig(),
  });

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...cardActionThunks, ...cardActions, ...boardActions, fetchRoomMembers }, dispatch),
});

const ListBoardContainer = ({ activeBoard, cards, lastActiveCard, isLoadingCards, roomMembers, listViewConfig, actions }: {
  activeBoard: Board;
  cards: Card[];
  lastActiveCard: Card;
  isLoadingCards: boolean;
  roomMembers: RoomMember[];
  listViewConfig: any;
  actions: any;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [state, setState] = useState({
    filters: [],
    viewConfig: listViewConfig,
  });

  useEffect(() => {
    const { boardId } = params;
    actions.fetchCards({ boardId });
    if (roomMembers.length < 1 || activeBoard.id.toString() !== boardId) {
      actions.fetchRoomMembers({ boardId });
    }

    searchTerm = localStorage.getItem('searchTerm') || '';
    setState((prev) => ({
      ...prev,
      filters: JSON.parse(localStorage.getItem('filters') || '[]') || [],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      const { viewConfig } = state;
      if (viewConfig) {
        actions.setListViewConfig(viewConfig);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewConfig, actions]);

  const handleSearch = (searchTerm: string) => {
    localStorage.setItem('searchTerm', searchTerm);
  };

  const handleFilter = (filters) => {
    localStorage.setItem('filters', JSON.stringify(filters));
    setState((prev) => ({
      ...prev,
      filters: filters,
    }));
  };

  const handleCardEditClick = (id) => {
    const step = new URLSearchParams(location.search).get('step');
    actions.setLastActiveCard({ lastActiveCard: id });

    return step
      ? navigate(`/${params.boardId}/cards/${id}?view=${LIST_BOARD_VIEW_MODE}&step=${step}`)
      : navigate(`/${params.boardId}/cards/${id}?view=${LIST_BOARD_VIEW_MODE}`);
  };

  const handleCardCreateClick = () => navigate(`/${params.boardId}/create-card?view=${LIST_BOARD_VIEW_MODE}`);

  const handleCardLockClick = (id) => {
    return actions
      .lockCard({
        boardId: params.boardId,
        cardId: id,
      })
      .then((action) => {
        alertAction({
          action,
          error: intl.get('card.lock_unlock.error'),
          success: intl.get('card.lock_unlock.success'),
        });
      });
  };

  const handleCardUnlockClick = (id) => {
    return actions
      .unlockCard({
        boardId: params.boardId,
        cardId: id,
      })
      .then((action) => {
        alertAction({
          action,
          error: intl.get('card.lock_unlock.error'),
          success: intl.get('card.lock_unlock.success'),
        });
      });
  };

  const handleExportClick =
    ({ folderName, isDecisionLog }) =>
    (config) => {
      const columnsToExport = config.columns.filter(
        (column) =>
          column.name !== COMMENT_COLUMN &&
          column.name !== MARGIN_COLUMN &&
          (isDecisionLog || column.name !== CONCLUDED_COLUMN),
      );

      const exportRequest = generateExportRequest({
        columns: columnsToExport,
        items: config.items,
        includeComments: config.includeComments,
      });

      return exportContent({
        boardId: activeBoard.id,
        exportRequest,
      }).then((response) => Promise.resolve(saveAs(response.data, `${folderName}.csv`)));
    };

  const handleSortChange = (sorter) => {
    setState((prev) => ({
      ...prev,
      viewConfig: { ...prev.viewConfig, sorter },
    }));
  };

  const handleColumnOrderChange = (columnOrder) => {
    setState((prev) => ({
      ...prev,
      viewConfig: { ...prev.viewConfig, columnOrder },
    }));
  };

  const handleColumnVisibilityChange = (visibleColumns) => {
    setState((prev) => ({
      ...prev,
      viewConfig: { ...prev.viewConfig, visibleColumns },
    }));
  };

  const rowToCardInfo = (row) => {
    const cardInfo = row.fields.reduce((questionInfo, field) => {
      questionInfo[field.id] = toPrimitiveFieldValue(field);
      return questionInfo;
    }, {});
    // Add step name and meta info to cardInfo
    return cardInfo.merge({
      [STEP_COLUMN]: row[STEP_COLUMN],
      ...getCardInfo(row, roomMembers),
    });
  };

  const cardsToRows = (cards, step) => {
    const filteredCards = step ? cards.filter((card) => card.step.id === step) : cards;

    return filteredCards.asMutable().map((card) =>
      itemToRow({
        item: card,
        decision_log_step_id: activeBoard.step_config.decision_log_step_id,
        fieldConfig: activeBoard.field_config,
      }),
    );
  };

  const renderCardLinkButton = ({ row }) => {
    const canEdit = canEditQuestion(row.permissions);

    return (
      <Cell>
        <ToggleIconButton
          tooltipText={intl.get(
            canEdit ? 'board.views.list.tooltips.edit_question' : 'board.views.list.tooltips.view_question',
          )}
          generalIcon={canEdit ? <IconEdit /> : <IconInfo />}
          hoverIcon={canEdit ? <IconEdit /> : <IconInfo />}
          disabled={false}
          onClick={() => handleCardEditClick(row.id)}
        />
        <ToggleIconButton
          tooltipText={row.locked ? intl.get('card.unlock') : intl.get('card.lock')}
          generalIcon={row.locked ? <Lock /> : <LockOpen />}
          hoverIcon={row.locked ? <LockOpen /> : <Lock />}
          disabled={row.locked ? !row.permissions.UNLOCK : !row.permissions.LOCK}
          onClick={() => (row.locked ? handleCardUnlockClick(row.id) : handleCardLockClick(row.id))}
        />
      </Cell>
    );
  };

  if (isLoadingCards) {
    return <LinearProgress />;
  }

  const step = new URLSearchParams(location.search).get('step');
  const isDecisionLog = step && step === activeBoard.step_config.decision_log_step_id;

  return (
    <PageWrapper title={intl.get(isDecisionLog ? 'app_bar.decision_log' : 'app_bar.question_list_view')}>
      <ListBoard
        board={activeBoard}
        lastActiveItem={lastActiveCard}
        items={cardsToRows(cards, step)}
        height={window.innerHeight - 255}
        columns={questionFieldsToColumns(activeBoard.field_config)}
        renderCellComponent={getCellComponentRenderer(activeBoard.field_config.fields, roomMembers)}
        editing={{ renderLinkButtons: renderCardLinkButton, width: 100 }}
        rowToInfo={rowToCardInfo}
        readOnly={isDecisionLog ? !activeBoard.permissions.FULL_CONTROL : false}
        onItemCreateClick={handleCardCreateClick}
        tooltips={{ create: intl.get('board.tooltips.add_card') }}
        filters={state.filters}
        onFiltersChange={handleFilter}
        onSearch={handleSearch}
        onSortChange={handleSortChange}
        onColumnOrderChange={handleColumnOrderChange}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        searchTerm={searchTerm}
        selection={{
          enableSelection: true,
          helperText: intl.get('board.views.list.tooltips.cards.export.helper_text'),
          numberOfItemsMessage: generateNumberOfCardsMessage,
        }}
        sorter={state.viewConfig.sorter}
        defaultSorter={[{ columnName: getEntryTitle(activeBoard.field_config.fields) }]}
        columnOrder={state.viewConfig.columnOrder}
        visibleColumns={state.viewConfig.visibleColumns}
        onExportClick={handleExportClick({
          folderName: activeBoard.workflow_config.workflow_name,
          isDecisionLog,
        })}
      />
    </PageWrapper>
  );
};

const ConnectedListBoardContainer = connect(mapStateToProps, mapDispatchToProps)(ListBoardContainer);

export default ConnectedListBoardContainer;
