import MuiToolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import IconCreate from '@mui/icons-material/Add';
import IconFilterOn from '@mui/icons-material/FilterList';
import IconFilterOff from '@mui/icons-material/Close';
import ButtonWithProgress from '../../common/ButtonWithProgress';

const Toolbar = ({
  readOnly,
  filtering,
  handleItemCreateClick,
  handleFilteringToggle,
  tooltips,
  title,
  subscription,
  handleExportClick,
  children,
}: {
  readOnly: boolean;
  filtering: boolean;
  handleItemCreateClick: () => void;
  handleFilteringToggle: () => void;
  tooltips: {
    create: string;
    remove_filtering: string;
    add_filtering: string;
    export: string;
  };
  title: string;
  subscription: React.ReactNode;
  handleExportClick: () => void;
  children: React.ReactNode;
}) => {
  return (
    <MuiToolbar>
      {!readOnly && (
        <Tooltip title={tooltips?.create}>
          <Fab size="small" color="primary" onClick={handleItemCreateClick}>
            <IconCreate />
          </Fab>
        </Tooltip>
      )}
      {title}
      {children}
      <Tooltip title={filtering ? tooltips?.remove_filtering : tooltips?.add_filtering}>
        <IconButton onClick={handleFilteringToggle}>{filtering ? <IconFilterOff /> : <IconFilterOn />}</IconButton>
      </Tooltip>
      {subscription}
      <ButtonWithProgress buttonText={tooltips?.export} tooltipText={tooltips?.export} handleClick={handleExportClick} />
    </MuiToolbar>
  );
};

export default Toolbar;
