import { useEffect, useCallback } from 'react';
import { Action, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import LinearProgress from '@mui/material/LinearProgress';

import { alertAction } from '../../thunks/Alerts';
import { selectActiveBoard, selectIsLoadingActiveBoard } from '../../ducks/Boards';
import * as boardsActions from '../../thunks/Boards';
import * as milestonesActions from '../../thunks/Milestones';
import Error from '../../common/Error';

import { MilestoneCreateContainer as MilestoneCreate, MilestoneUpdateContainer as MilestoneUpdate } from '.';
import { actions as roomActions, selectActiveRoom } from '../../ducks/Rooms';
import { actions as communityActions, selectActiveCommunity } from '../../ducks/Communities';
import {
  selectIsLoadingMilestones,
  selectIsRequestedMilestones,
  selectIsLoadingPossibleResponsible,
  selectIsRequestedPossibleResponsible,
  selectPossibleResponsible,
} from '../../ducks/Milestones';
import { hasResponsibleField } from '../../utils/FieldUtil';
import { memoize, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { Board } from '@/models/BoardModel';

const mapStateToProps = () =>
  createStructuredSelector({
    board: selectActiveBoard(),
    activeRoom: selectActiveRoom(),
    activeCommunity: selectActiveCommunity(),
    isLoadingBoard: selectIsLoadingActiveBoard(),
    isLoadingMilestones: selectIsLoadingMilestones(),
    isRequestedMilestones: selectIsRequestedMilestones(),
    possibleResponsible: selectPossibleResponsible(),
    isLoadingPossibleResponsible: selectIsLoadingPossibleResponsible(),
    isRequestedPossibleResponsible: selectIsRequestedPossibleResponsible(),
  });

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...milestonesActions,
      ...boardsActions,
      setActiveRoom: roomActions.setActiveRoom,
      setActiveCommunity: communityActions.setActiveCommunity,
    },
    dispatch,
  ),
});

const MilestoneContainer = ({
  board,
  activeCommunity,
  activeRoom,
  isLoadingBoard,
  isLoadingMilestones,
  isRequestedMilestones,
  isLoadingPossibleResponsible,
  isRequestedPossibleResponsible,
  actions,
}: any) => {
  const params = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMilestoneSet = useCallback(
    memoize((board: Board, milestoneFolderId: string) =>
      board.milestones_config.find((config: any) => config.id === milestoneFolderId),
    ),
    [],
  );

  const handleFetchActiveBoard = useCallback(
    ({ boardId }: { boardId?: string  }) => {
      actions.fetchActiveBoard({ boardId }).then((action: any) => {
        const workflowConfig = action.payload.activeBoard.workflow_config;

        if (activeCommunity !== workflowConfig.community_id) {
          actions.setActiveCommunity({
            communityId: workflowConfig.community_id,
          });
        }

        if (activeRoom !== workflowConfig.room_id) {
          actions.setActiveRoom({ roomId: workflowConfig.room_id });
        }
      });
    },
    [actions, activeCommunity, activeRoom],
  );

  const handleFetchMilestones = useCallback(
    ({ boardId }: { boardId?: string }) => {
      actions
        .fetchMilestones({ boardId })
        .then((action: any) => alertAction({ action, error: intl.get('milestone.fetch.error') }));
    },
    [actions],
  );

  const handleFetchPossibleResponsible = useCallback(() => {
    const milestoneConfig = getMilestoneSet(board, params.milestoneFolderId);

    if (milestoneConfig) {
      const shouldLoadPossibleResponsible =
        hasResponsibleField({ fieldConfig: milestoneConfig }) &&
        isRequestedPossibleResponsible.boardId !== board.id &&
        !isLoadingPossibleResponsible;

      if (shouldLoadPossibleResponsible) {
        actions
          .fetchPossibleResponsible({ boardId: board.id })
          .then((action: Action) => alertAction({ action, error: intl.get('card.responsible.error') }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board, isLoadingPossibleResponsible, isRequestedPossibleResponsible, actions, params.milestoneFolderId]);

  useEffect(() => {
    const { boardId } = params;

    if (!board) {
      handleFetchActiveBoard({ boardId });
    }

    if (!isRequestedMilestones || !isLoadingMilestones) {
      handleFetchMilestones({ boardId });
    }

    if (board) {
      handleFetchPossibleResponsible();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (board) {
      handleFetchPossibleResponsible();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board]);

  if (isLoadingBoard || isLoadingMilestones || !isRequestedMilestones) {
    return <LinearProgress />;
  }

  if (!board) {
    return <Error text={intl.get('board.not_found')} />;
  }

  const milestoneConfig = getMilestoneSet(board, params.milestoneFolderId);

  if (
    hasResponsibleField({ fieldConfig: milestoneConfig }) &&
    (isLoadingPossibleResponsible || isEmpty(isRequestedPossibleResponsible))
  ) {
    return <LinearProgress />;
  }

  if (params.milestoneId) {
    return <MilestoneUpdate board={board} params={params} />;
  }

  return <MilestoneCreate board={board} params={params} />;
};

const ConnectedMilestoneContainer = connect(mapStateToProps, mapDispatchToProps)(MilestoneContainer);
export default ConnectedMilestoneContainer;
