import React from 'react';
import intl from 'react-intl-universal';
import BoardItem from './BoardItem';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Board } from '@/models/BoardModel';
import { Room } from '@/models/RoomModel';

const useStyles = () => ({
  gridItem: {
    flexBasis: 'auto',
  },
});

const Boards = ({ boards, rooms, roomId }: { boards: Board[]; rooms: Room[]; roomId: string; }) => {
  const classes = useStyles();
  const getContent = () => {
    let room;

    if (roomId) {
      room = rooms.filter((room: Room) => room.id === roomId)[0];
    }

    if (!roomId || !room) {
      return <Typography variant="body1">{intl.get('boards.select_any')}</Typography>;
    }
    if (boards.length === 0) {
      return <Typography variant="body1">{intl.get('boards.not_found')}</Typography>;
    }

    return (
      <Accordion defaultExpanded key={`boards-item-${room.id}`}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{room.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {boards.map((board: Board) => (
              <Grid sx={classes.gridItem} key={`board-${board.id}`} item xs>
                <BoardItem board={board} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  return <React.Fragment>{getContent()}</React.Fragment>;
};

export default Boards;
