// @flow

import { Theme, useTheme } from '@mui/material/styles';
import classNames from 'classnames';

import { Link as NextLink } from 'react-router-dom';

const useStyles = (theme: Theme) => ({
  root: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  variantDefault: {
    color: 'inherit',
  },
  variantPrimary: {
    color: theme.palette.primary.main,
  },
  variantAccent: {
    color: theme.palette.secondary.main,
  },
  variantButton: {
    '&:hover': {
      textDecoration: 'inherit',
    },
  },
});

function Link(props: any) {
  const { activeClassName, children: childrenProp, className: classNameProp, href, prefetch } = props;

  const theme = useTheme();
  const classes: any = useStyles(theme);

  const className = classNames(classes.root, classNameProp);

  const children = childrenProp;

  const rootProps = {
    href,
    prefetch,
    passhref: 'true',
  };
  const active = window.location.pathname + window.location.search === href;
  return (
    <NextLink
      to={href}
      className={classNames(className, {
        [activeClassName]: active && activeClassName,
      })}
      {...rootProps}
    >
      {children}
    </NextLink>
  );
}

export default Link;
