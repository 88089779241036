import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = () => ({
  icon: {
    padding: '8px',
  },
});

const DeleteIconButton = ({ tooltipText, onClick, disabled }: any) => {
  const classes: any = useStyles();

  return !disabled ? (
    <Tooltip title={tooltipText}>
      <IconButton sx={classes.icon} onClick={onClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  ) : (
    <span />
  );
};

export default DeleteIconButton;
