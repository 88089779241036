import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import { Community } from '@/models/CommunityModel';

// Constants

export const types = {
  //FETCH COMMUNITIES
  FETCH_COMMUNITIES_REQUEST: 'FETCH_COMMUNITIES_REQUEST',
  FETCH_COMMUNITIES_SUCCESS: 'FETCH_COMMUNITIES_SUCCESS',
  FETCH_COMMUNITIES_ERROR: 'FETCH_COMMUNITIES_ERROR',

  // SET ACTIVE COMMUNITY
  SET_ACTIVE_COMMUNITY: 'SET_ACTIVE_COMMUNITY',
};

const initialState = Immutable({
  communities: [],
  communityId: '',
  isLoadingCommunities: false,
  wasRequested: false,
});

// Reducer

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case types.FETCH_COMMUNITIES_REQUEST: {
      return state.merge({
        isLoadingCommunities: true,
        wasRequested: true,
      });
    }
    case types.FETCH_COMMUNITIES_SUCCESS: {
      return state.merge({
        communities: action.payload.communities,
        isLoadingCommunities: false,
      });
    }
    case types.FETCH_COMMUNITIES_ERROR: {
      return state.merge({
        isLoadingCommunities: false,
      });
    }
    case types.SET_ACTIVE_COMMUNITY: {
      return state.merge({
        communityId: action.payload.communityId,
      });
    }
    default:
      return state;
  }
}

// Actions

export const actions = {
  fetchCommunitiesRequest: () => ({
    type: types.FETCH_COMMUNITIES_REQUEST,
  }),
  fetchCommunitiesSuccess: ({ communities }: { communities: Community[] }) => ({
    type: types.FETCH_COMMUNITIES_SUCCESS,
    payload: {
      communities,
    },
  }),
  fetchCommunitiesError: ({ error }: { error: any }) => ({
    type: types.FETCH_COMMUNITIES_ERROR,
    payload: {
      error,
    },
  }),
  setActiveCommunity: ({ communityId }: { communityId: string }) => ({
    type: types.SET_ACTIVE_COMMUNITY,
    payload: {
      communityId,
    },
  }),
};

// Selectors
const communitiesSelector = () => (state: any) => state.communities;

export const selectCommunities = () =>
  createSelector(communitiesSelector(), (communitiesState: any) => communitiesState.communities);

export const selectActiveCommunity = () =>
  createSelector(communitiesSelector(), (communitiesState: any) => communitiesState.communityId);

export const selectIsLoadingCommunities = () =>
  createSelector(communitiesSelector(), (communitiesState: any) => communitiesState.isLoadingCommunities);

export const selectWasRequested = () =>
  createSelector(communitiesSelector(), (communitiesState: any) => communitiesState.wasRequested);
