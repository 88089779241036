import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Note';
import amber from '@mui/material/colors/amber';
import { Box } from '@mui/material';

const useStyles = () => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px',
  },
  iconButton: {
    paddingTop: '15px',
  },
  iconBadge: {
    top: '0px',
    right: '-2px',
    transform: 'scale(1)',
  },
  iconBadgeHighReadability: {
    top: '0px',
    right: '-2px',
    transform: 'scale(1.5)',
  },
  icon: {
    color: amber[500],
    width: '48px',
    height: '48px',
  },
  iconHighReadability: {
    color: amber[500],
    width: '64px',
    height: '64px',
  },
});

const CardFolderClosed = ({ viewConfig, cardCount, onExpand }: { viewConfig: { highReadability: boolean}; cardCount: number; onExpand: () => void }) => {
  const classes = useStyles();

  return (
    <Box component="div" sx={classes.center}>
      {cardCount > 0 && (
        <IconButton onClick={onExpand} sx={classes.iconButton}>
          <Badge
            sx={{
              badge: viewConfig.highReadability ? classes.iconBadgeHighReadability : classes.iconBadge,
            }}
            badgeContent={cardCount}
            color="primary"
          >
            <MailIcon
              sx={{
                ...(viewConfig.highReadability ? classes.iconHighReadability : classes.icon),
                marginRight: '-5px',
                marginTop: '-10px',
              }}
            />
          </Badge>
        </IconButton>
      )}
    </Box>
  );
};
export default CardFolderClosed;
