import React from 'react';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Theme, useTheme } from '@mui/material';
import { Board } from '@/models/BoardModel';
import { Card } from '@/models/card/CardModel';
import { Step as StepType } from '@/models/folder/StepModel';
import Step from './Step';

const useStyles = (theme: Theme) => ({
  root: {
    width: '300px',
    height: 'calc(100% - 90px)',
    overflow: 'auto',
    padding: '20px',
    paddingTop: '70px',
    backgroundColor: '#fafafa',
  },
  steps: {
    padding: '20px',
  },
  drawer: {
    width: '340px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: '340px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '50px',
  },
  toolbar: {
    marginTop: '75px',
  },
});

const Steps = ({
  board,
  cards,
  steps,
  viewConfig,
  open,
  onSidebarClick,
  onCardClick,
  onMoveCardToStep,
  onChange,
}: {
  board: Board; 
  cards: Card[];
  steps: StepType[];
  viewConfig: any;
  open: boolean;
  onSidebarClick: () => void;
  onCardClick: () => void;
  onMoveCardToStep: () => void;
  onChange: () => void;
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleExpandStep = (key: any) => {
    onChange(viewConfig.set('expandedSteps', viewConfig.expandedSteps.concat(key)));
  };

  const handleCollapseStep = (key: any) => {
    onChange(
      viewConfig.set(
        'expandedSteps',
        viewConfig.expandedSteps.filter((item: string) => item !== key),
      ),
    );
  };

  /**
   * Renders a step with content.
   * If step is a final step, only render the count.
   */
  const stepRenderer = ({ board, step, cards, viewConfig }: { board: Board; step: StepType; cards: Card[]; viewConfig: any }) => (
    <Step
      key={`step-${step.id}`}
      cards={cards}
      step={step}
      board={board}
      viewConfig={viewConfig}
      expanded={viewConfig.expandedSteps.indexOf(step.id) >= 0}
      onCardClick={onCardClick}
      onMoveCardToStep={onMoveCardToStep}
      onExpand={handleExpandStep}
      onCollapse={handleCollapseStep}
    />
  );

  const stepsRenderer = ({
    classes,
    steps,
    board,
    cards,
    viewConfig,
  }: {
    classes: any;
    steps: StepType[];
    board: Board;
    cards: Card[];
    viewConfig: any;
  }) => (
    <Box sx={classes.steps}>
      {steps.map((step: StepType) =>
        stepRenderer({
          board,
          step,
          cards: cards.filter((card: Card) => card.step.id === step.id),
          viewConfig,
        }),
      )}
    </Box>
  );

  return (
    <React.Fragment>
      {/* Desktop steps as a right side bar */}
      <Drawer
        sx={{
          display: { xs: 'none', sm: 'block' },
          ...classes.drawer,
        }}
        PaperProps={{
          sx: {
            ...classes.drawer,
            ...(open && classes.drawerOpen),
            ...(!open && classes.drawerClose),
          },
        }}
        variant="permanent"
        anchor="right"
        open={true}
      >
        <Box sx={classes.toolbar}>
          <IconButton onClick={onSidebarClick}>{open ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </Box>
        {open &&
          stepsRenderer({
            classes,
            board,
            steps,
            cards,
            viewConfig,
          })}
      </Drawer>
      {/* Mobile steps as a simple card */}
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        {stepsRenderer({
            classes,
            board,
            steps,
            cards,
            viewConfig,
          })}
      </Box>
    </React.Fragment>
  );
};

export default Steps;
