import intl from 'react-intl-universal';
import AccordionDetails from '@mui/material/AccordionDetails';
import LinearProgress from '@mui/material/LinearProgress';
import { default as Table } from '../../common/table';
import Dropzone from 'react-dropzone';
import Immutable from 'seamless-immutable';

import DeleteIconButton from '../../common/DeleteIconButton';
import { DateFormatter } from '../../common/DateFormatter';
import { getIcon } from '../../utils/IconMappingUtil';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box } from '@mui/material';

const useStyles = () => ({
  attachmentColumn: {
    flexBasis: '100%',
  },
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
});

const DeleteButton = ({ onExecute }) => (
  <DeleteIconButton tooltipText={intl.get('common.attachments.delete')} onClick={onExecute} />
);

const AttachmentsDesktop = ({
  attachments = [],
  members = [],
  item,
  isUploading = false,
  isDeleting,
  formatAttachmentLink = () => {},
  commitChanges = () => {},
  onUploadAttachment = () => {},
}) => {
  const classes: any = useStyles();
  return (
    <AccordionDetails>
      <Box sx={classes.attachmentColumn}>
        {(isUploading || isDeleting) && <LinearProgress />}
        <Dropzone
          style={{ backgroundImage: '' }}
          multiple
          disableClick
          disablePreview
          disabled={!item.permissions.ADD_ATTACHMENT}
          onDrop={onUploadAttachment}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Table
                rows={Immutable(attachments || []).asMutable()}
                columns={[
                  {
                    name: 'icon',
                    title: ' ',
                    width: 50,
                    getCellValue: (file: any) => getIcon(file),
                  },
                  {
                    name: 'name',
                    title: intl.get('common.attachments.columns.name'),
                  },
                  {
                    name: 'created',
                    title: intl.get('common.attachments.columns.created'),
                  },
                  {
                    name: 'created_by',
                    title: intl.get('common.attachments.columns.created_by'),
                    getCellValue: (file: any) => {
                      const user = members.find((m) => m.id === file.created_by.id);
                      if (user) {
                        return user.name;
                      }
                      return file.created_by.id;
                    },
                  },
                ]}
                editing={{
                  stateConfig: {
                    onCommitChanges: commitChanges,
                  },
                  config: {
                    width: 120,
                    showDeleteCommand: item.permissions.DELETE_ATTACHMENT,
                    commandComponent: DeleteButton,
                  },
                }}
                providers={[
                  <DataTypeProvider
                    key={'dateProvider'}
                    for={['created']}
                    formatterComponent={({ value }) => <DateFormatter value={value} />}
                  />,
                  <DataTypeProvider key={'linkProvider'} for={['name']} formatterComponent={formatAttachmentLink} />,
                ]}
                tableConfig={{
                  messages: {
                    noData: <Box sx={classes.noData}>{intl.get('common.content.empty')}</Box>,
                  },
                }}
              />
            </div>
          )}
        </Dropzone>
      </Box>
    </AccordionDetails>
  );
};

export default AttachmentsDesktop;
