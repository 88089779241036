import { Board } from "@/models/BoardModel";
import { Card, Option } from "@/models/card/CardModel";

export const isOverdueColumn = (columnIndex: number) => columnIndex === 1;
export const isNoDueDateColumn = (columnIndex: number) => columnIndex === 0;
export const isMilestoneRow = (rowIndex: number) => rowIndex === 0;


const sameCell = ({ columnIndex, rowIndex, sourceColumnIndex, sourceRowIndex }: { columnIndex: number; rowIndex: number; sourceColumnIndex: number; sourceRowIndex: number }) =>
  columnIndex === sourceColumnIndex && rowIndex === sourceRowIndex;

const moveOnlyVertically = ({ columnIndex, rowIndex, sourceColumnIndex, sourceRowIndex }: { columnIndex: number; rowIndex: number; sourceColumnIndex: number; sourceRowIndex: number }) =>
  rowIndex !== sourceRowIndex && columnIndex === sourceColumnIndex;

const dateFieldWillBeChanged = ({ columnIndex, sourceColumnIndex, card }: { columnIndex: number; sourceColumnIndex: number; card: Card }) =>
  columnIndex !== sourceColumnIndex || (card.date && card.date.getDay() !== 5);

export const canDropQuestionOnCell = ({
  card,
  board,
  columnIndex,
  rowIndex,
  sourceColumnIndex,
  sourceRowIndex,
  config,
}: {
  card: Card;
  board: Board;
  columnIndex: number;
  rowIndex: number;
  sourceColumnIndex: number;
  sourceRowIndex: number;
  config: any;
}) => {
  const isInitialStep = board.step_config.initial_step_id === card.step.id;
  const toWillBeChanged = rowIndex !== sourceRowIndex;
  const intoSameCell = sameCell({
    columnIndex,
    rowIndex,
    sourceColumnIndex,
    sourceRowIndex,
  });
  const dateWillBeChanged = dateFieldWillBeChanged({
    columnIndex,
    sourceColumnIndex,
    card,
  });

  const moveVerticallyInOverdue =
    isOverdueColumn(columnIndex) &&
    moveOnlyVertically({
      columnIndex,
      rowIndex,
      sourceColumnIndex,
      sourceRowIndex,
    });
  const toMemberFieldConfig = board.field_config.fields.find(
    (field) => field.id === board.field_config.to_member_field,
  );
  const toMemberField = card.fields.find((field) => field.id === board.field_config.to_member_field);
  if (toMemberFieldConfig.multiple && !toMemberField.value.find((item) => item.id === config.xColumns[rowIndex].id)) {
    return false;
  }

  const canDropOnCellFromDifferentStep =
    !isInitialStep && card.permissions.MOVE[board.step_config.initial_step_id] && card.permissions.ASK;

  return (
    // in case to will be changed we should have ASK permissions
    // if d&ding from different step we should have permission to move to initial step
    canDropOnCellFromDifferentStep || // in case date will be changed we should have ASK permissions
    (!intoSameCell && // no sense to move to the same cell
      isInitialStep &&
      !isMilestoneRow(rowIndex) && // can't move question to milestone row
      (!isOverdueColumn(columnIndex) || moveVerticallyInOverdue) && // in Overdue column we can move only vertically
      (!dateWillBeChanged || card.permissions.ASK) &&
      (!toWillBeChanged || card.permissions.ASK))
  );
};

export const canDropDeliveryOnCell = ({
  card,
  board,
  columnIndex,
  rowIndex,
  sourceColumnIndex,
  sourceRowIndex,
  config,
}: {
  card: Card;
  board: Board;
  columnIndex: number;
  rowIndex: number;
  sourceColumnIndex: number;
  sourceRowIndex: number;
  config: any;
}) => {
  const fromWillBeChanged = rowIndex !== sourceRowIndex;
  const intoSameCell = sameCell({
    columnIndex,
    rowIndex,
    sourceColumnIndex,
    sourceRowIndex,
  });
  const moveVerticallyInOverdue =
    isOverdueColumn(columnIndex) &&
    moveOnlyVertically({
      columnIndex,
      rowIndex,
      sourceColumnIndex,
      sourceRowIndex,
    });
  const dateWillBeChanged = dateFieldWillBeChanged({
    columnIndex,
    sourceColumnIndex,
    card,
  });
  const isMemberOfNewFromGroup =
    board.delivery_config.allowed_from_authorities.filter((group: Option) => group.id === config.xColumns[rowIndex].id).length >
    0;

  return (
    // in case from will be changed we should be a member of new group
    // in case date will be changed we should have UPDATE permissions
    !intoSameCell && // if drop to the same cell then no sense to update
    !isMilestoneRow(rowIndex) && // can't move delivery to milestone row
    (!isOverdueColumn(columnIndex) || moveVerticallyInOverdue) && // in Overdue column we can move only vertically
    (!dateWillBeChanged || card.permissions.UPDATE) &&
    (!fromWillBeChanged || (isMemberOfNewFromGroup && card.permissions.UPDATE))
  );
};

export const canDropMilestoneOnCell = ({ card, columnIndex, rowIndex, sourceColumnIndex, sourceRowIndex }: { card: Card; columnIndex: number; rowIndex: number; sourceColumnIndex: number; sourceRowIndex: number }) => {
  const intoSameCell = sameCell({
    columnIndex,
    rowIndex,
    sourceColumnIndex,
    sourceRowIndex,
  });
  return (
    // to perform d&d we should have UPDATE permissions;
    // we can't drop milestone to Overdue column
    !intoSameCell && // if drop to the same cell then no sense to update
    isMilestoneRow(rowIndex) && // can move milestone card only to milestone row
    !isOverdueColumn(columnIndex) &&
    card.permissions.UPDATE
  );
};
