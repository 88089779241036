import React from 'react';
import intl from 'react-intl-universal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { default as Table } from '../../common/table';
import Immutable from 'seamless-immutable';
import IconFilterOn from '@mui/icons-material/FilterList';
import IconFilterOff from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { DateTimeFormatter } from '../../common/DateFormatter';
import StopClickPropagation from '../../common/StopClickPropagation';
import { parseISO } from 'date-fns';
import { getCustomSorters } from '../../utils/RenderUtil';
import { ENVIRONMENTAL_VARIABLES } from '../../utils/Environment';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box } from '@mui/material';

const useStyles = () => ({
  heading: {
    paddingRight: '10px',
  },
  column: {
    flexBasis: '33.33%',
  },
  logColumn: {
    flexBasis: '100%',
  },
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
  flexGrow: {
    flex: '1 1 auto',
  },
});

const Log = ({ log, members, item }: { log: any; members: any; item: any }) => {
  const classes = useStyles();
  const [filter, setFilter] = React.useState(false);

  const handleFilteringToggle = () => setFilter(!filter);

  const getAuditActionEntryMovedContent = (row: any) => {
    if (row.value.type === 'date') {
      return intl.get('audit.action.entry.moved', {
        old_value: row.value.old_value ? parseISO(row.value.old_value) : '',
        new_value: row.value.new_value ? parseISO(row.value.new_value) : '',
      });
    }
    return intl.get('audit.action.entry.moved', {
      old_value: row.value[0].old_value,
      new_value: row.value[0].new_value,
    });
  };

  const getAuditActionCommentUpdatedContent = (row: any) => {
    const oldValueLabel = intl.get('audit.action.update.comment.old');
    const newValueLabel = intl.get('audit.action.update.comment.new');
    return oldValueLabel + row.value[0].old_value + newValueLabel + row.value[0].new_value;
  };

  const getAuditActionCommentMentionedEmailSent = (row: any) => {
    return intl.get('audit.action.comment.mentioned.email.sent.value') + row.value;
  };

  const columns = [
    {
      name: 'type',
      title: intl.get('common.log.columns.action'),
      getCellValue: (log: any) => intl.get(log.type),
    },
    {
      name: 'created',
      title: intl.get('common.attachments.columns.created'),
    },
    {
      name: 'user',
      title: intl.get('common.log.columns.user'),
      getCellValue: (logInfo: any) => {
        const user = members.find((m: any) => m.id === logInfo.user.id);
        return user ? user.name : logInfo.user.id;
      },
    },
  ];

  return (
    <Accordion disabled={!item.id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ paddingRight: '40px', display: 'flex', width: '100%' }}>
          <Box sx={classes.column}>
            <Badge color="primary" badgeContent={log.length}>
              <Typography sx={classes.heading}>{intl.get('common.log')}</Typography>
            </Badge>
          </Box>
          <Box sx={classes.flexGrow} />
          <StopClickPropagation
            style={{
              position: 'relative',
              paddingRight: 0,
            }}
          >
            <Tooltip
              title={
                filter
                  ? intl.get('board.views.list.tooltips.remove_filtering')
                  : intl.get('board.views.list.tooltips.add_filtering')
              }
            >
              <IconButton onClick={handleFilteringToggle}>{filter ? <IconFilterOff /> : <IconFilterOn />}</IconButton>
            </Tooltip>
          </StopClickPropagation>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={classes.logColumn}>
          <Table
            rows={Immutable(log || []).asMutable()}
            columns={columns}
            filtering={
              filter && {
                rowConfig: {
                  messages: {
                    filterPlaceholder: intl.get('board.views.list.placeholders.filter'),
                  },
                },
                // TODO: Create custom filter, so we can filter on the number of comments and attachments, instead of disabling filtering.
                stateConfig: {},
                config: {
                  columnExtensions: [
                    {
                      columnName: 'created',
                      predicate: (value, filter) => {
                        const filterValue = DateTimeFormatter({ value });
                        return filterValue.indexOf(filter.value) !== -1;
                      },
                    },
                  ],
                },
              }
            }
            sorting={{
              config: {
                columnExtensions: getCustomSorters(columns),
              },
            }}
            tableHeaderRowConfig={{
              messages: {
                sortingHint: intl.get('board.views.list.tooltips.sort'),
              },
            }}
            rowDetailing={{
              config: {
                contentComponent: ({ row }) => {
                  let content = '';
                  let versionKey = '';
                  let dateKey = '';
                  switch (row.type) {
                    case 'AUDIT_ACTION_ADDED_FILE':
                      return row.value.name;
                    case 'AUDIT_ACTION_FILE_NEW_VERSION':
                      content = intl.get('audit.action.new.version', {
                        name: row.value.name,
                        previous: row.value.previous_version,
                        version: row.value.new_version,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_FILE_COPIED':
                      content = intl.get('audit.action.move', {
                        from: row.value.path,
                        name: row.value.name,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_PROPERTY_CHANGED':
                    case 'AUDIT_ACTION_CREATE':
                      row.value.forEach((action) => {
                        let value = action.new_value;

                        if (action.type === 'date') {
                          value = DateTimeFormatter({ value });
                        } else if (action?.type === 'member' || action?.type === 'taskResponsible') {
                          value = action?.new_value
                            ?.map((user) => {
                              const member = members.find((m) => m.id === user.id);
                              return member ? member.name : user.id;
                            })
                            .join(', ');
                        } else if (action.type === 'taskDone') {
                          value = action.new_value === 'true' ? intl.get('common.yes') : intl.get('common.no');
                        }
                        /** Rich text needs special care because intl.get returns it in wrong format. */
                        if (action.type === 'richText') {
                          content += `<b>${intl.get('audit.action.changed.field')} </b> ${
                            action.name
                          } <b>${intl.get('audit.action.changed.new_value')} </b>${action.new_value} <br />`;
                        } else if (action.type === 'date' && action.new_value) {
                          content += intl.get('audit.action.changed.date', {
                            field: action.name,
                            date: parseISO(action.new_value),
                          });
                        } else {
                          content += intl.get('audit.action.changed.property', {
                            field: action.name,
                            value,
                          });
                        }
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_DELETE':
                      return row.value;
                    case 'AUDIT_ACTION_RENAME':
                    case 'AUDIT_ACTION_EDITED_LINK':
                      content = row.value.map((action) => {
                        return intl.get('audit.action.old.new.value', {
                          ...action,
                        });
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_ADDED_LINK':
                      return row.value;
                    case 'AUDIT_ACTION_MOVED_LINK_FROM':
                    case 'AUDIT_ACTION_FILE_MOVED_FROM':
                      content = intl.get('audit.action.moved.from', {
                        name: row.value.name,
                        path: row.value.path,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_MOVED_LINK_TO':
                    case 'AUDIT_ACTION_FILE_MOVED_TO':
                      content = intl.get('audit.action.moved.to', {
                        name: row.value.name,
                        path: row.value.path,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_LINK_COPIED':
                      content = intl.get('audit.action.copied', {
                        name: row.value.name,
                        path: row.value.path,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_DELETE_COMMENT':
                      return <div dangerouslySetInnerHTML={{ __html: row.value }} />;
                    case 'AUDIT_ACTION_ADD_COMMENT':
                      return <div dangerouslySetInnerHTML={{ __html: row.value }} />;
                    case 'AUDIT_ACTION_UPDATE_COMMENT':
                      content = getAuditActionCommentUpdatedContent(row);
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_ENTRY_MOVED':
                      content = getAuditActionEntryMovedContent(row);
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_COMMENT_MENTIONED_EMAIL_SENT':
                      content = getAuditActionCommentMentionedEmailSent(row);
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_EMAIL_SENT':
                      return row.value;
                    case 'AUDIT_ACTION_ENTRY_COPIED':
                      content = intl.get('audit.action.entry.copied', {
                        path: row.value.path,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_CREATE_PUBLIC_LINK':
                    case 'AUDIT_ACTION_DELETE_PUBLIC_LINK':
                      content = intl.get('audit.action.public.link', {
                        name: row.value.name,
                        link: ENVIRONMENTAL_VARIABLES.INTERAXO_URL + '/prosjekthotell/public/?' + row.value.id,
                      });
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    case 'AUDIT_ACTION_SHARE':
                      return row.value.receivers
                        .map((receiver) => {
                          const user = members.find((m) => m.id === receiver.id);

                          return user ? user.name : row.user.id;
                        })
                        .join(', ');
                    case 'AUDIT_ACTION_REVISE':
                      versionKey = Object.keys(row.value.version)[0];
                      dateKey = Object.keys(row.value.date)[0];
                      content =
                        versionKey +
                        ': ' +
                        row.value.version[versionKey] +
                        '<br />' +
                        dateKey +
                        ': ' +
                        row.value.date[dateKey];
                      return <div dangerouslySetInnerHTML={{ __html: content }} />;
                    default:
                      return '';
                  }
                },
              },
            }}
            providers={[
              <DataTypeProvider
                key={'dateTimeProvider'}
                for={['created']}
                formatterComponent={({ value }) => <DateTimeFormatter value={value} />}
              />,
            ]}
            tableConfig={{
              messages: {
                noData: <Box sx={classes.noData}>{intl.get('common.content.empty')}</Box>,
              },
            }}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Log;
