import BoardIcon from '@mui/icons-material/GridOn';
import intl from 'react-intl-universal';
import ListView from '@mui/icons-material/List';
import WeekIcon from '@mui/icons-material/DateRange';
import DecisionLog from '@mui/icons-material/PlaylistAddCheck';
import DeliveriesList from '@mui/icons-material/LocalShippingOutlined';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import interaxa_X from '../assets/img/INTERAXO_X.png';
import interaxo_logo_blue from '../assets/img/interaxo-logo-full-color-rgb.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import BoardsIcon from '@mui/icons-material/Public';
import MuiDrawer from '@mui/material/Drawer';
import NavLink from './NavLink';
import {
  WEEK_BOARD_VIEW_MODE,
  LIST_BOARD_VIEW_MODE,
  LIST_MILESTONE_VIEW_MODE,
  LIST_DELIVERY_VIEW_MODE,
} from '../utils/ViewModeUtil';
import MilestonesList from './icons/OutlinedMilestoneIcon';
import { Theme, useTheme } from '@mui/material';
import { ENVIRONMENTAL_VARIABLES } from '@/utils/Environment';
import IconButton from '@mui/material/IconButton';
import { Box, Tooltip } from '@mui/material';

const useStyles = (theme: Theme) => ({
  button: {
    borderRadius: '0px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    position: 'fixed',
    height: '100%',
    width: '250px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: '45px',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  drawerInner: {
    width: '250px',
    height: '100%',
    paddingTop: '0px',
  },
  drawerInnerClose: {
    width: '45px',
    height: '100%',
    paddingTop: '0px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawerHeader: {
    backgroundColor: '#eafbfc',
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
  },
  contextItem: {
    marginLeft: '26px',
    marginTop: '20px',
    fontSize: '13px',
  },
  contextItemChild: {
    marginLeft: '50px',
    fontSize: '13px',
  },
  contextItemSeparator: {
    marginTop: '30px',
    marginLeft: '75px',
    fontSize: '13px',
  },
  sidebarToggler: {
    marginLeft: '25px',
  },
  interaxoIcon: {
    width: '18px',
    height: '20px',
    marginLeft: '13px',
    marginBottom: '5px',
  },
  openInteraxo: {
    width: '18px',
    height: '20px',
  },
  interaxoIconBig: {
    width: '120px',
    // 46px for margin-left is result of subtraction of 57px (needed for the content
    // fits the width of sidebar) and 11px (space between content and right border of sidebar)
    marginLeft: '46px',
    marginBottom: '5px',
  },
});

const Sidebar = ({ show, onMenuClick, activeBoard }: { show: boolean; onMenuClick: () => void; activeBoard: any }) => {
  const theme = useTheme();
  const classes: any = useStyles(theme);

  return (
    <MuiDrawer
      open={show}
      variant="permanent"
      sx={[
        {
          ...classes.drawerPaper,
        },
        !show && classes.drawerPaperClose,
      ]}
    >
      <List sx={show ? classes.drawerInner : classes.drawerInnerClose}>
        <Box component="div" sx={classes.drawerHeader}>
          <Box component="div" sx={classes.headerInner}>
            {!show && <Box component="img" sx={classes.interaxoIcon} src={interaxa_X} alt={'X'} />}
            {show && <Box component="img" sx={classes.interaxoIconBig} src={interaxo_logo_blue} alt={'Interaxo'} />}
            <Tooltip title={intl.get('app_bar.sidebar.close.tooltip')}>
              <IconButton sx={classes.sidebarToggler} onClick={onMenuClick}>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <NavLink icon={<BoardsIcon />} title={intl.get('app_bar.boards')} showText={show} href={`/`} />
        </Box>
        {activeBoard && [
          <NavLink
            key={'app_bar.tile_view'}
            icon={<BoardIcon />}
            title={intl.get('app_bar.tile_view')}
            href={`/${activeBoard.id}`}
            showText={show}
          />,
          <NavLink
            key={'app_bar.week_view'}
            icon={<WeekIcon />}
            title={intl.get('app_bar.week_view')}
            href={`/${activeBoard.id}?view=${WEEK_BOARD_VIEW_MODE}`}
            showText={show}
          />,
          <NavLink
            key={'app_bar.decision_log'}
            icon={<DecisionLog />}
            title={intl.get('app_bar.decision_log')}
            href={`/${activeBoard.id}?view=${LIST_BOARD_VIEW_MODE}&step=${activeBoard.step_config.decision_log_step_id}`}
            showText={show}
          />,
          <Divider key={'divider'} light />,
          activeBoard.milestones_config.length ? (
            [
              <NavLink
                key={'app_bar.milestone_list_view'}
                icon={<MilestonesList />}
                title={intl.get('app_bar.milestone_list_view')}
                href={`/${activeBoard.id}?view=${LIST_MILESTONE_VIEW_MODE}`}
                showText={show}
              />,
            ]
          ) : (
            <span key={'span1'} />
          ),
          <NavLink
            key={'app_bar.question_list_view'}
            icon={<ListView />}
            title={intl.get('app_bar.question_list_view')}
            href={`/${activeBoard.id}?view=${LIST_BOARD_VIEW_MODE}`}
            showText={show}
          />,
          activeBoard.delivery_config ? (
            [
              <NavLink
                key={'app_bar.delivery_list_view'}
                icon={<DeliveriesList />}
                title={intl.get('app_bar.delivery_list_view')}
                href={`/${activeBoard.id}?view=${LIST_DELIVERY_VIEW_MODE}`}
                showText={show}
              />,
            ]
          ) : (
            <span key={'span2'} />
          ),
          <NavLink
            icon={<img style={classes.openInteraxo} src={interaxa_X} alt={'X'} />}
            title={intl.get('app_bar.open_interaxo')}
            href={`${ENVIRONMENTAL_VARIABLES.INTERAXO_URL}/${activeBoard.workflow_config.community_id}/${activeBoard.workflow_config.room_id}/documentLibrary/${activeBoard.workflow_config.workflow_id}`}
            showText={show}
          />,
        ]}
      </List>
    </MuiDrawer>
  );
};
export default Sidebar;
