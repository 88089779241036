import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth0 } from '../auth0';

export const SessionExpirationWarning = (props) => {
  const { open, handleClose, title, message, yesText, noText } = props;

  const { logout, getTokenSilently } = useAuth0();

  const handleLogout = () => {
    logout();
  };

  const handleRenewSession = () => {
    getTokenSilently()
      .then(() => {
        handleClose();
      })
      .catch(() => handleLogout());
  };

  const originalTitle = document.title;
  let titleChangeInterval;
  let hidden;
  let visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  function handleVisibilityChange() {
    if (!document[hidden]) {
      clearInterval(titleChangeInterval);
      document.title = originalTitle;
    }
  }

  if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
    console.log('Does not support Page Visibility API');
  } else {
    // Handle page visibility change
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
    // Page change visibilty available, and page / tab is "hidden", i.e. not active so rotate the page
    // title every 2 second to draw attention to the page / tab.
    // This will then be cleared and original title restored as soon as the page / tab receives focus.
    if (document[hidden]) {
      titleChangeInterval = setInterval(() => {
        document.title = document.title === originalTitle ? title : originalTitle;
      }, 2000);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRenewSession} color="primary" autoFocus>
          {yesText}
        </Button>
        <Button onClick={handleLogout} color="secondary">
          {noText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const SessionExpirationMessage = (props) => {
  const { open, title, message, okText } = props;

  const handleClose = () => window.location.reload();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
