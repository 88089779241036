import intl from 'react-intl-universal';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import Tooltip from '@mui/material/Tooltip';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BoardCellConfig from './BoardCellConfig';
import BoardCellFilters from './BoardCellFilters';
import SubscriptionContainer from '../../common/SubscriptionContainer';
import FromToDatePicker from '../../common/FromToDatePicker';
import { subYears, addYears, format } from 'date-fns';
import PPCProgress from '../common/PPCProgressBar';
import PPCPeriodPicker from '../../common/PPCPeriodPicker';
import { getLocale } from '../../utils/LocaleUtil';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Board } from '@/models/BoardModel';

const useStyles = (theme: Theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    marginLeft: '25px',
  },
  flexGrow: {
    flex: '1 1 auto',
  },
  fixedWidthBlock: {
    width: '10px',
  },
  margin: {
    margin: theme.spacing(1),
  },
  icon: {
    fontSize: '20px',
    marginRight: theme.spacing(1),
  },
});

const locale = intl.getInitOptions().currentLocale;

const Toolbar = ({
  board,
  viewConfig,
  onChange,
  onCalculatePPC,
  onExpandAll,
  onCollapseAll,
  onExpandAllCells,
  onCollapseAllCells,
  expandedRows,
  expandedCells,
  subscription,
  possibleResponsible,
  viewName,
  renderCreateCardButton,
  onDateChange,
}: {
  board: Board;
  viewConfig: any;
  onChange: (viewConfig: any) => void;
  onCalculatePPC: (dateFrom: Date, dateTo: Date) => number;
  onExpandAll: () => void;
  onCollapseAll: () => void;
  onExpandAllCells: () => void;
  onCollapseAllCells: any;
  expandedRows: any;
  expandedCells: any;
  subscription: any;
  possibleResponsible: any;
  viewName: any;
  renderCreateCardButton: any;
  onDateChange: any;
}) => {
  const theme = useTheme();
  const classes: any = useStyles(theme);

  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleHighReadability = () => {
    onChange(
      viewConfig
        .set('highReadability', !viewConfig.highReadability)
        .set('stepsSidebarOpen', !viewConfig.highReadability ? false : viewConfig.stepsSidebarOpen),
    );
  };

  const handleClickPPCButton = () => {
    onChange(viewConfig.set('showPPC', !viewConfig.showPPC));
  };

  const showPPC = viewConfig.showPPC;
  const minDate = onDateChange && subYears(viewConfig.dateTo, 6);

  let toolbarWidth = 'calc(100% - 340px)';
  if (isSmallDown) {
    toolbarWidth = '100%';
  }

  return (
    <Box sx={{ ...classes.toolbar, width: toolbarWidth }}>
      {renderCreateCardButton()}
      {onDateChange && (
        <Hidden smDown>
          {!isSmallDown && <Box sx={classes.fixedWidthBlock} />}
          <FromToDatePicker
            minDateFrom={minDate}
            maxDateFrom={viewConfig.dateTo}
            valueFrom={viewConfig.dateFrom}
            minDateTo={viewConfig.dateFrom}
            maxDateTo={addYears(new Date(viewConfig.dateFrom), 6)}
            valueTo={viewConfig.dateTo}
            dateTo={viewConfig.dateTo}
            handleChange={onDateChange}
          />
        </Hidden>
      )}

      {onCalculatePPC && (
        <Tooltip title={intl.get('board.toolbar.ppc.calculate')} style={{ paddingLeft: 25 }}>
          <Button onClick={handleClickPPCButton}>
            {intl.get('board.toolbar.ppc.shorten')}
            {showPPC ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Tooltip>
      )}
      {onCalculatePPC && <PPCPeriodPicker viewConfig={viewConfig} />}
      {showPPC && onCalculatePPC && (
        <PPCProgress
          title={intl.get('board.toolbar.ppc.total')}
          viewConfig={viewConfig}
          style={{ width: '130px' }}
          ppc={onCalculatePPC(viewConfig.ppc_dateFrom, viewConfig.ppc_dateTo)}
          dateFrom={format(viewConfig.ppc_dateFrom, 'eee, dd MMM yyyy', {
            locale: getLocale(locale),
          })}
          dateTo={format(viewConfig.ppc_dateTo, 'eee, dd MMM yyyy', {
            locale: getLocale(locale),
          })}
        />
      )}
      <Box sx={classes.flexGrow} />
      <Hidden smDown>
        {onExpandAll && expandedRows.length === 0 && (
          <Tooltip title={intl.get('board.toolbar.expand.tooltip')}>
            <Button id={'expand-rows'} onClick={onExpandAll}>
              {intl.get('board.toolbar.expand')}
              <ExpandMore />
            </Button>
          </Tooltip>
        )}
        {onCollapseAll && expandedRows.length > 0 && (
          <Tooltip title={intl.get('board.toolbar.collapse.tooltip')}>
            <Button id={'collapse-rows'} onClick={onCollapseAll}>
              {intl.get('board.toolbar.collapse')}
              <ExpandLess />
            </Button>
          </Tooltip>
        )}
        {onExpandAllCells && expandedCells.length === 0 && (
          <Tooltip title={intl.get('board.toolbar.cells.expand.tooltip')}>
            <Button id={'expand-cells'} onClick={onExpandAllCells}>
              {intl.get('board.toolbar.cells.expand')}
              <ExpandMore />
            </Button>
          </Tooltip>
        )}
        {onCollapseAllCells && expandedCells.length > 0 && (
          <Tooltip title={intl.get('board.toolbar.cells.collapse.tooltip')}>
            <Button id={'collapse-cells'} onClick={onCollapseAllCells}>
              {intl.get('board.toolbar.cells.collapse')}
              <ExpandLess />
            </Button>
          </Tooltip>
        )}
        <Tooltip
          title={
            viewConfig.highReadability
              ? intl.get('board.toolbar.zoom.out.tooltip')
              : intl.get('board.toolbar.zoom.in.tooltip')
          }
        >
          <IconButton onClick={toggleHighReadability}>
            {viewConfig.highReadability ? <ZoomOut /> : <ZoomIn />}
          </IconButton>
        </Tooltip>
      </Hidden>
      <BoardCellFilters
        onChange={onChange}
        board={board}
        viewConfig={viewConfig}
        viewName={viewName}
        possibleResponsible={possibleResponsible}
      />
      {subscription && <SubscriptionContainer id={board.id} />}
      <BoardCellConfig onChange={onChange} board={board} viewConfig={viewConfig} viewName={viewName} />
    </Box>
  );
};

export default Toolbar;
