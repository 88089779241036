import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import green from '@mui/material/colors/green';
import { Box, SxProps, Theme, useTheme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  flexWrapper: {
    display: 'flex',
  },
  relativeWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  progress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
});

const SpinnerButton = ({
  spinner,
  disabled,
  children,
  variant,
  onClick,
  sx
}: {
  spinner: boolean;
  disabled: boolean;
  children: React.ReactNode;
  variant: 'outlined' | 'contained' | 'text';
  onClick: () => void;
  sx: SxProps<Theme>;
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box component="div" sx={classes.flexWrapper}>
      <Box component="div" sx={classes.relativeWrapper}>
        <Button disabled={spinner || disabled} variant={variant} sx={sx} onClick={onClick}>
          {children}
        </Button>
        {spinner && <CircularProgress size={24} sx={classes.progress} />}
      </Box>
    </Box>
  );
};

export default SpinnerButton;
