import intl from 'react-intl-universal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const useStyles = () => ({
  button: {
    marginLeft: '10px',
  },
});

const DeleteCardItemDialog = ({ onDelete, onClose, titles, disabled, ...other }: any) => {
  const classes: any = useStyles();

  return (
    <Dialog
      open={disabled}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': classes.dialog,
      }}
      {...other}
    >
      <DialogTitle>{titles.deleteDialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{titles.deleteDialogMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.get('common.dialog.cancel')}
        </Button>
        <Button onClick={onDelete} color="secondary">
          {intl.get('common.dialog.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCardItemDialog;
