import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import { find, map, partition } from 'lodash';
import { selectActiveBoard, types as boardsActionTypes } from './Boards';
import { DEFAULT_WEEKS_IN_VIEW, verifyName } from '../utils/FieldUtil';
import { addDays, addWeeks } from 'date-fns';
import { getNoonOfDay } from '../utils/DateUtil';
import { insertUpdatedItem } from '../utils/ReducerUtil';

import ListViewConfig from '@/models/ListViewConfig';
import { Card, CardAttachment, CardType } from '@/models/card/CardModel';
import { Comment } from '@/models/CommentModel';
import { StepConfig } from '@/models/folder/StepModel';
import { Board } from '@/models/BoardModel';

// Types
export const types = {
  // View config
  SET_VIEW_CONFIG: 'SET_VIEW_CONFIG',
  // get
  FETCH_CARDS_REQUEST: 'FETCH_CARDS_REQUEST',
  FETCH_CARDS_SUCCESS: 'FETCH_CARDS_SUCCESS',
  FETCH_CARDS_ERROR: 'FETCH_CARDS_ERROR',
  // update
  UPDATE_CARD_REQUEST: 'UPDATE_CARD_REQUEST',
  UPDATE_CARD_SUCCESS: 'UPDATE_CARD_SUCCESS',
  UPDATE_CARD_IMMEDIATELY_SUCCESS: 'UPDATE_CARD_IMMEDIATELY_SUCCESS',
  UPDATE_CARD_ERROR: 'UPDATE_CARD_ERROR',
  UPDATE_CARD_IMMEDIATELY_ERROR: 'UPDATE_CARD_IMMEDIATELY_ERROR',
  // create
  CREATE_CARD_REQUEST: 'CREATE_CARD_REQUEST',
  CREATE_CARD_SUCCESS: 'CREATE_CARD_SUCCESS',
  CREATE_CARD_ERROR: 'CREATE_CARD_ERROR',
  // lock
  LOCK_CARD_REQUEST: 'LOCK_CARD_REQUEST',
  LOCK_CARD_SUCCESS: 'LOCK_CARD_SUCCESS',
  LOCK_CARD_ERROR: 'LOCK_CARD_ERROR',
  // unlock
  UNLOCK_CARD_REQUEST: 'UNLOCK_CARD_REQUEST',
  UNLOCK_CARD_SUCCESS: 'UNLOCK_CARD_SUCCESS',
  UNLOCK_CARD_ERROR: 'UNLOCK_CARD_ERROR',
  // add attachment
  UPLOAD_ATTACHMENT_REQUEST: 'UPLOAD_ATTACHMENT_REQUEST',
  UPLOAD_ATTACHMENT_SUCCESS: 'UPLOAD_ATTACHMENT_SUCCESS',
  UPLOAD_ATTACHMENT_ERROR: 'UPLOAD_ATTACHMENT_ERROR',
  // add link
  ADD_LINK_REQUEST: 'ADD_LINK_REQUEST',
  ADD_LINK_SUCCESS: 'ADD_LINK_SUCCESS',
  ADD_LINK_ERROR: 'ADD_LINK_ERROR',
  // get attachments
  FETCH_ATTACHMENTS_REQUEST: 'FETCH_ATTACHMENTS_REQUEST',
  FETCH_ATTACHMENTS_SUCCESS: 'FETCH_ATTACHMENTS_SUCCESS',
  FETCH_ATTACHMENTS_ERROR: 'FETCH_ATTACHMENTS_ERROR',
  // delete Attachments
  DELETE_ATTACHMENTS_REQUEST: 'DELETE_ATTACHMENTS_REQUEST',
  DELETE_ATTACHMENTS_SUCCESS: 'DELETE_ATTACHMENTS_SUCCESS',
  DELETE_ATTACHMENTS_ERROR: 'DELETE_ATTACHMENTS_ERROR',
  // get comments
  FETCH_COMMENTS_REQUEST: 'FETCH_COMMENTS_REQUEST',
  FETCH_COMMENTS_SUCCESS: 'FETCH_COMMENTS_SUCCESS',
  FETCH_COMMENTS_ERROR: 'FETCH_COMMENTS_ERROR',
  // add comment
  CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
  CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
  CREATE_COMMENT_ERROR: 'CREATE_COMMENT_ERROR',
  // Update comment
  UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
  UPDATE_COMMENT_REQUEST_SUCCESS: 'UPDATE_COMMENT_REQUEST_SUCCESS',
  UPDATE_COMMENT_REQUEST_ERROR: 'UPDATE_COMMENT_REQUEST_ERROR',
  // Update comment
  DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
  DELETE_COMMENT_REQUEST_SUCCESS: 'DELETE_COMMENT_REQUEST_SUCCESS',
  DELETE_COMMENT_REQUEST_ERROR: 'DELETE_COMMENT_REQUEST_ERROR',
  // fetch log
  FETCH_LOG_REQUEST: 'FETCH_LOG_REQUEST',
  FETCH_LOG_SUCCESS: 'FETCH_LOG_SUCCESS',
  FETCH_LOG_ERROR: 'FETCH_LOG_ERROR',
  //
  //  FETCH POSSIBLE RESPONSIBLE PERSONS
  //
  FETCH_POSSIBLE_RESPONSIBLE_REQUEST: 'FETCH_POSSIBLE_RESPONSIBLE_REQUEST',
  FETCH_POSSIBLE_RESPONSIBLE_SUCCESS: 'FETCH_POSSIBLE_RESPONSIBLE_SUCCESS',
  FETCH_POSSIBLE_RESPONSIBLE_ERROR: 'FETCH_POSSIBLE_RESPONSIBLE_ERROR',
  //
  // LAST ACTIVE CARD
  //
  SET_LAST_ACTIVE_CARD: 'SET_LAST_ACTIVE_CARD',

  CLEAN_COMMENTS_FOR_CARD_ID: 'CLEAN_COMMENTS_FOR_CARD_ID',

  // List view config
  SET_CARDS_LIST_VIEW_CONFIG: 'SET_CARDS_LIST_VIEW_CONFIG',
};

const initialState = Immutable({
  isLoadingCards: false,
  isSavingCard: false,
  cards: [],
  isFetchingAttachments: false,
  isUploadingAttachments: false,
  isDeletingAttachments: false,
  attachments: [],
  comments: [],
  log: [],
  viewConfig: {
    field_filters: {
      [CardType.QUESTION]: null,
      [CardType.MILESTONE]: null,
      [CardType.DELIVERY]: null,
    },
    stepIdsToShowOnBoard: [],
    highReadability: false,
    stepsSidebarOpen: true,
    questionTitle: null,
    milestoneTitle: null,
    deliveryTitle: null,
    showQuestions: true,
    showDeliveries: true,
    showAttachments: true,
    showComments: true,
    expandedTileCells: [],
    expandedTileRows: [],
    expandedWeekCells: [],
    expandedWeekRows: [],
    // List of node IDs of steps for which questions are listed. The final step cannot be expanded.
    expandedSteps: [],
    dateFrom: getNoonOfDay(Date.now()),
    dateTo: addWeeks(Date.now(), DEFAULT_WEEKS_IN_VIEW),
    // default period for PPC from today-7days to today
    ppc_dateFrom: getNoonOfDay(addDays(Date.now(), -7)),
    ppc_dateTo: getNoonOfDay(Date.now()),
    showPPC: false,
    lastActiveCard: null,
  },
  listViewConfig: ListViewConfig.defaultValue,
  possibleResponsible: [],
  isFetchingComments: false,
  isDeletingComment: false,
  isUpdatingComment: false,
  isCreatingComment: false,
  isFetchingLog: false,
});
// Reducer
export default (state = initialState, action: any) => {
  let groupFields: any[] = [];
  
  switch (action.type) {
    case boardsActionTypes.FETCH_ACTIVE_BOARD_SUCCESS:
      groupFields = find(action.payload.activeBoard.field_config.fields, [
        'id',
        action.payload.activeBoard.field_config.from_member_field,
      ]).limited_to;
      return state.merge({
        viewConfig: {
          field_filters: {
            [CardType.QUESTION]: null,
            [CardType.MILESTONE]: null,
            [CardType.DELIVERY]: null,
          },
          stepIdsToShowOnBoard: [],
          highReadability: false,
          stepsSidebarOpen: true,
          questionTitle: null,
          milestoneTitle: null,
          deliveryTitle: null,
          showQuestions: state.viewConfig.showQuestions,
          showDeliveries: state.viewConfig.showDeliveries,
          showAttachments: state.viewConfig.showAttachments,
          showComments: state.viewConfig.showComments,
          expandedTileCells: [],
          expandedTileRows: map(groupFields, 'id'),
          expandedWeekCells: [],
          expandedWeekRows: [undefined].concat(map(groupFields, 'id')),
          expandedSteps: [],
          dateFrom: state.viewConfig.dateFrom,
          dateTo: state.viewConfig.dateTo,
          // default period for PPC from today-7days to today
          ppc_dateFrom: getNoonOfDay(addDays(Date.now(), -7)),
          ppc_dateTo: getNoonOfDay(Date.now()),
          showPPC: false,
        },
      });
    case types.SET_VIEW_CONFIG:
      return state.merge({
        viewConfig: action.payload.config,
      });
    case types.FETCH_CARDS_REQUEST:
      return state.merge({
        isLoadingCards: true,
      });
    case types.FETCH_CARDS_SUCCESS:
      return state.merge({
        isLoadingCards: false,
        cards: Immutable(action.payload.cards),
      });
    case types.FETCH_CARDS_ERROR:
      return state.merge({
        isLoadingCards: false,
      });
    case types.LOCK_CARD_REQUEST:
    case types.UNLOCK_CARD_REQUEST:
    case types.UPDATE_CARD_REQUEST:
    case types.CREATE_CARD_REQUEST:
      return state.merge({
        isSavingCard: true,
      });
    case types.LOCK_CARD_ERROR:
    case types.UNLOCK_CARD_ERROR:
      return state.merge({
        isSavingCard: false,
      });
    case types.LOCK_CARD_SUCCESS:
    case types.UNLOCK_CARD_SUCCESS:
    case types.UPDATE_CARD_SUCCESS:
      return state.merge({
        isSavingCard: false,
        cards: insertUpdatedItem(state.cards, action.payload.card),
      });
    case types.UPDATE_CARD_IMMEDIATELY_SUCCESS:
      return state.merge({
        isSavingCard: true,
        cards: insertUpdatedItem(state.cards, action.payload.card),
      });
    case types.UPDATE_CARD_IMMEDIATELY_ERROR:
      return state.merge({
        isSavingCard: false,
        cards: insertUpdatedItem(state.cards, action.payload.card),
      });
    case types.CREATE_CARD_SUCCESS:
      return state.merge({
        isSavingCard: false,
        cards: state.cards.concat(Immutable(action.payload.card)),
      });
    case types.FETCH_ATTACHMENTS_REQUEST:
      return state.merge({
        isFetchingAttachments: true,
      });
    case types.FETCH_ATTACHMENTS_SUCCESS:
      return state.merge({
        isFetchingAttachments: false,
        attachments: Immutable(action.payload.attachments),
      });
    case types.FETCH_ATTACHMENTS_ERROR:
      return state.merge({
        isFetchingAttachments: false,
      });
    case types.UPLOAD_ATTACHMENT_REQUEST:
      return state.merge({
        isUploadingAttachments: true,
      });
    case types.UPLOAD_ATTACHMENT_SUCCESS:
      return state.merge({
        isUploadingAttachments: false,
        cards: state.cards.map((card: Card) =>
          card.id === action.payload.cardId ? card.set('child_count', card.child_count + 1) : card,
        ),
        attachments: state.attachments.concat(Immutable(action.payload.attachments)),
      });
    case types.UPLOAD_ATTACHMENT_ERROR:
      return state.merge({
        isUploadingAttachments: false,
      });
    case types.ADD_LINK_REQUEST:
      return state.merge({
        isUploadingAttachments: true,
      });
    case types.ADD_LINK_SUCCESS:
      return state.merge({
        isUploadingAttachments: false,
        cards: state.cards.map((card: Card) =>
          card.id === action.payload.cardId ? card.set('child_count', card.child_count + 1) : card,
        ),
        attachments: state.attachments.concat(Immutable(action.payload.link)),
      });
    case types.ADD_LINK_ERROR:
      return state.merge({
        isUploadingAttachments: false,
      });
    case types.DELETE_ATTACHMENTS_REQUEST:
      return state.merge({
        isDeletingAttachments: true,
      });
    case types.DELETE_ATTACHMENTS_SUCCESS:
      return state.merge({
        isDeletingAttachments: false,
        cards: state.cards.map((card: Card) =>
          card.id === action.payload.cardId ? card.set('child_count', card.child_count - 1) : card,
        ),
        attachments: state.attachments.filter((a) => a.id !== action.payload.attachmentId),
      });
    case types.DELETE_ATTACHMENTS_ERROR:
      return state.merge({
        isDeletingAttachments: false,
      });
    case types.FETCH_COMMENTS_REQUEST:
      return state.merge({
        isFetchingComments: true,
      });
    case types.FETCH_COMMENTS_SUCCESS: {
      const commentIds = new Set(action.payload.comments.map((comment: Comment) => comment.id));
      const newComments = state.comments
        .filter((comment: Comment) => !commentIds.has(comment.id))
        .concat(action.payload.comments);
      return state.merge({
        isFetchingComments: false,
        comments: Immutable(newComments),
      });
    }
    case types.FETCH_COMMENTS_ERROR:
      return state.merge({
        isFetchingComments: false,
      });
    case types.CREATE_COMMENT_REQUEST:
      return state.merge({
        isCreatingComment: true,
      });
    case types.CREATE_COMMENT_SUCCESS:
      return state.merge({
        isCreatingComment: false,
        comments: Immutable([action.payload.comment]).concat(state.comments),
      });
    case types.CREATE_COMMENT_ERROR:
      return state.merge({
        isCreatingComment: false,
      });
    case types.FETCH_LOG_REQUEST:
      return state.merge({
        isFetchingLog: true,
        log: [],
      });
    case types.FETCH_LOG_SUCCESS:
      return state.merge({
        log: action.payload.log,
        isFetchingLog: false,
      });
    case types.FETCH_LOG_ERROR:
      return state.merge({
        isFetchingLog: false,
      });
    //
    // FETCH POSSIBLE RESPONSIBLE
    //
    case types.FETCH_POSSIBLE_RESPONSIBLE_REQUEST:
      return state.merge({
        possibleResponsible: Immutable([]),
      });
    case types.FETCH_POSSIBLE_RESPONSIBLE_SUCCESS:
      action.payload.possibleResponsible.forEach(verifyName);
      return state.merge({
        possibleResponsible: action.payload.possibleResponsible,
      });
    //
    // LAST ACTIVE CARD
    //
    case types.SET_LAST_ACTIVE_CARD:
      return state.merge({
        viewConfig: {
          ...state.viewConfig,
          lastActiveCard: action.payload.lastActiveCard,
        },
      });

    //Comment
    case types.UPDATE_COMMENT_REQUEST:
      return state.merge({
        isUpdatingComment: true,
      });
    case types.UPDATE_COMMENT_REQUEST_SUCCESS:
      return state.merge({
        isUpdatingComment: false,
        comments: state.comments.map((comment: Comment) => {
          return comment.id === action.payload.comment.id ? action.payload.comment : comment;
        }),
      });
    case types.UPDATE_COMMENT_REQUEST_ERROR:
      return state.merge({
        isUpdatingComment: false,
      });
    case types.DELETE_COMMENT_REQUEST:
      return state.merge({
        isDeletingComment: true,
      });
    case types.DELETE_COMMENT_REQUEST_SUCCESS:
      return state.merge({
        isDeletingComment: false,
        comments: state.comments.filter((comment: Comment) => comment.id !== action.payload.commentId),
      });
    case types.CLEAN_COMMENTS_FOR_CARD_ID:
      return state.merge({
        comments: state.comments.filter((comment: Comment) => comment.cardId !== action.payload.cardId),
      });
    case types.DELETE_COMMENT_REQUEST_ERROR:
      return state.merge({
        isDeletingComment: false,
      });
    case types.SET_CARDS_LIST_VIEW_CONFIG:
      return state.merge({
        listViewConfig: action.payload.config,
      });
    default:
      return state;
  }
};

// Actions
export const actions = {
  setViewConfig: (config: StepConfig) => ({
    type: types.SET_VIEW_CONFIG,
    payload: {
      config,
    },
  }),
  setListViewConfig: (config: StepConfig) => ({
    type: types.SET_CARDS_LIST_VIEW_CONFIG,
    payload: {
      config,
    },
  }),
  fetchCardsRequest: () => ({
    type: types.FETCH_CARDS_REQUEST,
  }),
  fetchCardsSuccess: ({ cards }: { cards: Card[] }) => ({
    type: types.FETCH_CARDS_SUCCESS,
    payload: {
      cards,
    },
  }),
  fetchCardsError: ({ error }: { error: any }) => ({
    type: types.FETCH_CARDS_ERROR,
    payload: { error },
  }),
  updateCardRequest: () => ({
    type: types.UPDATE_CARD_REQUEST,
  }),
  updateCardSuccess: ({ card }: { card: Card }) => ({
    type: types.UPDATE_CARD_SUCCESS,
    payload: {
      card,
    },
  }),
  updateCardImmediatelySuccess: ({ card }: { card: Card }) => ({
    type: types.UPDATE_CARD_IMMEDIATELY_SUCCESS,
    payload: { card },
  }),
  updateCardError: ({ error }: { error: any }) => ({
    type: types.UPDATE_CARD_ERROR,
    payload: { error },
  }),
  updateCardImmediatelyError: ({ error, card }: { error: any; card: Card }) => ({
    type: types.UPDATE_CARD_IMMEDIATELY_ERROR,
    payload: { error, card },
  }),
  lockCardRequest: () => ({
    type: types.LOCK_CARD_REQUEST,
  }),
  lockCardSuccess: ({ card }: { card: Card }) => ({
    type: types.LOCK_CARD_SUCCESS,
    payload: {
      card,
    },
  }),
  lockCardError: ({ error }: { error: any }) => ({
    type: types.LOCK_CARD_ERROR,
    payload: { error },
  }),
  unlockCardRequest: () => ({
    type: types.UNLOCK_CARD_REQUEST,
  }),
  unlockCardSuccess: ({ card }: { card: Card }) => ({
    type: types.UNLOCK_CARD_SUCCESS,
    payload: {
      card,
    },
  }),
  unlockCardError: ({ error }: { error: any }) => ({
    type: types.UNLOCK_CARD_ERROR,
    payload: { error },
  }),
  createCardRequest: () => ({
    type: types.CREATE_CARD_REQUEST,
  }),
  createCardSuccess: ({ card }: { card: Card }) => ({
    type: types.CREATE_CARD_SUCCESS,
    payload: {
      card,
    },
  }),
  createCardError: ({ error }: { error: any }) => ({
    type: types.CREATE_CARD_ERROR,
    payload: { error },
  }),
  fetchAttachmentsRequest: () => ({
    type: types.FETCH_ATTACHMENTS_REQUEST,
  }),
  fetchAttachmentsSuccess: ({ attachments }: { attachments: CardAttachment[] }) => ({
    type: types.FETCH_ATTACHMENTS_SUCCESS,
    payload: {
      attachments,
    },
  }),
  fetchAttachmentsError: ({ error }: { error: any }) => ({
    type: types.FETCH_ATTACHMENTS_ERROR,
    payload: { error },
  }),
  uploadAttachmentRequest: () => ({
    type: types.UPLOAD_ATTACHMENT_REQUEST,
  }),
  uploadAttachmentSuccess: ({ cardId, attachments }: { cardId: string; attachments: CardAttachment[] }) => ({
    type: types.UPLOAD_ATTACHMENT_SUCCESS,
    payload: {
      attachments,
      cardId,
    },
  }),
  uploadAttachmentError: ({ error }: { error: any }) => ({
    type: types.UPLOAD_ATTACHMENT_ERROR,
    payload: { error },
  }),
  addLinkRequest: () => ({
    type: types.ADD_LINK_REQUEST,
  }),
  addLinkSuccess: ({ cardId, link }: { cardId: string; link: string }) => ({
    type: types.ADD_LINK_SUCCESS,
    payload: {
      link,
      cardId,
    },
  }),
  addLinkError: ({ error }: { error: any }) => ({
    type: types.ADD_LINK_ERROR,
    payload: { error },
  }),
  deleteAttachmentsRequest: () => ({
    type: types.DELETE_ATTACHMENTS_REQUEST,
  }),
  deleteAttachmentsSuccess: ({ cardId, attachmentId }: { cardId: string; attachmentId: string }) => ({
    type: types.DELETE_ATTACHMENTS_SUCCESS,
    payload: {
      attachmentId,
      cardId,
    },
  }),
  deleteAttachmentsError: ({ error }: { error: any }) => ({
    type: types.DELETE_ATTACHMENTS_ERROR,
    payload: { error },
  }),
  fetchCommentsRequest: () => ({
    type: types.FETCH_COMMENTS_REQUEST,
  }),
  fetchCommentsSuccess: ({ comments }: { comments: Comment[] }) => ({
    type: types.FETCH_COMMENTS_SUCCESS,
    payload: {
      comments,
    },
  }),
  fetchCommentsError: ({ error }: { error: any }) => ({
    type: types.FETCH_COMMENTS_ERROR,
    payload: { error },
  }),
  createCommentRequest: () => ({
    type: types.CREATE_COMMENT_REQUEST,
  }),
  createCommentSuccess: ({ comment }: { comment: Comment }) => ({
    type: types.CREATE_COMMENT_SUCCESS,
    payload: {
      comment,
    },
  }),
  createCommentError: ({ error }: { error: any }) => ({
    type: types.CREATE_COMMENT_ERROR,
    payload: { error },
  }),
  fetchLogRequest: () => ({
    type: types.FETCH_LOG_REQUEST,
  }),
  fetchLogSuccess: ({ log }: { log: any }) => ({
    type: types.FETCH_LOG_SUCCESS,
    payload: {
      log,
    },
  }),
  fetchLogError: ({ error }: { error: any }) => ({
    type: types.FETCH_LOG_ERROR,
    payload: { error },
  }),
  //
  // FETCH POSSIBLE RESPONSIBLE
  //
  fetchPossibleResponsibleRequest: () => ({
    type: types.FETCH_POSSIBLE_RESPONSIBLE_REQUEST,
  }),
  fetchPossibleResponsibleSuccess: ({ possibleResponsible }: { possibleResponsible: string[] }) => ({
    type: types.FETCH_POSSIBLE_RESPONSIBLE_SUCCESS,
    payload: { possibleResponsible },
  }),
  fetchPossibleResponsibleError: ({ error }: { error: any }) => ({
    type: types.FETCH_POSSIBLE_RESPONSIBLE_ERROR,
    payload: { error },
  }),
  //
  // LAST ACTIVE CARD
  //
  setLastActiveCard: ({ lastActiveCard }: { lastActiveCard: string }) => ({
    type: types.SET_LAST_ACTIVE_CARD,
    payload: { lastActiveCard },
  }),

  updateCommentRequest: () => ({
    type: types.UPDATE_COMMENT_REQUEST,
  }),

  updateCommentSuccess: ({ comment }: { comment: Comment }) => ({
    type: types.UPDATE_COMMENT_REQUEST_SUCCESS,
    payload: {
      comment,
    },
  }),
  updateCommentError: ({ error }: { error: any }) => ({
    type: types.UPDATE_COMMENT_REQUEST_ERROR,
    payload: { error },
  }),

  cleanCommentForCardId: ({ cardId }: { cardId: string }) => ({
    type: types.CLEAN_COMMENTS_FOR_CARD_ID,
    payload: {
      cardId,
    },
  }),

  // Delete comment
  deleteCommentRequest: () => ({
    type: types.DELETE_COMMENT_REQUEST,
  }),
  deleteCommentSuccess: ({ commentId }: { commentId: string }) => ({
    type: types.DELETE_COMMENT_REQUEST_SUCCESS,
    payload: {
      commentId,
    },
  }),
  deleteCommentError: ({ error }: { error: any }) => ({
    type: types.DELETE_COMMENT_REQUEST_ERROR,
    payload: { error },
  }),
};

// Selectors
const cardsSelector = () => (state: any) => state.cards;

export const selectViewConfig = () => createSelector(cardsSelector(), (cardsState: any) => cardsState.viewConfig);

export const selectListViewConfig = () => createSelector(cardsSelector(), (cardsState: any) => cardsState.listViewConfig);

export const selectCards = () => createSelector(cardsSelector(), (cardsState: any) => cardsState.cards);

export const selectBoardCards = () =>
  createSelector(selectActiveBoard(), selectCards(), (board: Board, cards: Card[]) => {
    return partition(cards, (card: Card) => card.step.id === board.step_config.initial_step_id);
  });

export const selectIsLoadingCards = () => createSelector(cardsSelector(), (cardsState: any) => cardsState.isLoadingCards);

export const selectIsSavingCard = () => createSelector(cardsSelector(), (cardsState: any) => cardsState.isSavingCard);

export const selectCard = ({ cardId }: { cardId: string }) =>
  createSelector(cardsSelector(), (cardsState: any) => cardsState.cards.find((c: Card) => c.id === cardId));

export const selectAttachments = () => createSelector(cardsSelector(), (cardsState: any) => cardsState.attachments);

export const selectIsUploadingAttachments = () =>
  createSelector(cardsSelector(), (cardsState: any) => cardsState.isUploadingAttachments);

export const selectIsDeletingAttachments = () =>
  createSelector(cardsSelector(), (cardsState: any) => cardsState.isDeletingAttachments);

export const selectIsCommentsLoading = () =>
  createSelector(cardsSelector(), (cardsState: any) => cardsState.isFetchingComments);

export const selectComments = () => createSelector(cardsSelector(), (cardsState: any) => cardsState.comments);

export const selectIsLogLoading = () => createSelector(cardsSelector(), (cardsState: any) => cardsState.isFetchingLog);

export const selectLog = () => createSelector(cardsSelector(), (cardsState: any) => cardsState.log);

export const selectPossibleResponsible = () =>
  createSelector(cardsSelector(), (cardsState: any) => cardsState.possibleResponsible);

export const selectLastActiveCard = () =>
  createSelector(cardsSelector(), (cardsState: any) => cardsState.viewConfig.lastActiveCard);
