import { useState } from 'react';
import intl from 'react-intl-universal';
import { default as SpinnerButton } from '../../common/button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid2';
import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { canMoveToStep, isInStep } from '../../utils/PermissionUtils';
import { Theme, useTheme } from '@mui/material';
import { Step } from '@/models/folder/StepModel';
import { Card } from '@/models/card/CardModel';

const useStyles = (theme: Theme) => ({
  currentStep: {
    backgroundColor: '#fff9c4',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  lockIcon: {
    fontSize: theme.typography.pxToRem(20),
    paddingRight: theme.spacing(1),
  },
});

const CardStep = ({ card, steps, onStepChange }: { card: Card; steps: Step[]; onStepChange: ({ step, card }: { step: Step; card: Card }) => Promise<void> }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [movingToStep, setMovingToStep] = useState<string | null>(null);

  const handleStepChange = (step: Step) => {
    setMovingToStep(step.id);
    onStepChange({ step, card })
      .then(() => {
        setMovingToStep(null);
      })
      .catch(() => {
        setMovingToStep(null);
      });
  };

  return (
    <Accordion disabled={!card.id} defaultExpanded={!!card.id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={classes.heading}>{intl.get('card.form.move_to')}</Typography>
      </AccordionSummary>
      <AccordionActions sx={{ justifyContent: "flex-start" }}>
        <Grid container> 
          {card.step &&
            steps.map((step: Step) => (
              <Tooltip title={intl.get('card.move.tooltip')}>
                <SpinnerButton
                  variant="outlined"
                  sx={{
                    ...(isInStep({ step, card }) && classes.currentStep),
                  }}
                  onClick={() => handleStepChange(step)}
                  spinner={!!(movingToStep && movingToStep === step.id)}
                  disabled={!canMoveToStep({ step, card }) || !!movingToStep}
                >
                  {step.final && <LockIcon sx={classes.lockIcon} />}
                  {step.name}
                </SpinnerButton>
              </Tooltip>
            ))}
        </Grid>
      </AccordionActions>
    </Accordion>
  );
};

export default CardStep;
