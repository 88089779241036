import intl from 'react-intl-universal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
});

const Docs = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Paper sx={classes.root}>
      <Typography variant="h4">{intl.get('docs')}</Typography>
      <div>{intl.get('docs.boards')}</div>
      <div>{intl.get('docs.cards')}</div>
      <div>{intl.get('docs.steps')}</div>
      <div>{intl.get('docs.attachments')}</div>
      <div>{intl.get('docs.comments')}</div>
      <div>{intl.get('docs.contact_us')}</div>
    </Paper>
  );
};

export default Docs;
