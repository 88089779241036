import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import intl from 'react-intl-universal';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Gravatar from 'react-gravatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { default as Editor } from '../../common/richtext';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { DateTimeFormatter } from '../../common/DateFormatter';
import DeleteDeliveryDialogue from '../../common/DeleteCardItemDialog';
import IconButton from '@mui/material/IconButton';
import { Delete, EditOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Immutable from 'seamless-immutable';
import { authoritySorter } from '../../utils/SortUtil';
import { Theme, useTheme } from '@mui/material';
import { Comment } from '@/models/CommentModel';

const useStyles = (theme: Theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    paddingRight: '10px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
  commentLabel: {
    position: 'absolute',
    left: '40px',
    top: '145px',
    zIndex: 1000,
  },
  paper: {
    margin: `15px auto`,
    padding: '10px',
  },
  comment: {
    '&:first-child': {
      paddingLeft: '15px',
    },
  },
  iconPadding: {
    padding: '8px',
  },
});

const Comments = ({ item, comments, board, members, onSubmitComment, onUpdateComment, onDeleteComment }: { item: any; comments: any; board: any; members: any; onSubmitComment: any; onUpdateComment: any; onDeleteComment: any }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [editorExpanded, setEditorExpanded] = useState(false);
  const [commentToEdit, setCommentToEdit] = useState(null);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const commentCaption = useRef(null);

  const handleSubmit = (values, actions) => {
    closeEditor();
    actions.setSubmitting(true);

    if (commentToEdit) {
      return onUpdateComment({
        comment: commentToEdit.merge({
          content: values['content'],
        }),
      }).then(() => {
        setCommentToEdit(null);
        actions.resetForm({});
        actions.setSubmitting(false);
      });
    }

    onSubmitComment({ content: values['content'] }).then(() => {
      actions.resetForm({});
      actions.setSubmitting(false);
    });
  };

  const handleEditComment = ({ comment }) => {
    setCommentToEdit(comment);
  };

  const handleDeleteComment = ({ comment }) => {
    setOpenDeleteDialogue(true);
    setCommentToDelete(comment);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialogue(false);
    setCommentToDelete(null);
  };

  const handleDeleteSubmit = () => {
    if (commentToDelete) {
      onDeleteComment({ comment: commentToDelete });
    }
    handleCloseDelete();
  };

  const handleCancelEditComment = () => {
    setCommentToEdit(null);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.content || values.content === ' ') {
      errors['content'] = intl.get('common.form.validators.required');
    }
    return errors;
  };

  const handleFocus = () => {
    setEditorExpanded(true);
  };

  const closeEditor = () => {
    setEditorExpanded(false);
  };

  const fetchMentions = (searchTerm, callback) => {
    const mentions = Immutable.asMutable(members)
      .filter(
        member => member.name && member.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1,
      )
      .sort(authoritySorter(searchTerm))
      .map((member) => ({
        id:
          member.type === 'group' ? member.id : `${member.id.replace('@', '~')}@${board.workflow_config.community_id}`,
        name: member.name,
        rawname: member.name,
      }));
    callback(mentions, []);
  };

  const newComments = comments.filter((comment: Comment) => item.id === comment.cardId);

  return (
    <Accordion
      disabled={!item.id}
      defaultExpanded={!!item.id}
      onChange={(event, expanded) => {
        if (commentCaption && commentCaption.current) {
          commentCaption.current.style.display = expanded ? '' : 'none';
        }
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <React.Fragment>
          <Badge color="primary" badgeContent={newComments.length}>
            <Typography sx={classes.heading}>{intl.get('common.comments')}</Typography>
          </Badge>
        </React.Fragment>
      </AccordionSummary>
      <AccordionDetails sx={classes.form}>
        <Formik
          onSubmit={handleSubmit}
          validate={validate}
          enableReinitialize
          initialValues={{
            content: commentToEdit ? commentToEdit.content : ' ',
          }}
          render={({ values, handleSubmit, isSubmitting, isValid, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              {!editorExpanded && !isSubmitting && item.permissions.ADD_COMMENT && (
                <Typography ref={commentCaption} variant="caption" sx={classes.commentLabel}>
                  {intl.get('common.comments.label')}
                </Typography>
              )}
              <Editor
                id="commentField"
                value={values.content || ' '}
                onChange={(value: string) => {
                  setFieldValue('content', value);
                }}
                onFocus={handleFocus}
                enableMentions
                mentionsFetch={fetchMentions}
                disabled={!item.permissions.ADD_COMMENT}
                height={editorExpanded ? 250 : 100}
                sx={{ marginBottom: '20px' }}
              />
              {editorExpanded && (
                <div style={{ paddingTop: '10px' }}>
                  {commentToEdit ? (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !isValid || !item.permissions.ADD_COMMENT}
                    >
                      {intl.get('common.comments.update')}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !isValid || !item.permissions.ADD_COMMENT}
                    >
                      {intl.get('common.comments.add')}
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginLeft: '16px' }}
                    disabled={isSubmitting || !item.permissions.ADD_COMMENT}
                    onClick={() => {
                      setFieldValue('content', ' ');
                      handleCancelEditComment();
                      closeEditor();
                    }}
                  >
                    {intl.get('common.dialog.cancel')}
                  </Button>
                  {isSubmitting && <CircularProgress size={24} sx={classes.buttonProgress} />}
                </div>
              )}
            </form>
          )}
        />
        <Grid>
          {newComments.map((comment, index) => (
            <Paper key={`comment-${index}`} sx={classes.paper}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid>
                  <Avatar>
                    <Gravatar email={comment.author || ''} default="mm" />
                  </Avatar>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Tooltip title={intl.get('common.comments.update')}>
                    <IconButton
                      sx={classes.iconPadding}
                      disabled={!comment.permissions.UPDATE}
                      onClick={() => {
                        handleFocus();
                        handleEditComment({ comment });
                      }}
                    >
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={intl.get('common.comments.delete')}>
                    <IconButton
                      sx={classes.iconPadding}
                      disabled={!comment.permissions.DELETE}
                      onClick={() => handleDeleteComment({ comment })}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>

                  <DeleteDeliveryDialogue
                    open={openDeleteDialogue}
                    onClose={handleCloseDelete}
                    onDelete={handleDeleteSubmit}
                    titles={{
                      deleteDialogTitle: intl.get('common.comments.delete.tooltip.title'),
                      deleteDialogMessage: intl.get('common.comments.delete.tooltip.message'),
                    }}
                  />

                  <ListItemText
                    sx={classes.comment}
                    primary={<div dangerouslySetInnerHTML={{ __html: comment.content }} />}
                    secondary={intl.get('common.comments.posted_by', {
                      name: comment.created_by.name,
                      date: DateTimeFormatter({ value: comment.modified }),
                    })}
                  />
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default Comments;
