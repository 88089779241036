import intl from 'react-intl-universal';
import { find } from 'lodash';
import { getTranslations } from './utils/LocaleUtil';

const SUPPORTED_LOCALES = [
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'Norwegian',
    value: 'nb',
  },
  {
    name: 'Swedish',
    value: 'sv',
  },
];

export default (locale: string) => {
  let currentLocale = intl.determineLocale({
    urlLocaleKey: 'lang',
  });
  if (!find(SUPPORTED_LOCALES, { value: currentLocale })) {
    currentLocale = 'en';
  }

  currentLocale = locale || currentLocale;

  return intl.init({
    currentLocale,
    locales: {
      [currentLocale]: getTranslations(currentLocale),
    },
  });
};
