import React, { useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';

const useStyles = () => ({
  root: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
});

function ButtonWithProgress({
  tooltipText,
  buttonText,
  handleClick,
}: {
  tooltipText: string;
  buttonText: string;
  handleClick: () => void;
}) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [start, setStart] = React.useState(false);

  useEffect(() => {
    if (start) {
      (async () => {
        try {
          setLoading(true);
          await handleClick();
          setLoading(false);
          setStart(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          setStart(false);
        }
      })();
    }
  }, [handleClick, start]);

  const handleButtonClick = () => {
    if (!loading) {
      setStart(true);
    }
  };

  return (
    <Box component="div" sx={classes.root}>
      <Tooltip title={tooltipText}>
        <Button variant="contained" color="primary" disabled={loading} onClick={handleButtonClick}>
          {buttonText}
        </Button>
      </Tooltip>
      {loading && <CircularProgress size={24} sx={classes.progress} />}
    </Box>
  );
}

export default ButtonWithProgress;
