import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

// Constants

export const types = {
  //FETCH FILES
  FETCH_FILES_REQUEST: 'FETCH_FILES_REQUEST',
  FETCH_FILES_SUCCESS: 'FETCH_FILES_SUCCESS',
  FETCH_FILES_ERROR: 'FETCH_FILES_ERROR',

  CLEAR_FILES: 'CLEAR_FILES',
};

const initialState = Immutable({
  files: [],
  isLoading: false,
  wasRequested: false,
  isRequired: false,
});

// Reducer

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case types.FETCH_FILES_REQUEST: {
      return state.merge({
        isLoading: true,
        wasRequested: true,
      });
    }
    case types.FETCH_FILES_SUCCESS: {
      return state.merge({
        files: action.payload.files,
        isLoading: false,
      });
    }
    case types.FETCH_FILES_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }
    case types.CLEAR_FILES: {
      return state.merge({
        files: [],
        isLoading: false,
        isRequired: false,
      });
    }
    default:
      return state;
  }
}

// Actions

export const actions = {
  fetchFilesRequest: () => ({
    type: types.FETCH_FILES_REQUEST,
  }),
  fetchFilesSuccess: ({ files }: { files: File[] }) => ({
    type: types.FETCH_FILES_SUCCESS,
    payload: {
      files,
    },
  }),
  fetchFilesError: ({ error }: { error: any }) => ({
    type: types.FETCH_FILES_ERROR,
    payload: {
      error,
    },
  }),
};

export const clearFiles = () => ({
  type: types.CLEAR_FILES,
});

// Selectors
const filesSelector = () => (state: any) => state.files;

export const selectFiles = () => createSelector(filesSelector(), (filesState: any) => filesState.files);

export const selectIsLoading = () => createSelector(filesSelector(), (filesState: any) => filesState.isLoading);

export const selectWasRequested = () => createSelector(filesSelector(), (filesState: any) => filesState.wasRequested);
