export const generateExportRequest = ({ columns, items, includeComments }) => {
  const fields = columns.map((column) => ({
    id: column.name,
    title: column.title,
  }));

  return {
    format: 'excel',
    item_ids: items,
    fields: fields,
    include_comments: includeComments,
  };
};
