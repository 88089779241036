import { types } from '../../ducks/Auth';

declare global {
  interface Window {
    newrelic: any;
  }
}

const newrelic = window.newrelic;

export default {
  [types.SET_CURRENT_USER]: (action: any) => {
    if (newrelic) {
      newrelic.setCustomAttribute('IxUser', action.payload.user.username);
    }
    return { category: 'user', action: 'login' };
  },
  [types.LOGOUT_SUCCESS]: { category: 'user', action: 'logout' },
  [types.LOGOUT_FAILURE]: { category: 'user', action: 'logout.error' },
  [types.FETCH_USER_PROFILE_SUCCESS]: { category: 'user', action: 'fetch' },
  [types.FETCH_USER_PROFILE_ERROR]: (action: any) => ({
    category: 'user',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload &&
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
};
