import { useRef, useMemo, useEffect, useState, Dispatch } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import saveAs from 'file-saver';

import { alertAction } from '../../thunks/Alerts';
import * as boardsActions from '../../thunks/Boards';
import * as milestoneActions from '../../thunks/Milestones';
import Error from '../../common/Error';
import PageWrapper from '../../common/PageWrapper';
import {
  calculateDisabledFieldsForMilestone,
  getCardMetaInfo,
  getRequiredFields,
  getUpdatedFieldsAndValues,
  populateInitialValues,
} from '../../utils/FieldUtil';
import { selectActiveBoard, selectRoomMembers } from '../../ducks/Boards';
import { selectActiveCommunity } from '../../ducks/Communities';
import { selectActiveRoom } from '../../ducks/Rooms';
import {
  selectMilestone,
  selectAttachments,
  selectComments,
  selectLog,
  selectIsUploadingAttachments,
  selectIsDeletingAttachments,
  selectPossibleResponsible,
  actions as milestonesActions,
} from '../../ducks/Milestones';
import { downloadMilestoneAttachment } from '../../services/Milestones';
import { LIST_MILESTONE_VIEW_MODE, withViewModeQuery } from '../../utils/ViewModeUtil';
import Grid from '@mui/material/Grid2';
import Form from '../common/Form';
import MilestoneAttachments from '../common/Attachments';
import Log from '../common/Log';
import MilestoneComments from '../common/Comments';
import DeleteMilestoneDialog from '../../common/DeleteCardItemDialog';
import DeleteIconButton from '../../common/DeleteIconButton';
import { useNavigate } from 'react-router-dom';

const mapStateToProps = (state, ownProp) =>
  createStructuredSelector({
    activeCommunity: selectActiveCommunity(),
    activeRoom: selectActiveRoom(),
    board: selectActiveBoard(),
    milestone: selectMilestone({
      milestoneId: ownProp.params.milestoneId,
    }),
    attachments: selectAttachments(),
    log: selectLog(),
    isUploadingAttachments: selectIsUploadingAttachments(),
    isDeletingAttachments: selectIsDeletingAttachments(),
    comments: selectComments(),
    roomMembers: selectRoomMembers(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      ...milestoneActions,
      ...milestonesActions,
      ...boardsActions,
    },
    dispatch,
  ),
});

const MilestoneUpdateContainer = ({
  activeCommunity,
  activeRoom,
  board,
  milestone,
  log,
  attachments,
  isUploadingAttachments,
  isDeletingAttachments,
  comments,
  roomMembers,
  possibleResponsible,
  params,
  actions,
}: any) => {
  const navigate = useNavigate();
  const formRef = useRef();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [closeOnSuccess, setCloseOnSuccess] = useState(false);

  const getMilestoneSet = useMemo(
    () => (board) => board.milestones_config.find((config) => config.id === params.milestoneFolderId),
    [params.milestoneFolderId],
  );

  useEffect(() => {
    handleFetchComments({
      boardId: params.boardId,
      milestoneFolderId: params.milestoneFolderId,
      milestoneId: params.milestoneId,
    });
    handleFetchAttachments({
      boardId: params.boardId,
      milestoneId: params.milestoneId,
    });
    handleFetchLog({
      boardId: params.boardId,
      cardId: params.milestoneId,
    });

    if (roomMembers.length < 1 || board?.id.toString() !== params.boardId) {
      handleFetchRoomMembers({ boardId: params.boardId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = ({ values, item }) => {
    return actions
      .updateMilestone({
        boardId: board.id,
        milestoneId: item.id,
        milestoneSet: params.milestoneFolderId,
        data: {
          type: 'entry',
          fields: getUpdatedFieldsAndValues({
            values,
            item,
            fieldsConfig: getMilestoneSet(board).fields,
          }),
        },
      })
      .then((action) => {
        alertAction({
          action,
          error: intl.get('milestone.update.error'),
          success: intl.get('milestone.update.success'),
          onSuccess: () =>
            navigate(
              withViewModeQuery(
                closeOnSuccess
                  ? `/${board.id}`
                  : `/${board.id}/milestoneset/${action.payload.milestoneSet}/milestones/${action.payload.milestone.id}`,
              ),
            ),
        });
      });
  };

  const handleCancel = () => {
    navigate(withViewModeQuery(`/${board.id}`));
  };

  const handleFetchRoomMembers = ({ boardId }) => {
    actions.fetchRoomMembers({ boardId });
  };

  const handleFetchComments = ({ boardId, milestoneId }) => {
    actions
      .fetchComments({ boardId, milestoneId })
      .then((action) => alertAction({ action, error: intl.get('common.comments.fetch.error') }));
  };

  const handleFetchLog = ({ boardId, cardId }) => {
    actions.fetchLog({ boardId, cardId }).then((action) =>
      alertAction({
        action,
        error: intl.get('common.log.fetch.error'),
      }),
    );
  };

  const handleSubmitComment = ({ content }) => {
    return actions.createComment({ boardId: board.id, milestoneId: milestone.id, content }).then((action) => {
      alertAction({
        action,
        error: intl.get('common.comments.add.error'),
        success: intl.get('common.comments.add.success'),
      });
    });
  };

  const handleUpdateComment = ({ comment }) => {
    return actions
      .updateComment({
        boardId: board.id,
        milestoneId: milestone.id,
        commentId: comment.id,
        comment,
      })
      .then((action) => {
        alertAction({
          action,
          error: intl.get('common.comments.update.error'),
          success: intl.get('common.comments.update.success'),
        });
      });
  };

  const handleDeleteComment = ({ comment }) => {
    return actions
      .deleteComment({
        boardId: board.id,
        milestoneId: milestone.id,
        commentId: comment.id,
      })
      .then((action) => {
        alertAction({
          action,
          error: intl.get('common.comments.delete.error'),
          success: intl.get('common.comments.delete.success'),
        });
      });
  };

  const handleFetchAttachments = ({ boardId, milestoneId }) => {
    actions.fetchAttachments({ boardId, milestoneId }).then((action) =>
      alertAction({
        action,
        error: intl.get('common.attachments.fetch.error'),
      }),
    );
  };

  const handleUploadAttachment = ({ files }) => {
    if (files.length > 0) {
      actions
        .uploadAttachment({
          boardId: board.id,
          milestoneId: milestone.id,
          files,
        })
        .then((action) => {
          alertAction({
            action,
            success: intl.get('common.attachments.upload.success'),
            error: intl.get('common.attachments.upload.error'),
          });
        });
    }
  };

  const handleAddLinks = (links) => {
    links.forEach((link) =>
      actions
        .addMilestoneLink({
          boardId: board.id,
          milestoneId: milestone.id,
          link,
        })
        .then((action) => {
          alertAction({
            action,
            error: intl.get('common.link.add.error'),
            success: intl.get('common.link.add.success'),
          });
        }),
    );
  };

  const handleDownloadAttachment = ({ attachment }) => {
    const boardId = board.id;
    const milestoneId = milestone.id;
    let attachmentId = attachment.id;
    let version;
    if (attachment.type === 'internal-link') {
      attachmentId = attachment.destination;
      version = attachment.destination_version;
    }
    downloadMilestoneAttachment({
      boardId,
      milestoneId,
      attachmentId,
      version,
    }).then((response) => {
      saveAs(response.data, attachment.name);
    });
  };

  const handleDeleteAttachment = ({ attachmentId }) => {
    actions
      .deleteAttachment({
        boardId: board.id,
        milestoneId: milestone.id,
        attachmentId,
      })
      .then((action) => {
        alertAction({
          action,
          error: intl.get('common.attachments.delete.error'),
          success: intl.get('common.attachments.delete.success'),
        });
      });
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    actions
      .deleteMilestone({
        boardId: board.id,
        milestone,
        milestoneSet: params.milestoneFolderId,
      })
      .then((action) =>
        alertAction({
          action,
          success: intl.get('board.tooltips.milestone.delete.success.message'),
          error: intl.get('board.tooltips.milestone.delete.error.message'),
          onSuccess: () => {},
        }),
      );
    navigate(`/${params.boardId}?view=${LIST_MILESTONE_VIEW_MODE}`);
  };

  const handleSaveClick = (closeForm) => {
    const form = formRef.current;
    setCloseOnSuccess(!!closeForm);
    if (form) {
      form.handleSubmit();
    }
  };

  const deleteIconButton = (milestone) => (
    <DeleteIconButton
      tooltipText={intl.get('board.tooltips.milestone.delete.question.title')}
      onClick={() => setOpenDeleteDialog(true)}
      disabled={!milestone.permissions.UPDATE}
    />
  );

  if (!milestone) {
    return <Error text={intl.get('milestone.not_found')} />;
  }

  return (
    <PageWrapper title={intl.get('app_bar.milestone_card')}>
      <Grid container spacing={2}>
        <Grid size={{ sm: 12, md: 8, xs: 12 }} spacing={2}>
          <Form
            formRef={formRef}
            item={milestone}
            initialValues={populateInitialValues({
              item: milestone,
              fields: getMilestoneSet(board).fields,
            })}
            disabledFields={calculateDisabledFieldsForMilestone({
              permissions: milestone.permissions,
              fields: getMilestoneSet(board).fields,
            })}
            disableDelete={false}
            requiredFields={getRequiredFields({
              item: milestone,
              fields: getMilestoneSet(board).fields,
            })}
            type={board.type}
            fieldConfig={getMilestoneSet(board)}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            deleteIcon={deleteIconButton(milestone)}
            restrictions={{
              'task-done': (fieldConfig, field) => {},
              'task-responsible': (fieldConfig, field) => {},
              'rich-text': (fieldConfig, field) => {},
              'auto-number': (fieldConfig, field) => {},
              'unique-document-id': (fieldConfig, field) => {},
              'sequence-number': (fieldConfig, field) => {},
              member: (fieldConfig, field) => {},
              list: (fieldConfig, field) => {},
              date: (fieldConfig, field) => {
                if (fieldConfig.met_date_field === field.id) {
                  return { disableFuture: true };
                }
              },
              string: (fieldConfig, field) => {},
              numeric: (fieldConfig, field) => {},
            }}
            metaInfo={getCardMetaInfo(milestone, roomMembers)}
            possibleResponsible={possibleResponsible}
            submitOptions={[
              {
                title: intl.get('common.form.save_and_close'),
                default: true,
                handleClick: () => handleSaveClick({ closeForm: true }),
              },
              {
                title: intl.get('common.form.save'),
                handleClick: () => handleSaveClick({ closeForm: false }),
              },
            ]}
          />
          <DeleteMilestoneDialog
            open={openDeleteDialog}
            onClose={handleCloseDelete}
            onDelete={handleDelete}
            titles={{
              deleteDialogTitle: intl.get('board.tooltips.milestone.delete.question.title'),
              deleteDialogMessage: intl.get('board.tooltips.milestone.delete.question.message'),
            }}
          />
          <MilestoneAttachments
            activeCommunity={activeCommunity}
            activeRoom={activeRoom}
            item={milestone}
            attachments={attachments}
            members={roomMembers}
            isUploading={isUploadingAttachments}
            isDeleting={isDeletingAttachments}
            onUploadAttachment={handleUploadAttachment}
            onDeleteAttachment={handleDeleteAttachment}
            onDownloadAttachment={handleDownloadAttachment}
            onAddLinks={handleAddLinks}
          />
          <Log log={log} members={roomMembers} item={milestone} />
        </Grid>
        <Grid size={{ sm: 12, md: 4, xs: 12 }}>
          <MilestoneComments
            board={board}
            item={milestone}
            comments={comments}
            onSubmitComment={handleSubmitComment}
            onUpdateComment={handleUpdateComment}
            onDeleteComment={handleDeleteComment}
            members={roomMembers}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

const ConnectedMilestoneUpdateContainer = connect(mapStateToProps, mapDispatchToProps)(MilestoneUpdateContainer);
export default ConnectedMilestoneUpdateContainer;
