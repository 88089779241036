import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';

const useStyles = () => ({
  icon: {
    display: 'inline-block',
    '& IconButton': {
      position: 'relative',
      vAlign: 'middle',
    },
    '&:hover $active': {
      display: 'none',
    },

    '&:hover $hover': {
      display: 'inline-block',
    },
  },
  active: {
    padding: '8px',
    display: 'inline-block',
  },
  hover: {
    padding: 8,
    display: 'none',
  },
});

const ToggleIconButton = ({ tooltipText, generalIcon, hoverIcon, onClick, disabled }: any) => {
  const classes: any = useStyles();

  return disabled ? (
    <IconButton sx={classes.active} disabled>
      {generalIcon}
    </IconButton>
  ) : (
    <Tooltip title={tooltipText}>
      <Box component="div" sx={classes.icon}>
        <IconButton sx={classes.hover} onClick={onClick}>
          {hoverIcon}
        </IconButton>
        <IconButton sx={classes.active} onClick={onClick}>
          {generalIcon}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default ToggleIconButton;
