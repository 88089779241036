import { types } from '../../ducks/Cards';

export default {
  [types.FETCH_CARDS_SUCCESS]: { category: 'cards', action: 'fetch' },
  [types.FETCH_CARDS_ERROR]: (action: any) => ({
    category: 'cards',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [types.UPDATE_CARD_SUCCESS]: { category: 'cards', action: 'update' },
  [types.UPDATE_CARD_ERROR]: (action: any) => ({
    category: 'cards',
    action: 'update.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [types.UPDATE_CARD_IMMEDIATELY_SUCCESS]: {
    category: 'cards',
    action: 'update-immediately',
  },
  [types.UPDATE_CARD_IMMEDIATELY_ERROR]: (action: any) => ({
    category: 'cards',
    action: 'update-immediately.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [types.CREATE_CARD_SUCCESS]: { category: 'cards', action: 'create' },
  [types.CREATE_CARD_ERROR]: (action: any) => ({
    category: 'cards',
    action: 'create.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [types.LOCK_CARD_SUCCESS]: { category: 'cards', action: 'lock' },
  [types.LOCK_CARD_ERROR]: (action: any) => ({
    category: 'cards',
    action: 'lock.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [types.UNLOCK_CARD_SUCCESS]: { category: 'cards', action: 'unlock' },
  [types.UNLOCK_CARD_ERROR]: (action: any) => ({
    category: 'cards',
    action: 'unlock.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
};
