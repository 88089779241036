import { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import LinearProgress from '@mui/material/LinearProgress';

import { alertAction } from '../../thunks/Alerts';
import { selectActiveBoard, selectIsLoadingActiveBoard } from '../../ducks/Boards';
import { selectIsLoadingCards } from '../../ducks/Cards';
import * as boardsActions from '../../thunks/Boards';
import * as cardsActions from '../../thunks/Cards';
import Error from '../../common/Error';

import { CardCreateContainer as CardCreate, CardUpdateContainer as CardUpdate } from '.';
import { actions as roomActions, selectActiveRoom } from '../../ducks/Rooms';
import { actions as communityActions, selectActiveCommunity } from '../../ducks/Communities';
import { useParams } from 'react-router-dom';
import { Community } from '@/models/CommunityModel';
import { Room } from '@/models/RoomModel';
import { Board } from '@/models/BoardModel';

const mapStateToProps = () =>
  createStructuredSelector({
    board: selectActiveBoard(),
    activeRoom: selectActiveRoom(),
    activeCommunity: selectActiveCommunity(),
    isLoadingBoard: selectIsLoadingActiveBoard(),
    isLoadingCards: selectIsLoadingCards(),
  });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      ...cardsActions,
      ...boardsActions,
      setActiveRoom: roomActions.setActiveRoom,
      setActiveCommunity: communityActions.setActiveCommunity,
    },
    dispatch,
  ),
});

const CardContainer = ({
  board,
  activeCommunity,
  activeRoom,
  actions,
  isLoadingBoard,
  isLoadingCards,
}: {
  board: Board;
  activeCommunity: Community;
  activeRoom: Room;
  actions: any;
  isLoadingBoard: boolean;
  isLoadingCards: boolean;
}) => {
  const params = useParams();
  useEffect(() => {
    const { boardId } = params;
    // Direct link, assume nothing is loaded.
    if (!board) {
      actions.fetchActiveBoard({ boardId }).then((action: any) => {
        const workflowConfig = action.payload.activeBoard.workflow_config;

        if (activeCommunity !== workflowConfig.community_id) {
          actions.setActiveCommunity({
            communityId: workflowConfig.community_id,
          });
        }
        if (activeRoom !== workflowConfig.room_id) {
          actions.setActiveRoom({ roomId: workflowConfig.room_id });
        }
        alertAction({ action, error: intl.get('card.fetch_board.error') });
      });

      actions
        .fetchCards({ boardId })
        .then((action: any) => alertAction({ action, error: intl.get('card.fetch.error') }));
    }
     
  }, [board, activeCommunity, activeRoom, actions, params]);

  // Still loading active board
  if (isLoadingBoard || isLoadingCards) {
    return <LinearProgress />;
  }

  // Board fetch failed
  if (!board) {
    return <Error text={intl.get('board.not_found')} />;
  }

  if (params.cardId) {
    return <CardUpdate board={board} params={params} />;
  }

  return <CardCreate board={board} params={params} />;
};

const ConnectedCardContainer = connect(mapStateToProps, mapDispatchToProps)(CardContainer);

export default ConnectedCardContainer;
