import { types as questionTypes } from '../../ducks/Cards';
import { types as milestoneTypes } from '../../ducks/Milestones';
import { types as deliveryTypes } from '../../ducks/Deliveries';

export default {
  [questionTypes.ADD_LINK_SUCCESS]: {
    category: 'links',
    action: 'add',
  },
  [questionTypes.ADD_LINK_ERROR]: (action: any) => ({
    category: 'links',
    action: 'add.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [milestoneTypes.ADD_LINK_SUCCESS]: {
    category: 'links',
    action: 'add',
  },
  [milestoneTypes.ADD_LINK_ERROR]: (action: any) => ({
    category: 'links',
    action: 'add.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [deliveryTypes.ADD_LINK_SUCCESS]: {
    category: 'links',
    action: 'add',
  },
  [deliveryTypes.ADD_LINK_ERROR]: (action: any) => ({
    category: 'links',
    action: 'add.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
};
