import { Card, CardAttachment, CardLog } from '@/models/card/CardModel';
import { client } from './API';
import { Comment } from '@/models/CommentModel';

/**
 * Fetch list of cards of all steps
 */
export const fetchCards = ({ boardId }: { boardId: string }): Promise<Card[]> => client.get(`/v1/boards/${boardId}/cards`).then((response) => response.data);

/**
 * Create card in initial step
 */
export const createCard = ({ boardId, data }: { boardId: string, data: Card }): Promise<Card> =>
  client.post(`/v1/boards/${boardId}/cards`, data).then((response) => response.data);

/**
 * Update card values (from/to/question/etc.)
 */
export const updateCard = ({ boardId, cardId, stepId, data }: { boardId: string, cardId: string, stepId?: string, data: Card }): Promise<Card> =>
  client
    .put(`/v1/boards/${boardId}/cards/${cardId}${stepId ? `?stepId=${stepId}` : ''}`, data)
    .then((response) => response.data);

/**
 * Lock card
 */
export const lockCard = ({ boardId, cardId }: { boardId: string, cardId: string }): Promise<Card> =>
  client.post(`/v1/boards/${boardId}/cards/${cardId}/lock`).then((response) => response.data);

/**
 * Unlock card
 */
export const unlockCard = ({ boardId, cardId }: { boardId: string, cardId: string }): Promise<Card> =>
  client.post(`/v1/boards/${boardId}/cards/${cardId}/unlock`).then((response) => response.data);

/**
 * Move card to a specific step
 */
export const moveCard = ({ boardId, cardId, stepId }: { boardId: string, cardId: string, stepId: string }): Promise<Card> => {
  return client.post(`/v1/boards/${boardId}/steps/${stepId}`, { id: cardId }).then((response) => response.data);
};

/**
 * Fetch card attachment infos
 */
export const fetchCardAttachments = ({ boardId, cardId }: { boardId: string, cardId: string }): Promise<CardAttachment[]> =>
  client.get(`/v1/boards/${boardId}/cards/${cardId}/attachments`).then((response) => response.data);

/**
 * Upload attachment with content to card
 */
export const uploadCardAttachment = ({ boardId, cardId, file }: { boardId: string, cardId: string, file: File }): Promise<CardAttachment> => {
  const data = new FormData();
  data.append('file', file, file.name);
  return client
    .post(`/v1/boards/${boardId}/cards/${cardId}/attachments`, data, {
      headers: { Accept: '*/*' },
    })
    .then((response) => response.data);
};

/**
 * Add link to card
 */
export const addLink = ({ boardId, cardId, link }: { boardId: string, cardId: string, link: any }): Promise<Card> =>
  client.post(`/v1/boards/${boardId}/cards/${cardId}/links`, link).then((response) => response.data);

/**
 * Delete attachment from card
 */
export const deleteCardAttachment = ({ boardId, cardId, attachmentId }: { boardId: string, cardId: string, attachmentId: string }): Promise<Card> =>
  client.delete(`/v1/boards/${boardId}/cards/${cardId}/attachments/${attachmentId}`).then((response) => response.data);

export const downloadCardAttachment = ({ boardId, cardId, attachmentId, version }: { boardId: string, cardId: string, attachmentId: string, version: string }): Promise<Blob> => {
  const config = {
    responseType: 'blob', // important
    params: version ? { version } : {},
  };

  return client.get(`/v1/boards/${boardId}/cards/${cardId}/attachments/${attachmentId}`, config as any);
};

/**
 * Fetch all card comments
 */
export const fetchCardComments = ({ boardId, cardId }: { boardId: string, cardId: string }): Promise<Comment[]> => {
  return client.get(`/v1/boards/${boardId}/cards/${cardId}/comments`).then((response) => response.data);
};

/**
 * Create comment in card
 */
export const createCardComment = ({ boardId, cardId, content }: { boardId: string, cardId: string, content: string }): Promise<Comment> => {
  const comment = { content };
  return client.post(`/v1/boards/${boardId}/cards/${cardId}/comments`, comment).then((response) => response.data);
};

/**
 * Update comment in card
 */
export const updateCardComment = ({ boardId, cardId, comment }: { boardId: string, cardId: string, comment: Comment }): Promise<Comment> => {
  return client
    .put(`/v1/boards/${boardId}/cards/${cardId}/comments/${comment.id}`, comment)
    .then((response) => response.data);
};

/**
 * Delete comment in card
 */
export const deleteCardComment = ({ boardId, cardId, commentId }: { boardId: string, cardId: string, commentId: string }): Promise<Comment> => {
  return client.delete(`/v1/boards/${boardId}/cards/${cardId}/comments/${commentId}`).then((response) => response.data);
};

/**
 * Fetch card log
 */
export const fetchCardLog = async ({ boardId, cardId }: { boardId: string, cardId: string }): Promise<CardLog[]> => {
  const response = await client.get(`/v1/boards/${boardId}/cards/${cardId}/log`);
  return response.data;
};
