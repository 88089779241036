import intl from 'react-intl-universal';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import AttachmentIcon from '@mui/icons-material/AttachFile';
import CommentIcon from '@mui/icons-material/ModeCommentOutlined';
import DraggableCard from './DraggableCard';
import CardItemTooltip from './CardItemTooltip';
import { getCardDate, getEntryTitle, isNumberField, getTitle } from '../../utils/FieldUtil';
import { Box } from '@mui/material';
import { Board } from '@/models/BoardModel';
import { Card, CardType } from '@/models/card/CardModel';

export const STATUS_RED = 'statusRed';
export const STATUS_GREEN = 'statusGreen';

const DEFAULT_PADDING_LEFT = '40px';
const HIGH_READABILITY_PADDING_LEFT = '50px';

const useStyles = () => ({
  card: {
    margin: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
    position: 'relative',
    background: '#f5f2f1',
    minHeight: '38px',
    border: '2px dashed transparent;',
  },
  cardFake: {
    opacity: 0.4,
    border: '2px dashed grey',
  },
  cardHighReadability: {
    margin: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
    position: 'relative',
    background: '#f5f2f1',
    minHeight: '56px',
  },
  cardIsMarked: {
    background: '#fee',
    borderWidth: '3px',
  },
  title: {
    maxHeight: '30px',
    overflow: 'hidden',
    fontSize: '14px',
    lineHeight: '15px',
    padding: '6px 14px 0 38px',
    textOverflow: 'ellipsis',
  },
  titleHighReadability: {
    maxHeight: '49px',
    overflow: 'hidden',
    fontSize: '24px',
    lineHeight: '24px',
    padding: '8px 22px 0 52px',
    textOverflow: 'ellipsis',
  },
  info: {
    display: 'flex',
    paddingRight: '5px',
    fontSize: '10px',
    fontWeight: '100',
  },
  infoHighReadability: {
    display: 'flex',
    paddingTop: '2px',
    paddingBottom: '2px',
    fontSize: '18px',
    fontWeight: '100',
  },
  typeIconContainer: {
    borderRadius: '50%',
    position: 'absolute',
    top: 4,
    display: 'inline-block',
  },
  tag: {
    height: '26px',
    width: '6px',
    borderRadius: '3px',
    position: 'absolute',
    right: '3px',
    top: '6px',
    display: 'inline-block',
  },
  tagHighReadability: {
    height: '42px',
    width: '12px',
    borderRadius: '6px',
    position: 'absolute',
    right: '3px',
    top: '6px',
    display: 'inline-block',
  },
  icon: {
    height: '14px',
    width: '14px',
  },
  iconHighReadability: {
    height: '24px',
    width: '24px',
  },
  date: {
    marginTop: '2px',
    marginRight: '9px',
  },
  heading: {
    fontSize: '8px',
  },
  statusGreen: {
    backgroundColor: '#309269',
  },
  statusRed: {
    backgroundColor: '#d64c48',
  },
});

const CardItem = ({
  card,
  board,
  viewConfig,
  titleField,
  getFieldMap,
  typeIcon,
  typeIconTooltip,
  statusColour,
  statusTooltip,
  onCardClick,
  rowIndex,
  columnIndex,
  isMarked,
  type,
}: {
  card: Card;
  board: Board;
  viewConfig: any;
  titleField: any;
  getFieldMap: any;
  typeIcon: any;
  typeIconTooltip: any;
  statusColour: any;
  statusTooltip: any;
  onCardClick: any;
  rowIndex: any;
  columnIndex: any;
  isMarked?: any;
  type: any;
}) => {
  const classes = useStyles();
  const calculateCardInfoPaddingLeft = ({
    cardType,
    cardDate,
    showComments,
    showAttachments,
  }: {
    cardType: CardType;
    cardDate: Date;
    showComments: boolean;
    showAttachments: boolean;
  }) => {
    if (cardType !== CardType.QUESTION) {
      return viewConfig.highReadability ? HIGH_READABILITY_PADDING_LEFT : DEFAULT_PADDING_LEFT;
    }

    const fields = board.field_config.fields;
    const isTitleNumberField = isNumberField({
      fields,
      titleId: viewConfig[titleField] || getEntryTitle(fields),
    });

    if (viewConfig.highReadability) {
      return isTitleNumberField && cardDate && showAttachments && showComments
        ? DEFAULT_PADDING_LEFT
        : HIGH_READABILITY_PADDING_LEFT;
    }

    return isTitleNumberField
      ? cardDate && showAttachments && showComments
        ? '24px' //when title is number field and we should display all icons in info
        : '32px' //when title is number field and we should not display all icons in info
      : DEFAULT_PADDING_LEFT; //when title is not number field
  };

  const cardTitle = getTitle(card, viewConfig, titleField);
  const commentCaption = intl.get(card.comment_count === 1 ? 'boards.tooltips.comment' : 'boards.tooltips.comments');
  const attachmentCaption = intl.get(
    card.child_count === 1 ? 'boards.tooltips.attachment' : 'boards.tooltips.attachments',
  );
  const locale = intl.getInitOptions().currentLocale;
  const cardDate = getCardDate(card, board, locale);
  const showAttachments = viewConfig.showAttachments && card.child_count > 0;
  const showComments = viewConfig.showComments && card.comment_count > 0;

  const cardInfoPaddingLeft = calculateCardInfoPaddingLeft({
    cardType: type,
    cardDate,
    showComments,
    showAttachments,
  });

  return (
    <DraggableCard board={board}>
      <Paper
        sx={{
          ...(viewConfig.highReadability ? classes.cardHighReadability : classes.card),
          ...(card.fakeModel && classes.cardFake),
          ...(isMarked && classes.cardIsMarked && false), // do not highlight cards until DARWIN-16807 AC #7 will be clarified
        }}
        elevation={1}
        onClick={() => onCardClick(card)}
      >
        <Tooltip title={typeIconTooltip}>
          <Box component="div" sx={classes.typeIconContainer}>
            {typeIcon}
          </Box>
        </Tooltip>
        <Tooltip title={statusTooltip}>
          <Box
            component="div"
            sx={{
              ...(viewConfig.highReadability ? classes.tagHighReadability : classes.tag),
              ...classes[statusColour],
            }}
          />
        </Tooltip>
        <CardItemTooltip viewConfig={viewConfig} map={getFieldMap(card)}>
          <div>
            <Typography
              sx={{
                ...(viewConfig.highReadability ? classes.titleHighReadability : classes.title),
              }}
              gutterBottom
            >
              {cardTitle}
            </Typography>
            <Box
              component="div"
              sx={{
                ...(viewConfig.highReadability ? classes.infoHighReadability : classes.info),
                paddingLeft: cardInfoPaddingLeft,
              }}
            >
              <span style={{ height: 20, marginRight: 1 }}>{cardDate}</span>
              {showComments && (
                <Tooltip title={String(card.comment_count) + ' ' + commentCaption}>
                  <CommentIcon sx={viewConfig.highReadability ? classes.iconHighReadability : classes.icon} />
                </Tooltip>
              )}
              {showAttachments && (
                <Tooltip title={String(card.child_count) + ' ' + attachmentCaption}>
                  <AttachmentIcon sx={viewConfig.highReadability ? classes.iconHighReadability : classes.icon} />
                </Tooltip>
              )}
            </Box>
          </div>
        </CardItemTooltip>
      </Paper>
    </DraggableCard>
  );
};

export default CardItem;
