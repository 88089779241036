import { Milestone } from '@/models/MilestoneModel';
import { client } from './API';
import { Card, CardAttachment } from '@/models/card/CardModel';
import { Comment } from '@/models/CommentModel';

/**
 * Fetch list of milestones
 */
export const fetchMilestones = ({ boardId }: { boardId: string }): Promise<Milestone[]> =>
  client.get(`/v1/boards/${boardId}/milestones`).then((response) => response.data);

/**
 * Create milestone
 */
export const createMilestone = ({ boardId, containerId, data }: { boardId: string, containerId: string, data: Milestone }): Promise<Milestone> =>
  client.post(`/v1/boards/${boardId}/milestones/${containerId}`, data).then((response) => response.data);

/**
 * Update milestone values
 */
export const updateMilestone = ({ boardId, milestoneId, data }: { boardId: string, milestoneId: string, data: Milestone }): Promise<Milestone> =>
  client.put(`/v1/boards/${boardId}/milestones/${milestoneId}`, data).then((response) => response.data);

/**
 * Delete milestone
 */
export const deleteMilestone = ({ boardId, milestoneId }: { boardId: string, milestoneId: string }): Promise<Milestone> =>
  client.delete(`/v1/boards/${boardId}/milestones/${milestoneId}`).then((response) => response.data);

/**
 * Fetch milestone attachment infos
 */
export const fetchMilestoneAttachments = ({ boardId, milestoneId }: { boardId: string, milestoneId: string }): Promise<CardAttachment[]> =>
  client.get(`/v1/boards/${boardId}/milestones/${milestoneId}/attachments`).then((response) => response.data);

/**
 * Upload attachment with content to milestone
 */
export const uploadMilestoneAttachment = ({ boardId, milestoneId, file }: { boardId: string, milestoneId: string, file: File }): Promise<CardAttachment> => {
  const data = new FormData();
  data.append('file', file, file.name);
  return client
    .post(`/v1/boards/${boardId}/milestones/${milestoneId}/attachments`, data, {
      headers: { Accept: '*/*' },
    })
    .then((response) => response.data);
};

/**
 * Add link to milestone
 */
export const addMilestoneLink = ({ boardId, milestoneId, link }: { boardId: string, milestoneId: string, link: any }): Promise<Card> =>
  client.post(`/v1/boards/${boardId}/milestones/${milestoneId}/links`, link).then((response) => response.data);

/**
 * Delete attachment from milestone
 */
export const deleteMilestoneAttachment = ({ boardId, milestoneId, attachmentId }: { boardId: string, milestoneId: string, attachmentId: string }): Promise<Card> => {
  return client
    .delete(`/v1/boards/${boardId}/milestones/${milestoneId}/attachments/${attachmentId}`)
    .then((response) => response.data);
};

export const downloadMilestoneAttachment = ({ boardId, milestoneId, attachmentId, version }: { boardId: string, milestoneId: string, attachmentId: string, version: string }): Promise<Blob> => {
  const config = {
    responseType: 'blob', // important
    params: version ? { version } : {},
  };
  return client.get(`/v1/boards/${boardId}/milestones/${milestoneId}/attachments/${attachmentId}`, config);
};

/**
 * Fetch all milestone comments
 */
export const fetchMilestoneComments = ({ boardId, milestoneId }: { boardId: string, milestoneId: string }): Promise<Comment[]> => {
  return client.get(`/v1/boards/${boardId}/milestones/${milestoneId}/comments`).then((response) => response.data);
};

/**
 * Create comment in milestone
 */
export const createMilestoneComment = ({ boardId, milestoneId, content }: { boardId: string, milestoneId: string, content: string }): Promise<Comment> => {
  const comment = { content };
  return client
    .post(`/v1/boards/${boardId}/milestones/${milestoneId}/comments`, comment)
    .then((response) => response.data);
};

/**
 * Update comment in Milestone
 */
export const updateMilestoneComment = ({ boardId, milestoneId, comment }: { boardId: string, milestoneId: string, comment: Comment }): Promise<Comment> => {
  return client
    .put(`/v1/boards/${boardId}/milestones/${milestoneId}/comments/${comment.id}`, comment)
    .then((response) => response.data);
};

/**
 * Delete comment in Milestone
 */
export const deleteMilestoneComment = ({ boardId, milestoneId, commentId }: { boardId: string, milestoneId: string, commentId: string }): Promise<Comment> => {
  return client
    .delete(`/v1/boards/${boardId}/milestones/${milestoneId}/comments/${commentId}`)
    .then((response) => response.data);
};
