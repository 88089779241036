import React from 'react';
import intl from 'react-intl-universal';
import Menu from '@mui/material/Menu';
import DisciplineFilters from './DisciplineFilters';
import FieldFilters from './FieldFilters';
import StepsFilter from './StepsFilter';
import IconButton from '@mui/material/IconButton';
import IconFilterOn from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import red from '@mui/material/colors/red';
import { getIdenticalMilestoneFields } from '../../utils/FieldUtil';
import { getValidFilterFields, isAnyFilterAvailable, isShowingOnlyDefaultStep } from '../../utils/FilterUtil';
import { WEEK_BOARD_VIEW_MODE } from '../../utils/ViewModeUtil';
import { CardType } from '@/models/card/CardModel';


const useStyles = () => ({
  iconFiltersApplied: {
    color: red[800],
  },
  menu: {
    paddingBottom: '24px',
  },
});

const BoardCellFilters = ({
  board,
  viewConfig,
  possibleResponsible,
  onChange,
  viewName,
}: {
  board: any;
  viewConfig: any;
  possibleResponsible: any;
  onChange: any;
  viewName: any;
}) => {
  const classes: any = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fromMemberField = board.field_config.fields.find((field) => field.id === board.field_config.from_member_field);
  const toMemberField = board.field_config.fields.find((field) => field.id === board.field_config.to_member_field);

  // Filters questions
  let questionFields = getValidFilterFields({
    board,
    fields: board.field_config.fields,
    type: CardType.QUESTION,
  });
  const taskResponsible = board.field_config.fields.filter((field) => field.type === 'task-responsible');
  if (taskResponsible.length === 1) {
    questionFields = questionFields.concat(taskResponsible[0].set('limited_to', possibleResponsible));
  }

  //Filters deliveries
  let deliveryFields = [];
  if (board.delivery_config && viewName === WEEK_BOARD_VIEW_MODE) {
    deliveryFields = getValidFilterFields({
      board,
      fields: board.delivery_config.fields,
      type: CardType.DELIVERY,
    });
  }

  //Filters milestones
  let milestoneFields = [];
  if (viewName === WEEK_BOARD_VIEW_MODE) {
    milestoneFields = getValidFilterFields({
      board,
      fields: getIdenticalMilestoneFields({ board }),
      type: CardType.MILESTONE,
    });
  }

  // Filters applied
  const hasDisciplineFilters =
    (!!viewConfig.from && viewConfig.from.length !== fromMemberField.limited_to.length) ||
    (!!viewConfig.to && viewConfig.to.length !== toMemberField.limited_to.length);
  const hasFilters =
    hasDisciplineFilters ||
    isAnyFilterAvailable(viewConfig.field_filters[CardType.QUESTION]) ||
    !isShowingOnlyDefaultStep(board, viewConfig.stepIdsToShowOnBoard) ||
    (viewName === WEEK_BOARD_VIEW_MODE &&
      (isAnyFilterAvailable(viewConfig.field_filters[CardType.MILESTONE]) ||
        isAnyFilterAvailable(viewConfig.field_filters[CardType.DELIVERY])));

  return (
    <React.Fragment>
      <Tooltip title={intl.get('board.toolbar.discipline_filters.tooltip')}>
        <IconButton onClick={handleClick}>
          <IconFilterOn sx={{ ...(hasFilters && classes.iconFiltersApplied) }} />
        </IconButton>
      </Tooltip>
      <Menu classes={{ paper: classes.menu }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <DisciplineFilters
          viewConfig={viewConfig}
          hasDisciplineFilters={hasDisciplineFilters}
          fromMemberField={fromMemberField}
          toMemberField={toMemberField}
          onChange={onChange}
        />
        {milestoneFields.length > 0 && (
          <FieldFilters
            title={intl.get('board.toolbar.milestones.filters')}
            resetTitle={intl.get('board.toolbar.milestones.filters.reset')}
            viewConfig={viewConfig}
            fields={milestoneFields}
            onChange={onChange}
            type={CardType.MILESTONE}
          />
        )}
        {questionFields.length > 0 && (
          <FieldFilters
            title={intl.get('board.toolbar.questions.filters')}
            resetTitle={intl.get('board.toolbar.questions.filters.reset')}
            viewConfig={viewConfig}
            fields={questionFields}
            onChange={onChange}
            type={CardType.QUESTION}
          />
        )}
        {deliveryFields.length > 0 && (
          <FieldFilters
            title={intl.get('board.toolbar.deliveries.filters')}
            resetTitle={intl.get('board.toolbar.deliveries.filters.reset')}
            viewConfig={viewConfig}
            fields={deliveryFields}
            onChange={onChange}
            type={CardType.DELIVERY}
          />
        )}
        <StepsFilter
          title={intl.get('board.toolbar.step_to_expose')}
          resetTitle={intl.get('board.toolbar.deliveries.filters.reset')}
          viewConfig={viewConfig}
          stepIdsToShowOnBoard={viewConfig.stepIdsToShowOnBoard}
          onChange={onChange}
          board={board}
        />
      </Menu>
    </React.Fragment>
  );
};

export default BoardCellFilters;
