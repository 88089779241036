import intl from 'react-intl-universal';
import CardItem from './CardItem';
import { STATUS_RED, STATUS_GREEN } from './CardItem';
import { getQuestionFieldValuesToDisplayInTooltip } from '../../utils/FieldUtil';
import { Card, CardType } from '@/models/card/CardModel';
import QuestionIcon from '@mui/icons-material/HelpRounded';
import { isQuestionAnswered } from '../../utils/QuestionUtil';
import { Board } from '@/models/BoardModel';

const useStyles = () => ({
  typeIcon: {
    height: '32px',
    width: '32px',
    color: '#3eb1c8',
  },
  typeIconHighReadability: {
    height: '48px',
    width: '48px',
    color: '#3eb1c8',
  },
});

const QuestionCardItem = ({
  card,
  board,
  viewConfig,
  onCardClick,
  rowIndex,
  columnIndex,
  isMarked,
  type,
}: {
  card: Card;
  board: Board;
  viewConfig: any;
  onCardClick: ({ card, type }: { card: Card; type: CardType }) => void;
  rowIndex: number;
  columnIndex: number;
  isMarked: boolean;
  type: CardType;
}) => {
  const classes = useStyles();
  const isAnswered = isQuestionAnswered(board, card);

  return (
    <CardItem
      key={`card-${card.id}`}
      card={card}
      board={board}
      viewConfig={viewConfig}
      onCardClick={(card: Card) => {
        onCardClick({ card, type: CardType.QUESTION });
      }}
      getFieldMap={getQuestionFieldValuesToDisplayInTooltip}
      typeIcon={<QuestionIcon sx={viewConfig.highReadability ? classes.typeIconHighReadability : classes.typeIcon} />}
      typeIconTooltip={intl.get('board.tooltips.question')}
      statusColour={isAnswered ? STATUS_GREEN : STATUS_RED}
      statusTooltip={
        isAnswered ? intl.get('board.tooltips.question.answered') : intl.get('board.tooltips.question.unanswered')
      }
      titleField="questionTitle"
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      isMarked={isMarked}
      type={type}
    />
  );
};

export default QuestionCardItem;
