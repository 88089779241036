import { Comment } from '@/models/CommentModel';
import { client } from './API';
import { Card, CardAttachment } from '@/models/card/CardModel';

/**
 * Fetch list of deliveries
 */
export const fetchDeliveries = ({ boardId }: { boardId: string }): Promise<Card[]> =>
  client.get(`/v1/boards/${boardId}/deliveries`).then((response) => response.data);

/**
 * Create delivery
 */
export const createDelivery = ({ boardId, data }: { boardId: string, data: Card }): Promise<Card> =>
  client.post(`/v1/boards/${boardId}/deliveries`, data).then((response) => response.data);

/**
 * Update delivery values
 */
export const updateDelivery = ({ boardId, deliveryId, data }: { boardId: string, deliveryId: string, data: Card }): Promise<Card> =>
  client.put(`/v1/boards/${boardId}/deliveries/${deliveryId}`, data).then((response) => response.data);

/**
 * Delete delivery
 */
export const deleteDelivery = ({ boardId, deliveryId }: { boardId: string, deliveryId: string }): Promise<Card> =>
  client.delete(`/v1/boards/${boardId}/deliveries/${deliveryId}`).then((response) => response.data);

/**
 * Fetch delivery attachment infos
 */
export const fetchDeliveryAttachments = ({ boardId, deliveryId }: { boardId: string, deliveryId: string }): Promise<CardAttachment[]> =>
  client.get(`/v1/boards/${boardId}/deliveries/${deliveryId}/attachments`).then((response) => response.data);

/**
 * Upload attachment with content to delivery
 */
export const uploadDeliveryAttachment = ({ boardId, deliveryId, file }: { boardId: string, deliveryId: string, file: File }): Promise<CardAttachment> => {
  const data = new FormData();
  data.append('file', file, file.name);
  return client
    .post(`/v1/boards/${boardId}/deliveries/${deliveryId}/attachments`, data, {
      headers: { Accept: '*/*' },
    })
    .then((response) => response.data);
};

/**
 * Add link to delivery
 */
export const addDeliveryLink = ({ boardId, deliveryId, link }: { boardId: string, deliveryId: string, link: any }): Promise<Card> =>
  client.post(`/v1/boards/${boardId}/deliveries/${deliveryId}/links`, link).then((response) => response.data);

/**
 * Delete attachment from delivery
 */
export const deleteDeliveryAttachment = ({ boardId, deliveryId, attachmentId }: { boardId: string, deliveryId: string, attachmentId: string }): Promise<Card> => {
  return client
    .delete(`/v1/boards/${boardId}/deliveries/${deliveryId}/attachments/${attachmentId}`)
    .then((response) => response.data);
};

export const downloadDeliveryAttachment = ({ boardId, deliveryId, attachmentId, version }: { boardId: string, deliveryId: string, attachmentId: string, version: string }): Promise<Blob> => {
  const config = {
    responseType: 'blob', // important
    params: version ? { version } : {},
  };
  return client.get(`/v1/boards/${boardId}/deliveries/${deliveryId}/attachments/${attachmentId}`, config as any);
};

/**
 * Fetch all delivery comments
 */
export const fetchDeliveryComments = ({ boardId, deliveryId }: { boardId: string, deliveryId: string }): Promise<Comment[]> => {
  return client.get(`/v1/boards/${boardId}/deliveries/${deliveryId}/comments`).then((response) => response.data);
};

/**
 * Create comment in delivery
 */
export const createDeliveryComment = ({ boardId, deliveryId, content }: { boardId: string, deliveryId: string, content: string }): Promise<Comment> => {
  const comment = { content };
  return client
    .post(`/v1/boards/${boardId}/deliveries/${deliveryId}/comments`, comment)
    .then((response) => response.data);
};

/**
 * Update comment in deliveries
 */
export const updateDeliveryComment = ({ boardId, deliveryId, comment }: { boardId: string, deliveryId: string, comment: Comment }): Promise<Comment> => {
  return client
    .put(`/v1/boards/${boardId}/deliveries/${deliveryId}/comments/${comment.id}`, comment)
    .then((response) => response.data);
};

/**
 * Delete comment in deliveries
 */
export const deleteDeliveryComment = ({ boardId, deliveryId, commentId }: { boardId: string, deliveryId: string, commentId: string }): Promise<Comment> => {
  return client
    .delete(`/v1/boards/${boardId}/deliveries/${deliveryId}/comments/${commentId}`)
    .then((response) => response.data);
};
