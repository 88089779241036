import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { actions as appActions, selectZoomLevel } from '../ducks/App';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const mapStateToProps = () =>
  createStructuredSelector({
    zoomLevel: selectZoomLevel(),
  });

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...appActions,
    },
    dispatch,
  ),
});

const ZoomDetector = ({ zoomLevel = 1, actions }) => {
  const zoomHandler = React.useCallback(() => {
    if (zoomLevel !== window.devicePixelRatio) {
      actions.zoom({ zoomLevel: window.devicePixelRatio });
    }
  }, [zoomLevel, actions]);

  React.useEffect(() => {
    window.addEventListener('resize', zoomHandler);
    return () => window.removeEventListener('resize', zoomHandler);
  }, [zoomHandler]);

  return <React.Fragment />;
};

ZoomDetector.propTypes = {
  zoomLevel: PropTypes.number,
  actions: PropTypes.object.isRequired,
};

const ConnectedZoomDetector = compose(connect(mapStateToProps, mapDispatchToProps))(ZoomDetector);

export default ConnectedZoomDetector;
