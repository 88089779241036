import { types as questionTypes } from '../../ducks/Cards';
import { types as milestoneTypes } from '../../ducks/Milestones';
import { types as deliveryTypes } from '../../ducks/Deliveries';

export default {
  [questionTypes.FETCH_COMMENTS_SUCCESS]: {
    category: 'comments',
    action: 'fetch',
  },
  [questionTypes.FETCH_COMMENTS_ERROR]: (action: any) => ({
    category: 'comments',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [questionTypes.CREATE_COMMENT_SUCCESS]: {
    category: 'comments',
    action: 'create',
  },
  [questionTypes.CREATE_COMMENT_ERROR]: (action: any) => ({
    category: 'comments',
    action: 'create.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [milestoneTypes.FETCH_COMMENTS_SUCCESS]: {
    category: 'comments',
    action: 'fetch',
  },
  [milestoneTypes.FETCH_COMMENTS_ERROR]: (action: any) => ({
    category: 'comments',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [milestoneTypes.CREATE_COMMENT_SUCCESS]: {
    category: 'comments',
    action: 'create',
  },
  [milestoneTypes.CREATE_COMMENT_ERROR]: (action: any) => ({
    category: 'comments',
    action: 'create.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [deliveryTypes.FETCH_COMMENTS_SUCCESS]: {
    category: 'comments',
    action: 'fetch',
  },
  [deliveryTypes.FETCH_COMMENTS_ERROR]: (action: any) => ({
    category: 'comments',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
  [deliveryTypes.CREATE_COMMENT_SUCCESS]: {
    category: 'comments',
    action: 'create',
  },
  [deliveryTypes.CREATE_COMMENT_ERROR]: (action: any) => ({
    category: 'comments',
    action: 'create.error',
    label: `trace_id: ${
      action.payload.error && action.payload.error.response && action.payload.error.response.data.trace_id
    }`,
  }),
};
