import { useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';

import { alertAction } from '../../thunks/Alerts';
import { selectActiveBoard } from '../../ducks/Boards';
import { selectPossibleResponsible } from '../../ducks/Cards';
import { selectActiveCommunity } from '../../ducks/Communities';
import { selectActiveRoom } from '../../ducks/Rooms';
import * as boardsActions from '../../thunks/Boards';
import * as cardsActions from '../../thunks/Cards';
import {
  populateInitialValues,
  getUpdatedFieldsAndValues,
  getRequiredFields,
  hasResponsibleField,
  getDefaultFieldValue,
  getInitialFieldValue,
  getNonMandatoryManualSequenceId,
  UNIQUE_DOC_ID,
} from '../../utils/FieldUtil';
import PageWrapper from '../../common/PageWrapper';
import { withViewModeQuery } from '../../utils/ViewModeUtil';
import Grid from '@mui/material/Grid2';
import Form from '../common/Form';
import CardAttachments from '../common/Attachments';
import CardStep from './CardStep';
import CardComments from '../common/Comments';
import { validateAnsweredDateField } from '../../utils/Validators';
import { TextareaAutosize } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const mapStateToProps = () =>
  createStructuredSelector({
    activeCommunity: selectActiveCommunity(),
    activeRoom: selectActiveRoom(),
    board: selectActiveBoard(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...cardsActions, ...boardsActions }, dispatch),
});

const CardCreateContainer = ({
  activeCommunity,
  activeRoom,
  board,
  possibleResponsible,
  user,
  attachments = [],
  isUploading = false,
  isDeleting = false,
  comments = [],
  members = [],
  actions,
}: any) => {
  const navigate = useNavigate();
  const formRef = useRef();
  const [closeOnSuccess, setCloseOnSuccess] = useState(false);

  const handleSubmit = ({ values, item }) => {
    return actions
      .createCard({
        boardId: board.id,
        data: {
          type: 'entry',
          fields: getUpdatedFieldsAndValues({
            values,
            item,
            fieldsConfig: board.field_config.fields,
          }),
        },
      })
      .then((action) => {
        alertAction({
          action,
          error: intl.get('card.create.error'),
          success: intl.get('card.create.success'),
          onSuccess: () =>
            navigate(
              withViewModeQuery(closeOnSuccess ? `/${board.id}` : `/${board.id}/cards/${action.payload.card.id}`),
            ),
        });
      });
  };

  const handleCancel = () => {
    navigate(withViewModeQuery(`/${board.id}`));
  };

  const handleFetchPossibleResponsible = ({ disciplines }) => {
    if (hasResponsibleField({ fieldConfig: board.field_config })) {
      actions.fetchPossibleResponsible({ boardId: board.id, disciplines }).then((action) =>
        alertAction({
          action,
          error: intl.get('card.responsible.error'),
        }),
      );
    }
  };

  const handleSaveClick = (closeForm) => {
    const form = formRef.current;
    setCloseOnSuccess(!!closeForm);

    if (form) {
      form.handleSubmit();
    }
  };

  // Create new dummy card
  const card = {
    type: 'entry',
    fields: board.field_config.fields
      .filter((field) => field.type !== 'auto-number')
      .map((field) => {
        const value = field.initial_value ? getInitialFieldValue(field) : getDefaultFieldValue(field);

        return {
          id: field.id,
          name: field.name,
          type: field.type,
          value: value,
        };
      }),
    permissions: {},
  };

  return (
    <PageWrapper title={intl.get('app_bar.question_card')}>
      <Grid container spacing={2}>
        <Grid size={{sm: 12, md: 8, xs: 12}}>
          <Form
            formRef={formRef}
            item={card}
            possibleResponsible={possibleResponsible}
            initialValues={populateInitialValues({
              item: card,
              fields: board.field_config.fields,
            })}
            disabledFields={[
              board.field_config.answer_field,
              board.field_config.answered_date_field,
              UNIQUE_DOC_ID,
              ...getNonMandatoryManualSequenceId(board.field_config.fields),
            ]}
            disableDelete={true}
            requiredFields={getRequiredFields({
              item: card,
              fields: board.field_config.fields,
            })}
            type={board.type}
            fieldConfig={board.field_config}
            onFetchPossibleResponsible={handleFetchPossibleResponsible}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            restrictions={{
              'task-done': (fieldConfig, field) => ({}),
              'task-responsible': (fieldConfig, field) => ({}),
              'rich-text': (fieldConfig, field) => ({}),
              'auto-number': (fieldConfig, field) => ({}),
              'unique-document-id': (fieldConfig, field) => ({}),
              'sequence-number': (fieldConfig, field) => ({}),
              member: (fieldConfig, field) => ({}),
              list: (fieldConfig, field) => ({}),
              date: (fieldConfig, field) => {
                if (fieldConfig.answered_date_field === field.id) {
                  return { disableFuture: true };
                }
              },
              string: (fieldConfig, field) => {
                return (field.id === fieldConfig.question_field || field.id === fieldConfig.answer_field) &&
                  field.type === 'string'
                  ? {
                      variant: 'outlined',
                      InputLabelProps: { shrink: true },
                      InputProps: {
                        inputComponent: TextareaAutosize,
                        inputProps: { rowsMin: 5, rowsMax: 10 },
                        style: { minWidth: 512 },
                      },
                      style: { marginTop: 15 },
                    }
                  : {};
              },
              numeric: (fieldConfig, field) => ({}),
            }}
            formValidation={{
              answered_date_field: (fieldConfig, values) => validateAnsweredDateField({ fieldConfig, values }),
            }}
            submitOptions={[
              {
                title: intl.get('common.form.save_and_close'),
                handleClick: () => handleSaveClick(true),
              },
              {
                title: intl.get('common.form.save'),
                default: true,
                handleClick: () => handleSaveClick(false),
              },
            ]}
          />
          <CardAttachments
            activeCommunity={activeCommunity}
            activeRoom={activeRoom}
            item={card}
            user={user}
            attachments={attachments}
            isUploading={isUploading}
            isDeleting={isDeleting}
          />
        </Grid>
        <Grid size={{sm: 12, md: 4, xs: 12}}>
          <CardStep card={card} steps={board.step_config.steps} />
          <CardComments board={board} item={card} comments={comments} members={members} />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

const ConnectedCardCreateContainer = connect(mapStateToProps, mapDispatchToProps)(CardCreateContainer);

export default ConnectedCardCreateContainer;
