import React from 'react';
import intl from 'react-intl-universal';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Immutable from 'seamless-immutable';
import { Field } from '@/models/card/CardModel';

const useStyles = () => ({
  menuList: {
    outline: 'none',
  },
  resetFilter: {
    marginLeft: '24px',
    height: '32px',
  },
  field: {
    marginLeft: '16px',
    marginRight: '16px',
    height: '48px',
    marginTop: '5px',
  },
});

const DisciplineFilters = ({
  viewConfig,
  onChange = () => {},
  hasDisciplineFilters,
  fromMemberField,
  toMemberField,
}: {
  viewConfig: any;
  onChange: (any) => void;
  hasDisciplineFilters: boolean;
  fromMemberField: Field;
  toMemberField: Field;
}) => {
  const classes: any = useStyles();

  const handleFilterChange = (event: any) => {
    onChange(viewConfig.set(event.target.name, event.target.value));
  };

  const handleResetFilter = () => {
    onChange(viewConfig.set('from', null).set('to', null));
  };

  return (
    <React.Fragment>
      <ListSubheader style={{ outline: 'none' }}>
        {intl.get('board.toolbar.discipline_filters')}
        <Button
          sx={classes.resetFilter}
          size="small"
          variant="outlined"
          disabled={!hasDisciplineFilters}
          onClick={handleResetFilter}
        >
          {intl.get('board.toolbar.discipline_filters.reset')}
        </Button>
      </ListSubheader>
      <MenuItem sx={classes.field}>
        <FormControl style={{ minWidth: '250px', maxWidth: '250px' }} variant='standard'>
          <InputLabel htmlFor="from-label-placeholder">{intl.get('board.toolbar.discipline_filters.from')}</InputLabel>
          <Select
            multiple
            value={Immutable(viewConfig.from || fromMemberField.limited_to).asMutable()}
            onChange={handleFilterChange}
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
            inputProps={{
              name: 'from',
              id: 'from-label-placeholder',
            }}
          >
            {fromMemberField.limited_to?.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MenuItem>
      <MenuItem sx={classes.field}>
        <FormControl style={{ minWidth: '250px', maxWidth: '250px' }} variant='standard'>
          <InputLabel htmlFor="to-label-placeholder">{intl.get('board.toolbar.discipline_filters.to')}</InputLabel>
          <Select
            multiple
            value={Immutable(viewConfig.to || toMemberField.limited_to).asMutable()}
            onChange={handleFilterChange}
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
            inputProps={{
              name: 'to',
              id: 'to-label-placeholder',
            }}
          >
            {toMemberField.limited_to?.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MenuItem>
    </React.Fragment>
  );
};

export default DisciplineFilters;
