import * as api from '../services/API';
import { actions as authActions } from '../ducks/Auth';
import onError from './Error';
import { Dispatch } from 'redux';

export function getUserProfile({ communityId }: { communityId: string }) {
  return async (dispatch: Dispatch) => {
    dispatch(authActions.fetchUserProfileRequest());

    try {
      const userProfile = await api.fetchCurrentUserProfile({ communityId });

      return dispatch(authActions.fetchUserProfileSuccess({ userProfile }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: authActions.fetchUserProfileError(),
      });
    }
  };
}

export const logout = () => {
  return async (dispatch: Dispatch) => {
    dispatch(authActions.logoutRequest());

    try {
      return dispatch(authActions.logoutSuccess());
    } catch (error) {
      return onError({ error, dispatch, action: authActions.logoutFailure() });
    }
  };
};
