import axios from 'axios';
import { ENVIRONMENTAL_VARIABLES } from '@/utils/Environment';

const checkStatus = (status: number) => status >= 200 && status < 300;

const client = axios.create({
  baseURL: ENVIRONMENTAL_VARIABLES.API_URL,
  headers: {
    Accept: 'application/json, application/xml, text/plain, text/html',
    'Content-Type': 'application/json',
  },
  validateStatus: checkStatus,
});

const ERROR_CODES = {
  NOT_FOUND: 'NOT_FOUND',
  INVALID: 'INVALID',
  UNAUTHORIZED: 'UNAUTHORIZED',
  ACCESS_DENIED: 'ACCESS_DENIED',
  UNIQUE_ERROR: 'UNIQUE_ERROR',
  INTEGRITY_VIOLATIONS: 'INTEGRITY_VIOLATIONS',
  NETWORK_ERROR: 'NETWORK_ERROR',
  UNKNOWN: 'UNKNOWN',
};

export { client, ERROR_CODES };

export * from '@/services/Cards';
export * from '@/services/Boards';
export * from '@/services/Subscribers';
export * from '@/services/Communities';
export * from '@/services/Rooms';
export * from '@/services/Milestones';
export * from '@/services/Deliveries';
export * from '@/services/Files';
export * from '@/services/UserProfile';
export * from '@/services/Export';
