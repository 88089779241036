import intl from 'react-intl-universal';
import CardItem from './CardItem';
import { STATUS_RED, STATUS_GREEN } from './CardItem';
import { deliveryStatusMessages, getDeliveryFieldValuesToDisplayInTooltip } from '../../utils/FieldUtil';
import DeliveryIcon from '@mui/icons-material/LocalShippingOutlined';
import { Card, CardType } from '@/models/card/CardModel';
import { Board } from '@/models/BoardModel';
const useStyles = () => ({
  typeIcon: {
    height: '32px',
    width: '32px',
    color: '#3eb1c8',
  },
  typeIconHighReadability: {
    height: '48px',
    width: '48px',
    color: '#3eb1c8',
  },
});

function isDelivered(card: any) {
  return !!card.metDate;
}

const DeliveryCardItem = ({
  card,
  board,
  viewConfig,
  onCardClick,
  rowIndex,
  columnIndex,
  isMarked,
  type,
}: {
  card: Card;
  board: Board;
  viewConfig: any;
  onCardClick: ({ card, type }: { card: Card; type: CardType }) => void;
  rowIndex: number;
  columnIndex: number;
  isMarked: boolean;
  type: CardType;
}) => {
  const classes: any = useStyles();
  const isMet = isDelivered(card);

  return (
    <CardItem
      key={`card-${card.id}`}
      card={card}
      board={board}
      viewConfig={viewConfig}
      onCardClick={(card: Card) => {
        onCardClick({ card, type: CardType.DELIVERY });
      }}
      getFieldMap={getDeliveryFieldValuesToDisplayInTooltip}
      typeIcon={<DeliveryIcon sx={viewConfig.highReadability ? classes.typeIconHighReadability : classes.typeIcon} />}
      typeIconTooltip={intl.get('board.tooltips.delivery')}
      statusColour={isMet ? STATUS_GREEN : STATUS_RED}
      statusTooltip={intl.get(deliveryStatusMessages[card.status])}
      titleField="deliveryTitle"
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      isMarked={isMarked}
      type={type}
    />
  );
};

export default DeliveryCardItem;
