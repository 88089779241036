import intl from 'react-intl-universal';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Tooltip from '@mui/material/Tooltip';

const useStyles = () => ({
  button: {
    marginLeft: '10px',
  },
});

const Subscriptions = ({ isSubscribing, onSubscribe }: any) => {
  const classes: any = useStyles();

  return (
    <Tooltip title={intl.get(isSubscribing ? 'subscription.unsubscribe' : 'subscription.subscribe')}>
      <IconButton
        id={'toggle-subscribe'}
        onClick={(event) => {
          event.stopPropagation();
          onSubscribe();
        }}
      >
        {isSubscribing ? <VisibilityOff sx={classes.icon} /> : <Visibility sx={classes.icon} />}
      </IconButton>
    </Tooltip>
  );
};

export default Subscriptions;
