import * as api from '../services/API';
import { actions } from '../ducks/Rooms';
import onError from './Error';
import { Dispatch } from 'redux';

export const fetchRooms = ({ communityId }: { communityId: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchRoomsRequest({ communityId }));
    try {
      const rooms = await api.fetchRooms({ communityId });
      return dispatch(actions.fetchRoomsSuccess({ communityId, rooms }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchRoomsError({ error }),
      });
    }
  };
};
