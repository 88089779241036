import intl from 'react-intl-universal';
import { toastr } from 'react-redux-toastr';
import translateApiError from '../utils/ApiErrorTranslator';

/**
 * Show error notification if action has error
 * Show success notification if no error found and success message provided
 */
export const alertAction = ({ action, success, error, onSuccess, onError }: { action: any, success?: string, error: string, onSuccess?: (action: any) => void, onError?: (action: any) => void }) => {
  if (action.payload.error) {
    // Show error message with trace id and error code
    alertError(formatApiError(action.payload.error, error));

    // on error callback
    if (onError) {
      onError({ action });
    }
  } else {
    // Show success message if provided
    if (success) {
      alertSuccess(success);
    }

    // on success callback
    if (onSuccess) {
      onSuccess({ action });
    }
  }
};

export const alertSuccess = (text: string) => {
  toastr.success(intl.get('common.toast.success'), text);
};

export const alertError = (text: string) => {
  toastr.error(intl.get('common.toast.error'), text, {
    timeOut: 5000,
  });
};

export const alertWarning = (text: string) => {
  toastr.warning(intl.get('common.toast.info'), text, {
    timeOut: 5000,
  });
};

const formatApiError = (error: any, defaultMessage = intl.get('common.error.default')) => {
  if (!error) {
    return defaultMessage;
  }

  let message = null;

  if (error.response && error.response.data) {
    const { data: apiError } = error.response;

    if (apiError.error_code) {
      message = translateApiError(apiError.error_code, defaultMessage);
    } else if (apiError.code) {
      message = `${apiError.code}: ${defaultMessage}`;
    }
    // API returns "unknown" trace_id for frequent not-found and access-denied errors
    // so it will not spam in logs
    if (apiError.trace_id && apiError.trace_id !== 'unknown') {
      message = `${apiError.trace_id}. ${message}`;
    }
  }

  return message || defaultMessage || error.message;
};
